import React, { useRef, useState, useEffect } from "react";
import {
  Table,
  Drawer,
  Card,
  Col,
  Row,
  Carousel,
  Select,
  message,
  Image,
  Checkbox,
  Button,
  Upload,
  Input,
} from "antd";
import useCardStyle from "../../../hooks/useCardStyle";
import useDrawer from "../../../hooks/useDrawer";
import OrderProduct from "../order/OrderProduct";
import {
  CTD_REQUEST_CHANGES_MUTATION,
  CTD_APPROVE_REQUEST_MUTATION,
  CTD_HANDLE_REQUEST_MUTATION,
  UPLOAD_CTD_FILE_MUTATION,
  CTD_DELETE_REQUEST_MUTATION,
  NEW_GET_QUOTATION_IMAGES,
  NEW_FILE_QUERY,
  CTD_ADD_COMMENT_MUTATION
} from "../../../queries/back-end/queries";
import { fetchWrapper } from "../../../helpers/fetchWrapper";
import Spinner from "../../spinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import * as quotationAction from "../../../redux/actions/quotationAction";
import * as productAction from "../../../redux/actions/productAction";
import * as answerAction from "../../../redux/actions/quotation/answerAction";
import { SyncOutlined, UploadOutlined } from "@ant-design/icons";
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input
const backendGraphqlEndpoint = process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT

// CTD Employee point of view
const CtdChecks = (props) => {
  const {
    ctdRequestsData,
    ctdCheckLabels,
    quotationStepsLabels,
    setSyncData,
    syncData,
  } = props

  let approveCommentId = useRef(null)
  let installationTime = useRef(null)
  let complexity = useRef(null)
  const inputRef = useRef(null)

  const downloadUrl = process.env.REACT_APP_BACKEND_FILE_DOWNLOAD_ENDPOINT

  const [ctdChangeInput, setCTDChangeInput] = useState([])
  const [hideAnswers, setHideAnswers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ctdRequest, setCtdRequest] = useState()
  const [shouldFilter, setShouldFilter] = useState(false)
  const [quotationFiles, setQuotationFiles] = useState()
  const [customerFiles, setCustomerFiles] = useState()
  const [cmpCode, setCmpCode] = useState(0)

  const { cardStyle } = useCardStyle()
  const { showDrawer, isDrawerVisible, closeDrawer } = useDrawer()

  const quotations = useSelector((state) => state.quotations)
  const products = useSelector((state) => state.products)
  const logins = useSelector(state => state.logins)

  const dispatch = useDispatch()
  const setQuotation = (quotation) =>
    dispatch(quotationAction.setQuotation(quotation))
  const deleteQuotation = () => dispatch(quotationAction.deleteQuotation());
  const deleteProduct = () => dispatch(productAction.deleteProduct());
  const setProduct = (name, id) => dispatch(productAction.setProduct(name, id));
  const createAnswer = (answer, step) =>
    dispatch(answerAction.createAnswer(answer, step));
  const deleteAllAnswers = () => dispatch(answerAction.deleteAllAnswers());

  useEffect(() => {
    if (ctdRequest) {
      const quotation = ctdRequest.QuotationOverview;
      setQuotation(quotation)
      setProduct(
        quotations.quotation.WizardCompositeKey.Model.ProductCategory.category,
        quotations.quotation.WizardCompositeKey.Model.ProductCategory.code
      );
    }
  }, [ctdRequest]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateQuotationsSteps = () => {
    // Map the quotations answers from the CMS to the quotation selected
    // we are in the wrong product in this moment
    if (quotations.quotation?.answers?.length > 0) {
      quotationStepsLabels.lables.answers.map((answer, index) => {
        return createAnswer(
          answer.options.filter((option) => {
            return (
              option.answer_back_end_id ===
              quotations.quotation.answers[index]
                .answerId
            )
          })[0],
          index
        )
      })
    } else {
      deleteAllAnswers()
    }
  }

  // Data from the CMS - "ctdCheck" document
  const columnsHelpDesk = [
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnCMPCode,
      dataIndex: "cmp_code",
      key: "cmp_code",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnIsLiveClosing,
      dataIndex: "is_live_closing",
      key: "is_live_closing",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnSalesman,
      dataIndex: "salesman",
      key: "salesman",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnQuotation,
      dataIndex: "quotation",
      key: "quotation",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnCountry,
      dataIndex: "country",
      key: "country",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnStatus,
      dataIndex: "status",
      key: "status",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnProduct,
      dataIndex: "product",
      key: "product",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnWaiting,
      dataIndex: "waiting",
      key: "waiting",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnHandler,
      dataIndex: "handler",
      key: "handler",
    },
    {
      title: ctdCheckLabels?.ctdRequestsTableColumnAction,
      dataIndex: "startAction",
      key: "startAction",
      render: (_, { startAction, quotation, cmp_code }) => {
        if (startAction === "Check") {
          return (
            <div
              className="btn btn-orange"
              style={{ width: "100px" }}
              onClick={() => {
                const quotationUpdated = ctdRequestsData.find(
                  (requestQuotation) => {
                    return requestQuotation.QuotationOverview.id === quotation
                  }
                )

                setCtdRequest(quotationUpdated)

                // files by CMP code
                const variables = {
                  quotationId: quotationUpdated.QuotationOverview.id
                }

                setIsLoading(true)

                fetchWrapper
                  .post(backendGraphqlEndpoint, NEW_GET_QUOTATION_IMAGES, variables)
                  .then(({ data }) => {
                    setIsLoading(false)
                    setQuotationFiles(data)
                  })
                  .catch((error) => {
                    setIsLoading(false)
                  })
                // End - files by CMP code

                // measurement files
                const measurementFilesVariables = {
                  documentTypeId: 186,
                  cmpCode: cmp_code
                }

                setCmpCode(cmp_code)

                setIsLoading(true)

                fetchWrapper
                  .post(backendGraphqlEndpoint, NEW_FILE_QUERY, measurementFilesVariables)
                  .then(({ data }) => {
                    setIsLoading(false)
                    setCustomerFiles(data)
                  })
                  .catch((error) => {
                    setIsLoading(false)
                  })
                // End - measurement files

                setProduct(
                  quotationUpdated.QuotationOverview.WizardCompositeKey.Model
                    .ProductCategory.category,
                  quotationUpdated.QuotationOverview.WizardCompositeKey.Model
                    .ProductCategory.code
                )

                // // Map the quotations answers from the CMS to the quotation selected
                // // we are in the wrong product in this moment
                // if (quotationUpdated.QuotationOverview.answers.length > 0) {
                //   quotationStepsLabels.lables.answers.map((answer, index) => {
                //     return createAnswer(
                //       answer.options.filter((option) => {
                //         return (
                //           option.answer_back_end_id ===
                //           quotationUpdated.QuotationOverview.answers[index]
                //             .answerId
                //         )
                //       })[0],
                //       index
                //     )
                //   })
                // } else {
                //   deleteAllAnswers()
                // }
                // updateQuotationsSteps()

                setQuotation(quotationUpdated.QuotationOverview)
                showDrawer()
              }}
            >
              {startAction}
            </div>
          )
        } else {
          return { startAction };
        }
      },
    },
    {
      title: "Delete",
      dataIndex: "ctd_id",
      key: "ctd_id",
      render: (_, { ctd_id }) => {
        return (
          <div
            className="btn btn-dark-blue"
            style={{ width: "100px" }}
            onClick={() => {
              setIsLoading(true);
              const requestId = {
                requestId: ctd_id,
              };

              fetchWrapper
                .post(
                  backendGraphqlEndpoint,
                  CTD_DELETE_REQUEST_MUTATION,
                  requestId
                )
                .then(({ data }) => {
                  setIsLoading(false);
                  message.success("Success!");
                })
                .catch((error) => {
                  setIsLoading(false);
                  message.error(`👎 failled!`);
                })
            }}
          >
            X
          </div>
        );
      },
    },
  ]

  const ctdRequestChanges = () => {
    const variables = {
      requestId: ctdRequest.id,
      ctdChangeInput: ctdChangeInput,
    }

    setIsLoading(true)
    fetchWrapper
      .post(backendGraphqlEndpoint, CTD_REQUEST_CHANGES_MUTATION, variables)
      .then(({ data, errors }) => {
        setIsLoading(false);
        if (errors) {
          message.error(`👎 ${errors[0].message}`, [10]);
        } else {
          message.success("", [10]);
          closeDrawer();
          deleteProduct();
          deleteQuotation();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      })
  }

  const ctdApproveRequest = () => {
    const variables = {
      requestId: ctdRequest.id,
      approveCommentId: approveCommentId.current,
      installationTimeId: installationTime.current,
      complexityId: complexity.current
    }

    setIsLoading(true)
    fetchWrapper
      .post(backendGraphqlEndpoint, CTD_APPROVE_REQUEST_MUTATION, variables)
      .then(({ data, errors }) => {
        setIsLoading(false)
        if (errors) {
          message.error(`👎 ${errors[0].message}`, [10])
        } else {
          message.success("", [10])
          closeDrawer()
          deleteProduct()
          deleteQuotation()
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const sliderRef = useRef()

  const onChangeDropdown = (value, categoryId, questionData) => {
    setCTDChangeInput([
      ...ctdChangeInput,
      {
        questionId: questionData.id,
        categoryId: categoryId,
        comment: value,
      },
    ]);
  };

  const onApprovedAnswer = (e, id) => {
    if (e.target.checked) {
      setHideAnswers([...hideAnswers, id]);
    } else {
      setHideAnswers([
        ...hideAnswers.filter((answer) => {
          return answer !== id;
        }),
      ])
    }
  }

  const ctdHandleRequest = () => {
    const variables = {
      requestId: ctdRequest.id,
    }

    setIsLoading(true)

    fetchWrapper
      .post(backendGraphqlEndpoint, CTD_HANDLE_REQUEST_MUTATION, variables)
      .then(({ data, errors }) => {
        setCtdRequest(data.ctdHandleRequest)
        setIsLoading(false)
        if (errors) {
          message.error(`👎 ${errors[0].message}`, [10])
        } else {
          message.success("", [10])
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const handleUploadFile = (info, categoryId, questionData) => {
    setIsLoading(true)

    const existingFileName = ctdRequest?.answers?.find((change) => {
      return (
        change.categoryId === categoryId &&
        change.questionId === questionData.id
      );
    }).answer;

    if (info.file.status !== "uploading") {
      let variables = "";

      if (existingFileName === undefined) {
        variables = `{"file":null,"quotationId":${quotations.quotation.id}}`;
      } else {
        variables = `{"file":null,"quotationId":${quotations.quotation.id},"existingFileName":"${existingFileName}"}`
      }

      fetchWrapper
        .postAsset(
          backendGraphqlEndpoint,
          UPLOAD_CTD_FILE_MUTATION,
          variables,
          info.fileList[0].originFileObj
        )
        .then(({ data }) => {
          if (data.uploadCTDFile.success === true) {
            message.success(
              `${info.file.name} ${questionData.uploadFileSuccessMessage}`
            );

            setIsLoading(false);
          } else {
            setIsLoading(false);
            message.error(
              `${info.file.name} ${questionData.uploadFileErrorMessage}`
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          message.error(`${info.file.name} ${error}`);
        })
    }
  }

  // Data from the backend
  let dataHelpDesk = [];
  if (ctdRequestsData?.length > 0) {
    ctdRequestsData.map((ctdRequest, index) => {
      let diff = Math.abs(new Date() - new Date(ctdRequest.modifiedTime));
      dataHelpDesk.push({
        key: index + 1,
        cmp_code: ctdRequest?.QuotationOverview?.Customer?.synergyCmpCode,
        is_live_closing: ctdRequest?.isLiveClosing ? <b style={{ color: "#FF5A00" }}>{ctdRequest?.isLiveClosing.toString()}</b> : ctdRequest?.isLiveClosing.toString(),
        salesman: `${ctdRequest?.QuotationOverview?.Employee?.firstName} ${ctdRequest?.QuotationOverview?.Employee?.lastName}`,
        quotation: ctdRequest.QuotationOverview.id,
        country: ctdRequest.QuotationOverview.Customer.locale,
        status: ctdRequest.Status.status,
        product:
          ctdRequest.QuotationOverview.WizardCompositeKey.Model.ProductCategory
            .category,
        // summer time
        waiting: Math.floor(diff / 1000 / 60) - 60,
        handler:
          ctdRequest.Employee &&
          `${ctdRequest.Employee?.firstName} ${ctdRequest.Employee?.lastName}`,
        startAction: "Check",
        ctd_id: ctdRequest.id,
      })
      return null
    })
  }

  const onChangeTextArea = () => {
    const variables = {
      requestId: ctdRequest.id,
      comment: inputRef?.current?.resizableTextArea?.props?.value,
    }

    setIsLoading(true)
    fetchWrapper
      .post(backendGraphqlEndpoint, CTD_ADD_COMMENT_MUTATION, variables)
      .then(({ data, errors }) => {
        setIsLoading(false)
        if (errors) {
          message.error(`👎 ${errors[0].message}`, [10])
        } else {
          message.success("", [10])
          setCtdRequest(data.ctdAddComment)
          showDrawer()
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const dataSourceDrawer = [
    {
      key: "1",
      salesman: `${quotations?.quotation?.Employee?.firstName} ${quotations?.quotation?.Employee?.lastName}`,
      cmpCode: quotations?.quotation?.Customer?.synergyCmpCode,
      customer: `${quotations?.quotation?.Customer?.firstName} ${quotations?.quotation?.Customer?.lastName}`,
    },
  ];

  const columnsDrawer = [
    {
      title: "Synergy Code",
      dataIndex: "cmpCode",
      key: "cmpCode",
    },
    {
      title: "Salesman",
      dataIndex: "salesman",
      key: "salesman",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
  ]

  return (
    <div>
      {isLoading && <Spinner />}
      <div className={`pt-3 pr-3 pl-3 pt-lg-5 pr-lg-5 pl-lg-5 pb-0 pb-lg-0`}>
        <div className="card border-0" style={cardStyle}>
          <Card
            style={{ marginTop: 16 }}
            bordered={false}
            title={
              <div>
                <b>{ctdCheckLabels?.ctdRequestsTableTitle}</b>
                <Button
                  type="default"
                  className="ml-3"
                  onClick={() => setSyncData(!syncData)}
                >
                  <SyncOutlined />
                </Button>
              </div>
            }
          >
            {updateQuotationsSteps()}
            <Table
              columns={columnsHelpDesk}
              dataSource={dataHelpDesk}
              pagination={{
                defaultPageSize: 25,
                defaultCurrent: 1,
                position: ["bottomRight"],
              }}
            />
          </Card>
        </div>

        {isDrawerVisible && (
          <Drawer
            title={ctdCheckLabels?.ctdRequestDrawerTitle}
            height="auto"
            width={"100%"}
            placement="right"
            closable={true}
            onClose={() => (
              deleteProduct(),
              closeDrawer(),
              deleteQuotation(),
              deleteAllAnswers(),
              setCTDChangeInput([]),
              setHideAnswers([])
            )}
            open={isDrawerVisible}
          >
            {/* Buttons */}
            {/* Do not show the buttons to the CTD employee if the quotation is "final" status */}
            {
              quotations.quotation.Status.id !== 3 &&
              <div className="w-100 d-flex justify-content-center pb-2">
                {/* Approved Button */}
                {ctdRequest?.Status.id === 2 && ctdChangeInput.length === 0 && (
                  <div>
                    <div
                      className={`btn btn-orange ${ctdRequest?.Status.id === 1 ? "disable" : ""
                        }`}
                      style={{ width: "200px" }}
                      onClick={() => {
                        ctdApproveRequest();
                      }}
                    >
                      {ctdCheckLabels?.approveButtonLabel}
                    </div>
                  </div>
                )}

                {/* Request Changes Button */}
                {ctdRequest?.Status.id === 2 && (
                  <div
                    className="btn btn-orange"
                    style={{ width: "200px" }}
                    onClick={() => {
                      ctdRequestChanges();
                    }}
                  >
                    {ctdCheckLabels?.requestChangesButtonLabel}
                  </div>
                )}

                {/* Handle Request Button - first time*/}
                {
                  ctdRequest?.Status.id === 1 && (
                    <div
                      className={`btn btn-orange`}
                      style={{ width: "200px" }}
                      onClick={() => {
                        ctdHandleRequest()
                      }}
                    >
                      {ctdCheckLabels?.handleRequestButtonLabel}
                    </div>
                  )
                }
                {/* Handle Request Button - second time*/}
                {
                  ctdRequest?.Status.id !== 1 && (
                    <div
                      className={`btn btn-dark-blue`}
                      style={{ width: "200px" }}
                      onClick={() => {
                        ctdHandleRequest()
                      }}
                    >
                      {ctdCheckLabels?.handleRequestButtonLabel}
                    </div>
                  )
                }
              </div>
            }
            {/* Remarks Dropdown */}
            {
              ctdRequest?.Status.id === 2 && ctdChangeInput.length === 0 && (
                <div>
                  {/* Approve Comments */}
                  <div className="text-center">
                    <Select
                      style={{
                        width: '70%',
                        margin: "5px",
                      }}
                      onChange={(value) => (approveCommentId.current = value)}
                      placeholder="Approve Comment"
                    >
                      {ctdCheckLabels.ctdCheckProductEntry
                        .find((product) => {
                          return product.product.backendId === products.productId
                        })
                        ?.ctdApproveComment?.dropdownOptions.map(
                          (option, index) => {
                            return (
                              <Option key={index} value={index + 1}>
                                {option}
                              </Option>
                            )
                          }
                        )}
                    </Select>
                  </div>
                  {/* Installation Time */}
                  <div className="text-center">
                    <Select
                      style={{
                        width: '70%',
                        margin: "5px",
                      }}
                      onChange={(value) => (installationTime.current = value)}
                      placeholder={ctdCheckLabels.installationInformation.installationTime.dropdownPlaceholder}
                    >
                      {ctdCheckLabels?.installationInformation?.installationTime?.dropdownOptions.map(
                        (option, index) => {
                          return (
                            <Option key={index} value={index + 1}>
                              {option}
                            </Option>
                          )
                        }
                      )}
                    </Select>
                  </div>
                  {/* Complexity */}
                  <div className="text-center">
                    <Select
                      style={{
                        width: '70%',
                        margin: "5px",
                      }}
                      onChange={(value) => (complexity.current = value)}
                      placeholder={ctdCheckLabels.installationInformation.complexity.dropdownPlaceholder}
                    >
                      {ctdCheckLabels?.installationInformation?.complexity?.dropdownOptions.map(
                        (option, index) => {
                          return (
                            <Option key={index} value={index + 1}>
                              {option}
                            </Option>
                          )
                        }
                      )}
                    </Select>
                  </div>
                </div>
              )
            }

            <Row justify="center">
              <Col
                xxl={8}
                style={{
                  padding: "20px"
                }}
              >
                {quotations.quotation.remark !== "" && (
                  <Card
                    style={{ marginTop: 16 }}
                    bordered={true}
                    title={<b>{"Salesman Comments:"}</b>}
                  >
                    <div>{quotations.quotation.remark}</div>
                  </Card>
                )}
                <OrderProduct
                  bordered={true}
                  productName={
                    quotations.quotation?.WizardCompositeKey?.Model.name
                  }
                  discount={
                    quotations.quotation?.discount
                      ? quotations.quotation?.discount
                      : 0
                  }
                  totalPrice={quotations.quotation?.quotationPrice}
                  modelPicture={
                    products.productId !== 24
                      ? quotations.quotation?.WizardCompositeKey.Model.picture
                      : `${process.env.REACT_APP_BACKEND_PROXY_SERVER}saninet/${quotations.quotation.id}/thumbnail`
                  }
                  cardProductSpecifications={
                    quotationStepsLabels.lables.cardProductSpecifications
                  }
                  cardProductColorOptionOne={
                    quotationStepsLabels.lables.cardProductColorOptionOne
                  }
                  cardProductColorOptionTwo={
                    quotationStepsLabels.lables.cardProductColorOptionTwo
                  }
                  cardProductExtraServiceOptionOne={
                    quotationStepsLabels.lables.cardProductExtraServiceOptionOne
                  }
                  cardProductExtraServiceOptionTwo={
                    quotationStepsLabels.lables.cardProductExtraServiceOptionTwo
                  }
                  cardProductAdditionalCodesTitle={
                    quotationStepsLabels.lables.cardProductAdditionalCodesTitle
                  }
                  cardProductAdditionalCodesTableDescription={
                    quotationStepsLabels.lables
                      .cardProductAdditionalCodesTableDescription
                  }
                  cardProductAdditionalCodesTableQuantity={
                    quotationStepsLabels.lables
                      .cardProductAdditionalCodesTableQuantity
                  }
                  cardProductAdditionalCodesTablePrice={
                    quotationStepsLabels.lables
                      .cardProductAdditionalCodesTablePrice
                  }
                  questions={quotationStepsLabels.lables.answers}
                />
              </Col>

              {
                ctdRequest?.Status.id !== 1 && (
                  <>
                    <Col
                      xxl={8}
                      style={{
                        padding: "20px"
                      }}
                    >
                      <div>

                        <div className="w-100 d-flex justify-content-center pb-2 pt-2">
                          <Table
                            style={{ width: "400px" }}
                            pagination={false}
                            dataSource={dataSourceDrawer}
                            columns={columnsDrawer}
                          />
                        </div>

                        {
                          quotations.quotation.CTDRequest.answers.some((answer) => {
                            return answer.dataType === "file"
                          }) &&
                          <Card
                            style={{ marginTop: 16 }}
                            bordered={true}
                            title={<b>CTD Files</b>}
                          >
                            <div className="d-flex" style={{
                              flexWrap: "wrap"
                            }}>
                              {quotations.quotation.CTDRequest.answers.filter((answer) => {
                                return answer.dataType === "file"
                              })?.map((image, index) => {

                                return (
                                  <div>
                                    {index + 1}.
                                    <Image
                                      width={150}
                                      src={`${process.env
                                        .REACT_APP_BACKEND_PROXY_SERVER
                                        }ctd/${ctdRequest
                                          .QuotationOverview.id
                                        }/${image.answer}`}
                                      key={image.answer}
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                      }}
                                      alt="Image"
                                    />
                                  </div>
                                )

                              })}
                            </div>
                          </Card>
                        }
                        {
                          quotationFiles &&
                          <Card
                            style={{ marginTop: 16 }}
                            bordered={true}
                            title={<b>Quotation Files</b>}
                          >
                            <div>
                              {quotationFiles.getQuotationImages.map((image, index) => {

                                return (
                                  <div key={image.name}>
                                    {index + 1}.
                                    <Image
                                      alt={image.name}
                                      src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}quotation/${quotations.quotation.id}/${image.name}`}
                                      width={150}
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                      }}
                                    />

                                  </div>
                                )

                              })}
                            </div>
                          </Card>
                        }

                        {/* Customer files */}
                        {
                          customerFiles?.file &&
                          < Card
                            style={{ marginTop: 16 }}
                            bordered={true}
                            title={<b>Measurement Files</b>}
                          >
                            <div>
                              {customerFiles?.file.map((file, index) => {

                                return (
                                  <div key={file.name} style={{ marginBottom: 10 }}>
                                    <a href={`${downloadUrl}${file.name}/${cmpCode}`} rel="noopener noreferrer" target="_blank" download style={{ display: 'flex', alignItems: 'center' }}>
                                      {index + 1}.{file.name}
                                    </a>

                                  </div>
                                )

                              })}
                            </div>
                          </Card>
                        }
                        <Card
                          style={{ marginTop: 16 }}
                          bordered={true}
                          title={<b>Comments</b>}
                        >
                          <div
                            style={{
                              marginTop: "10px",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <div>
                                {
                                  // show CTD Comments
                                  quotations.quotation?.CTDRequest?.comments.length > 0 &&
                                  quotations.quotation?.CTDRequest?.comments.map((element, index) => {
                                    return <p key={`ctd-comments-${index}`}>
                                      {`[${element.date} ${element.employeeName}]`} <b>{element.comment}</b> [End]
                                    </p>
                                  })
                                }
                              </div>
                            </div>
                            <TextArea
                              ref={inputRef}
                              style={{
                                marginBottom: "10px",
                              }}
                              rows={4}
                              placeholder={`[${moment().format().replace('T', ' ').split('+')[0]} ${logins.employeeName.firstName} ${logins.employeeName.lastName}] `}
                            />
                            <div
                              className="btn btn-dark-blue w-auto m-auto"
                              onClick={onChangeTextArea}
                            >
                              {"Submit comment (in test)"}
                            </div>
                          </div>
                        </Card>
                      </div>

                    </Col>

                    <Col
                      xxl={8}
                      style={{
                        padding: "20px"
                      }}
                    >
                      <div>
                        <Carousel
                          ref={sliderRef}
                          swipeToSlide={true}
                          dots={false}
                          draggable
                        >
                          {ctdCheckLabels &&
                            ctdCheckLabels.ctdCheckProductEntry
                              .find((product) => {
                                return (
                                  product.product.backendId === products.productId
                                )
                              })
                              ?.ctdCheckCategoryEntry?.map((entry, index) => {
                                const categoryId = entry.id;

                                return (
                                  <div
                                    key={index}
                                    className={"card border-0"}
                                    style={cardStyle}
                                  >
                                    <Card
                                      style={{ marginTop: 16 }}
                                      bordered={true}
                                      title={
                                        <div>
                                          <div>
                                            <b>{categoryId} - {entry.title}</b>
                                          </div>
                                          {/* carousel buttons */}
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              userSelect: "none",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              className="btn btn-orange"
                                              onClick={() => sliderRef.current.prev()}
                                            >
                                              {"<"}
                                            </div>
                                            <div
                                              className="btn btn-orange"
                                              onClick={() => setShouldFilter(!shouldFilter)}
                                            >
                                              {"||"}
                                            </div>
                                            <div
                                              className="btn btn-orange"
                                              onClick={() => sliderRef.current.next()}
                                            >
                                              {">"}
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    >
                                      {entry.ctdQuestionEntry
                                        ?.filter((question) => {
                                          return (
                                            question.isMandatoryForCTDEmployee ===
                                            true
                                          )
                                        })
                                        .filter((entry) => {
                                          if (shouldFilter) {
                                            return quotations.quotation.CTDRequest?.changes?.some(
                                              (e) => {
                                                return (e.categoryId === categoryId && e.questionId === entry.id)
                                              })
                                          } else {
                                            return true
                                          }
                                        })
                                        .map((questionData, key) => {
                                          const ctdRequestPlaceholder =
                                            ctdChangeInput?.find((change) => {
                                              return (
                                                change.categoryId === categoryId &&
                                                change.questionId ===
                                                questionData.id
                                              )
                                            })

                                          return (
                                            <span key={key}>
                                              {ctdRequest?.answers.some(
                                                (answer) => {
                                                  return (
                                                    answer.categoryId ===
                                                    categoryId &&
                                                    answer.questionId ===
                                                    questionData.id
                                                  )
                                                }
                                              ) && (
                                                  <div
                                                    style={{
                                                      padding: "15px",
                                                      border:
                                                        "2px solid rgb(232, 232, 232)",
                                                      borderRadius: "15px",
                                                      marginBottom: "15px",
                                                    }}
                                                  >
                                                    <p>
                                                      <Checkbox
                                                        onChange={(e) =>
                                                          onApprovedAnswer(
                                                            e,
                                                            questionData._id
                                                          )
                                                        }
                                                      >
                                                        {questionData.id} - {questionData.questionText}
                                                      </Checkbox>
                                                    </p>
                                                    <div
                                                      id={questionData._id}
                                                      className={
                                                        hideAnswers.find((answer) => {
                                                          return (
                                                            answer ===
                                                            questionData._id
                                                          )
                                                        })
                                                          ? "d-none"
                                                          : ""
                                                      }
                                                    >
                                                      {ctdRequest?.answers.some(
                                                        (answer) => {
                                                          return (
                                                            answer.categoryId ===
                                                            categoryId &&
                                                            answer.questionId ===
                                                            questionData.id &&
                                                            answer.dataType !== "file"
                                                          )
                                                        }
                                                      ) && (
                                                          <p>
                                                            <b>
                                                              {
                                                                ctdRequest?.answers.find(
                                                                  (answer) => {
                                                                    return (
                                                                      answer.categoryId ===
                                                                      categoryId &&
                                                                      answer.questionId ===
                                                                      questionData.id &&
                                                                      answer.dataType !==
                                                                      "file"
                                                                    )
                                                                  }
                                                                )?.answer
                                                              }
                                                            </b>
                                                            <Select
                                                              onChange={(value) =>
                                                                onChangeDropdown(
                                                                  value,
                                                                  categoryId,
                                                                  questionData
                                                                )
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                marginTop: "10px",
                                                              }}
                                                              placeholder={
                                                                ctdRequestPlaceholder
                                                                  ? ctdRequestPlaceholder.comment
                                                                  : questionData
                                                                    .ctdCheckRequestOptions
                                                                    ?.dropdownPlaceholder
                                                              }
                                                            >
                                                              {questionData?.ctdCheckRequestOptions?.dropdownOptions?.map(
                                                                (option, key) => {
                                                                  return (
                                                                    <Option
                                                                      key={key}
                                                                      value={option}
                                                                    >
                                                                      {option}
                                                                    </Option>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                            <div
                                                              style={{
                                                                paddingTop: '10px',
                                                                color: 'red'
                                                              }}
                                                            >
                                                              {
                                                                quotations.quotation.CTDRequest?.changes?.find(
                                                                  (e) =>
                                                                    e.categoryId ===
                                                                    categoryId &&
                                                                    e.questionId ===
                                                                    entry.id
                                                                )?.comment
                                                              }
                                                            </div>
                                                          </p>
                                                        )}
                                                      {ctdRequest?.answers.some(
                                                        (answer) => {
                                                          return (
                                                            answer.categoryId ===
                                                            categoryId &&
                                                            answer.questionId ===
                                                            questionData.id &&
                                                            answer.dataType === "file"
                                                          );
                                                        }
                                                      ) && (
                                                          <div>
                                                            <Image
                                                              width={200}
                                                              src={`${process.env
                                                                .REACT_APP_BACKEND_PROXY_SERVER
                                                                }ctd/${ctdRequest
                                                                  .QuotationOverview.id
                                                                }/${ctdRequest?.answers.find(
                                                                  (answer) => {
                                                                    return (
                                                                      answer.categoryId ===
                                                                      categoryId &&
                                                                      answer.questionId ===
                                                                      questionData.id &&
                                                                      answer.dataType ===
                                                                      "file"
                                                                    );
                                                                  }
                                                                )?.answer
                                                                }`}
                                                              key={`${ctdRequest?.answers.find(
                                                                (answer) => {
                                                                  return (
                                                                    answer.categoryId ===
                                                                    categoryId &&
                                                                    answer.questionId ===
                                                                    questionData.id &&
                                                                    answer.dataType ===
                                                                    "file"
                                                                  );
                                                                }
                                                              )?.answer.categoryId
                                                                }${ctdRequest?.answers.find(
                                                                  (answer) => {
                                                                    return (
                                                                      answer.categoryId ===
                                                                      categoryId &&
                                                                      answer.questionId ===
                                                                      questionData.id &&
                                                                      answer.dataType ===
                                                                      "file"
                                                                    );
                                                                  }
                                                                )?.answer.questionId
                                                                }`}
                                                              style={{
                                                                margin:
                                                                  "2px 10px 2px 2px",
                                                                cursor: "pointer",
                                                              }}
                                                              alt="Image"
                                                            />
                                                            <Select
                                                              onChange={(value) =>
                                                                onChangeDropdown(
                                                                  value,
                                                                  categoryId,
                                                                  questionData
                                                                )
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                marginTop: "10px",
                                                              }}
                                                              placeholder={
                                                                ctdRequestPlaceholder
                                                                  ? ctdRequestPlaceholder.comment
                                                                  : questionData
                                                                    .ctdCheckRequestOptions
                                                                    ?.dropdownPlaceholder
                                                              }
                                                            >
                                                              {questionData.ctdCheckRequestOptions?.dropdownOptions.map(
                                                                (option, index) => {
                                                                  return (
                                                                    <Option
                                                                      key={index}
                                                                      value={option}
                                                                    >
                                                                      {option}
                                                                    </Option>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                            <div
                                                              style={{
                                                                paddingTop: '10px',
                                                                paddingBottom: '10px',
                                                                color: 'red'
                                                              }}
                                                            >
                                                              {
                                                                quotations.quotation.CTDRequest?.changes?.find(
                                                                  (e) =>
                                                                    e.categoryId ===
                                                                    categoryId &&
                                                                    e.questionId ===
                                                                    questionData.id
                                                                )?.comment
                                                              }

                                                            </div>

                                                            {/* Start - Test */}
                                                            <Upload
                                                              beforeUpload={() => false}
                                                              onChange={(info) =>
                                                                handleUploadFile(
                                                                  info,
                                                                  categoryId,
                                                                  questionData
                                                                )
                                                              }
                                                              multiple={false}
                                                              maxCount={1}
                                                              listType="picture"
                                                              showUploadList={{
                                                                showRemoveIcon: false,
                                                                showPreviewIcon: false,
                                                                showDownloadIcon: false,
                                                              }}
                                                            >
                                                              <Button>
                                                                <UploadOutlined />
                                                                {
                                                                  questionData.uploadFileButtonText
                                                                }
                                                              </Button>
                                                            </Upload>
                                                            {/* End - Test */}
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                )}
                                            </span>
                                          );
                                        })}
                                    </Card>
                                  </div>
                                )
                              })}
                        </Carousel>
                      </div>
                    </Col>
                  </>
                )}
            </Row>
          </Drawer>
        )}
      </div>
    </div >
  );
};

export default CtdChecks
