import * as actionTypes from '../../actions/actionType';

export default (state = [], action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_ANSWER:
      if (state.some((e) => { return e[1] === action.step })) {
        return [...state].map((e) => { return (e[1] === action.step ? [action.answer, action.step] : e) })
      } else {
        return [...state, [action.answer, action.step]]
      }
    case actionTypes.DELETE_ANSWER:
      return [...state].filter((e) => {
        return e[1] !== action.step
      })
    case actionTypes.DELETE_ALL_ANSWERS:
      return []
    default:
      return state;
  }
};