import { LOGIN_USER } from '../../queries/back-end/queries';
import { backendAuthclient } from '../../graphql-endpoints/graphqlEndpoints'
import { useMutation } from 'react-apollo';

const useLoginUserMutation = () => {

  const [loginUserMutation, { loading, error }] = useMutation(LOGIN_USER, { client: backendAuthclient });

  if (loading) return { loading }
  if (error) return { error }

  return {
    loginUserMutation,
    loading,
    error
  }
}

export default useLoginUserMutation