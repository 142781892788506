import * as actionTypes from '../actions/actionType';

export default (state = { productName: '', productId: '' }, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCT:
      return { ...state, productName: action.productName, productId: action.productId }
    case actionTypes.DELETE_PRODUCT:
      return { productName: '', productId: '' }
    default:
      return state;
  }
}