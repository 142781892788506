import React, { useEffect, useState, Suspense } from 'react'
import { Card } from 'antd'
import * as filterAction from '../../redux/actions/quotation/filterAction'
import * as quotationAction from '../../redux/actions/quotationAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import { useSelector, useDispatch } from 'react-redux'
import { SAVE_CHOSEN_MODEL } from '../../queries/back-end/queries'
// import useLogout from '../../hooks/useLogout'
import useQuotationStepsQuery from '../../hooks/cms/useQuotationStepsQuery'
import useSaveChosenModelMutation from '../../hooks/backend/useSaveChosenModelMutation'
import withHandleQuery from '../../hoc/withHandleQuery'
import { CheckCircleOutlined, CloseCircleOutlined, ToolOutlined } from '@ant-design/icons';

const FilterResults = React.lazy(() => import('../../components/cards/quote/FilterResults'));
const ResultCard = React.lazy(() => import('../../components/cards/quote/QuoteResultCard'));

const backendGraphqlEndpoint = process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT

const { Meta } = Card

const QuoteResult = (props) => {

  const {
    dataHoc,
    quotationStepsScreenUrl,
    preOrderScreennUrl,
    history,
  } = props

  const [showingResults, setShowingResults] = useState(5)
  const [isLoading, setIsLoading] = useState(false)

  const answers = useSelector(state => state.answers)
  const results = useSelector(state => state.results)
  const logins = useSelector(state => state.logins)
  const filters = useSelector(state => state.filters)
  const dispatch = useDispatch()
  const isFilterResultsOn = (boolean) => dispatch(filterAction.isFilterResultsOn(boolean));
  const setFilterOptions = (option) => dispatch(filterAction.setFilterOptions(option));
  const setRankBackendValue = (value) => dispatch(filterAction.setRankBackendValue(value));
  const setRankCmsValue = (value) => dispatch(filterAction.setRankCmsValue(value));
  const setQuotation = (quotation) => dispatch(quotationAction.setQuotation(quotation))
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  // const { logout } = useLogout()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const languages = useSelector(state => state.languages)

  const quotationStepsLabels = dataHoc[0] !== undefined && dataHoc[0].quotationStepsLabels
  const saveChosenModelMutation = dataHoc[1] !== undefined && dataHoc[1].saveChosenModelMutation

  const filterIcons = []
  quotationStepsLabels.lables.filterIcons.map((icon) => {

    return filterIcons.push({
      filter: {
        0: icon.icon_off,
        1: icon.icon_on
      }
    })
  })

  const filterLabels = {
    filterCardTitle: quotationStepsLabels.lables.filterCardTitle,
    button: quotationStepsLabels.lables.button,
    filterRanksDropdownName: quotationStepsLabels.lables.filterRanksDropdownName,
    messageInfoFilterRanksDropdown: quotationStepsLabels.lables.messageInfoFilterRanksDropdown,
    messageErrorNoResultsTitle: quotationStepsLabels.lables.messageErrorNoResultsTitle,
    messageErrorNoResultsContent: quotationStepsLabels.lables.messageErrorNoResultsContent,
    messageErrorNoResultsOkButton: quotationStepsLabels.lables.messageErrorNoResultsOkButton,
    filterRanks: quotationStepsLabels.lables.filterRanks,
    filterIcons: quotationStepsLabels.lables.filterIcons
  }

  const goToQuoteStepsScreen = (stepNumber) => {
    history.push({
      pathname: quotationStepsScreenUrl,
      state: { stepNumber: stepNumber, showSalesmanButtons: logins.accessType === 'employee' }
    })
  }

  const totalNumberOfResults = results?.result?.length

  const showAllResults = () => {
    setShowingResults(totalNumberOfResults)
  }

  const goToPreOrder = async (order) => {

    await saveChosenModelMutation({
      mutation: SAVE_CHOSEN_MODEL,
      variables: { quotationId: order.id }
    }).then((result) => setQuotation(result.data.saveChosenModel[0])).catch((error) => {
      console.log(error)
      // logout()
    })

    history.push({
      pathname: preOrderScreennUrl,
    })
  }

  const quotationResultsCards = () => {
    const cardStyle = !logins.isMobile ? { margin: 16, height: 'fit-content', minWidth: '350px', cursor: 'pointer' } : { margin: 16, height: 'fit-content', cursor: 'pointer' }

    return (
      results?.result?.map((result, index) => {

        let quoatationResults = null
        let deleteQuotationResult = []

        if (true) {
          quoatationResults = (
            <Card
              data-cy={`quotation-${index}`}
              style={cardStyle}
              bordered={false}
              title={<b>{result?.WizardCompositeKey?.Model?.name}</b>}
              key={result?.WizardCompositeKey?.Model?.name + index}
              onClick={() => { goToPreOrder(result) }}
            >
              <Meta
                title={<div style={{ float: 'right' }}>
                  <h4>
                    <b>{filters.filterOptions.includes(6) ? '' : `€${result?.quotationPrice}`}</b>
                  </h4></div>}
                description={
                  <div data-cy={`quotation-product-${index}`}>
                    <div className='mb-2' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img alt={result?.WizardCompositeKey?.Model?.name} height="250" src={result.WizardCompositeKey?.Model?.picture} />
                      {index === 0 ?
                        <img
                          alt="Mobilae best choice"
                          height="150"
                          width="150"
                          src="https://my-environment-assets.s3.eu-central-1.amazonaws.com/en/Global+items/SpecialOffer.png"
                          style={{ position: 'absolute', right: '-20px', top: '300px' }}
                        />
                        : null}
                    </div>
                    {/* week delivery */}
                    {
                      result.ModelInstallationTimetable.map((week, index) => {

                        return <div key={index}>{`(${week.week}) ${week.startDate} - ${week.endDate}`}</div>
                      })
                    }
                    <div>
                      <ToolOutlined className='m-2' style={{ fontSize: '28px' }} />
                      <ul>
                        {
                          result.modelSpecs.map((spec, index) => {

                            let specValue = ''

                            if (spec.description.length > 0) {
                              specValue = spec.description?.filter((language) => language.language === languages.language.split('_')[0]).pop()?.value
                            } else {
                              specValue = spec.value !== "T" && spec.value !== "F" ? spec.value : (spec.value === "T" ? <CheckCircleOutlined /> : <CloseCircleOutlined />)
                            }

                            if (spec.value !== null && spec.value !== undefined && spec.value !== "") {
                              return (
                                spec.name.filter((language) => language.language === languages.language.split('_')[0]).pop() !== undefined &&
                                <li key={spec.value + index}>
                                  {spec.name.filter((language) => language.language === languages.language.split('_')[0]).pop().value}: <b>{specValue}{spec.unit}</b>
                                </li>
                              )
                            } else {
                              return null
                            }
                          })
                        }
                      </ul>
                    </div>
                    <div style={{ display: 'table', tableLayout: 'fixed' }}>

                      {result.properties?.map((property, index) => {
                        // The response from stage and production backend is not the same. This is why we need to check the filter number in the string (ex: 'Filter_01')
                        if (filters.filterOptions.includes(parseInt(property.type.replace(/^\D+/g, '')))) {
                          // property 1 means that the filter is true for this functionality
                          if (property.property === 1) {
                            deleteQuotationResult.push(false)
                            return (
                              <div key={index} style={{ display: 'table-cell', verticalAlign: 'top', width: '75px' }} >
                                <img width="70" height="70" style={{ maxWidth: '100%', height: 'auto' }} src={filterIcons[parseInt(property.type.replace(/^\D+/g, '')) - 1]?.filter[property.property]} alt={property.type} />
                              </div>
                            )
                          } else {
                            deleteQuotationResult.push(true)
                            return null
                          }

                        }
                        return null
                      })}

                    </div>
                  </div>
                }
              />
            </Card>
          )
        }

        if (deleteQuotationResult.includes(true)) {
          quoatationResults = null
        }
        return quoatationResults
      })
    )
  }

  const goToFilter = () => {
    isFilterResultsOn(true)
  }

  const cardStyle = !logins.isMobile ? { background: 'transparent', flexFlow: 'row wrap', justifyContent: 'center', height: 'fit-content' } : { background: 'transparent', minWidth: 'auto' }
  const cardResultsStyle = !logins.isMobile ? { background: 'transparent', flexFlow: 'row wrap', maxWidth: '430px' } : { background: 'transparent', minWidth: 'auto', margin: '16px' }

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`}>

        <div className="card border-0" style={cardStyle}>
          {filters.isFilterResultsOn && <FilterResults filterOn={filters.filterOptions} setFilterOn={setFilterOptions} filterLabels={filterLabels} rank={filters.rankBackendValue} setRank={setRankBackendValue} rankValue={filters.rankCmsValue} setRankValue={setRankCmsValue} quotationStepsScreenUrl={quotationStepsScreenUrl} />}


          {!filters.isFilterResultsOn &&
            <div style={{ display: 'contents' }}>
              {quotationResultsCards()?.filter((e) => { return e !== null }).filter((e, index) => { return index <= showingResults })}
              {totalNumberOfResults > showingResults && <div className="btn btn-orange w-100" onClick={() => showAllResults()} style={{ fontSize: 'xx-large', marginLeft: '45%', marginRight: '45%' }}>+</div>}
            </div>
          }
        </div>

        {!filters.isFilterResultsOn && <div className="card border-0" style={cardResultsStyle}>
          <ResultCard results={answers} questions={quotationStepsLabels.lables.question} goToStep={(stepNumber) => { goToQuoteStepsScreen(stepNumber) }} showFilter={true} goToFilter={() => { goToFilter() }} resultsTitleJson={quotationStepsLabels.internationalizationJsonProducts} filterCardTitle={quotationStepsLabels.lables.filterCardTitle} rankValue={filters.rankCmsValue} messageInfoFilterRanksDropdown={filterLabels.messageInfoFilterRanksDropdown} />
        </div>}
      </div >
    </Suspense>
  )
}

export default withHandleQuery([useQuotationStepsQuery, useSaveChosenModelMutation])(React.memo(QuoteResult))