import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { LOGIN_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useLoginQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(LOGIN_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    newAccountLogin: i18next.t(`new_account.${languages.language}`),
    usernameLogin: i18next.t(`username.${languages.language}`),
    usernameErrorMessageLogin: i18next.t(`username_error_message.${languages.language}`),
    passwordLogin: i18next.t(`password.${languages.language}`),
    passwordErrorMessageLogin: i18next.t(`password_error_message.${languages.language}`),
    usernameOrPasswordErrorMessageLogin: i18next.t(`username_or_password_error_message.${languages.language}`),
    inactiveAccountErrorMessage: i18next.t(`inactive_account_error_message.${languages.language}`),
    rememberMeLogin: i18next.t(`remember_me.${languages.language}`),
    forgotPasswordLogin: i18next.t(`forgot_password.${languages.language}`),
    loginButtonLogin: i18next.t(`login_button.${languages.language}`),
    optionLogin: i18next.t(`option.${languages.language}`),
    bannerMessageLogin: i18next.t(`banner_message.${languages.language}`),
    confirmRegistrationSuccessTitle: i18next.t(`confirm_registration_success_title.${languages.language}`),
    confirmRegistrationSuccessContent: i18next.t(`confirm_registration_success_content.${languages.language}`),
    confirmRegistrationSucessButton: i18next.t(`confirm_registration_sucess_button.${languages.language}`),
    confirmRegistrationErrorTitle: i18next.t(`confirm_registration_error_title.${languages.language}`),
    confirmRegistrationErrorContent: i18next.t(`confirm_registration_error_content.${languages.language}`),
    confirmRegistrationErrorButton: i18next.t(`confirm_registration_error_button.${languages.language}`),
    country: i18next.t(`country.${languages.language}`),
    countryErrorMessage: i18next.t(`country_error_message.${languages.language}`),
    countryOptionFlanders: i18next.t(`country_option_flanders.${languages.language}`),
    countryOptionItaly: i18next.t(`country_option_italy.${languages.language}`),
    countryOptionWalloniaBrussels: i18next.t(`country_option_wallonia_brussels.${languages.language}`),
    countryOptionFrance: i18next.t(`country_option_france.${languages.language}`),
    countryOptionGermany: i18next.t(`country_option_germany.${languages.language}`),
    countryOptionTheNetherlands: i18next.t(`country_option_the_netherlands.${languages.language}`),
    preferredLanguage: i18next.t(`preferred_language.${languages.language}`),
    preferredLanguageErrorMessage: i18next.t(`preferred_language_error_message.${languages.language}`),
    preferredLanguageOptionFlanders: i18next.t(`preferred_language_option_flanders.${languages.language}`),
    preferredLanguageOptionItaly: i18next.t(`preferred_language_option_italy.${languages.language}`),
    preferredLanguageOptionWalloniaBrussels: i18next.t(`preferred_language_option_wallonia_brussels.${languages.language}`),
    preferredLanguageOptionFrance: i18next.t(`preferred_language_option_france.${languages.language}`),
    preferredLanguageOptionGermany: i18next.t(`preferred_language_option_germany.${languages.language}`),
    preferredLanguageOptionTheNetherlands: i18next.t(`preferred_language_option_the_netherlands.${languages.language}`),
    forgotPasswordErrorMessage: i18next.t(`forgot_password_error_message.${languages.language}`),
    forgotPasswordSuccessMessage: i18next.t(`forgot_password_success_message.${languages.language}`),
    forgotPasswordPlaceHolder: i18next.t(`forgot_password_place_holder.${languages.language}`),
    forgotPasswordTitle: i18next.t(`forgot_password_title.${languages.language}`),
    countryValueFlanders: data.card.Card[0].country_value_flanders,
    countryValueItaly: data.card.Card[0].country_value_italy,
    countryValueWallonia_brussels: data.card.Card[0].country_value_wallonia_brussels,
    countryValueFrance: data.card.Card[0].country_value_france,
    countryValueGermany: data.card.Card[0].country_value_germany,
    countryValueTheNetherlands: data.card.Card[0].country_value_the_netherlands,
    preferredLanguageValueFlanders: data.card.Card[0].preferred_language_value_flanders,
    preferredLanguageValueItaly: data.card.Card[0].preferred_language_value_italy,
    preferredLanguageValueWallonia_brussels: data.card.Card[0].preferred_language_value_wallonia_brussels,
    preferredLanguageValueFrance: data.card.Card[0].preferred_language_value_france,
    preferredLanguageValueGermany: data.card.Card[0].preferred_language_value_germany,
    preferredLanguageValueTheNetherlands: data.card.Card[0].preferred_language_value_the_netherlands,
  }

  return {
    loginLabels: lables,
    loading,
    error
  }
}

export default useLoginQuery