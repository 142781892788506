import * as actionTypes from '../actions/actionType';

export default (state = { isImpersonate: false, impersonateParameter: '', impersonateUsername: '' }, action) => {
  switch (action.type) {
    case actionTypes.IS_IMPERSONATE:
      return { ...state, isImpersonate: action.isImpersonate }
    case actionTypes.SET_IMPERSONATE_PARAMETER:
      return { ...state, impersonateParameter: action.impersonateParameter }
      case actionTypes.SET_IMPERSONATE_USERNAME:
      return { ...state, impersonateUsername: action.impersonateUsername }
    default:
      return state;
  }
};