import { useSelector } from 'react-redux'

const useCardStyle = () => {

  const logins = useSelector(state => state.logins)

  const cardStyle = !logins.isMobile ? { background: 'transparent' } : { background: 'transparent', minWidth: 'auto' }

  return {
    cardStyle: cardStyle
  }
}

export default useCardStyle