export const loadFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem('my-environment');
    if (serializedState === null || serializedState === []) {
      return undefined;
    }
    // return JSON.parse(atob(serializedState.split("").reverse().join("")));
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveToSessionStorage = (state) => {
  try {
    // sessionStorage.setItem('my-environment', btoa(JSON.stringify(state)).split("").reverse().join(""));
    sessionStorage.setItem('my-environment', JSON.stringify(state));
  } catch (err) {
    // Ignore write errors.
  }
};

export const removeState = () => {
  try {
    sessionStorage.removeItem("my-environment");
  } catch (err) {
    return undefined;
  }
};