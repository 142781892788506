import React from 'react'
import Spinner from '../components/spinner/Spinner'
import ErrorHandler from '../components/cards/ErrorHandler'

const withHandleQuery = (apolloHooks, variable = '', logMeOut = true) => (WrappedComponent) => {

  const WithHandleQuery = (props) => {

    const {
      documentType,
      period,
      quotationId
    } = props

    if (documentType) {
      variable = documentType.id
    }

    if (period) {
      variable = period
    }

    if (quotationId) {
      variable = quotationId
    }

    const result = apolloHooks.map((hook) => {
      const data = hook(variable)

      if (data.loading) {
        return { hookLoading: data.loading }
      }

      if (data.error) {
        if (data.error.message === 'GraphQL error: Current password is incorrect') {
          return { hookError: false }
        }
        if (data.error.message === 'You have not enough rights for this action') {
          return { hookError: false }
        }
        if (data.error.message === 'GraphQL error: Invalid email or password') {
          return { hookError: false }
        }
        if (data.error.message === 'GraphQL error: This email is already taken') {
          return { hookError: false }
        }
        return { hookError: data.error }
      }

      return {
        data: data,
        hookLoading: data.loading,
        hookError: data.error,
        refetch: data.refetch
      }

    })

    if (result.some((loading) => loading.hookLoading === true)) {
      return <Spinner />
    }

    if (result.some((error) => error.hookError === true)) {
      return <ErrorHandler logMeOut={logMeOut} />
    }

    return <WrappedComponent {...props} dataHoc={result.map(data => data.data)} />
  }

  return WithHandleQuery
}

export default withHandleQuery
