import { REGISTER_WIZARD_KEY } from '../../queries/back-end/queries'
import { useMutation } from 'react-apollo'

const useRegisterWizardKeyMutation = () => {

  const [registerWizardKeyMutation, { loading, error }] = useMutation(REGISTER_WIZARD_KEY);

  if (loading) return { loading }
  if (error) return { error }

  return {
    registerWizardKeyMutation,
    loading,
    error
  }

}

export default useRegisterWizardKeyMutation