import React from 'react'
import { Page, View, Document, Image } from '@react-pdf/renderer';

const QuotationPdf = (props) => {

  const {
    image,
    pdfCoverPage,
    pdfQuotationStepsInformationPage,
    pdfAuthor,
    pdfKeywords,
    pdfSubject,
    pdfTitle,
    pdfType,
  } = props

  return (
    <Document
      author={pdfAuthor}
      keywords={pdfKeywords}
      subject={pdfSubject}
      title={pdfTitle}
    >
      {/* Cover */}
      <Page wrap={false} orientation="portrait" size="A4">
        <View>
          <Image
            style={{ width: 'auto', height: '100%' }}
            src={pdfCoverPage}
            crossOrigin="anonymous"
          />
        </View>
      </Page>

      {/* Main Information */}
      <Page object-fit="fill" style={{ width: '100%' }} wrap={false} orientation="portrait" size="A4">
        <View style={{
          width: '100%',
          height: '100%',
          padding: 0,
        }}>
          <Image
            style={{ position: 'relative' }}
            src={image}
            alt="images"
            crossOrigin="anonymous"
          />
        </View>
      </Page>

      {/* Steps Information */}
      {
        pdfType === 'quotation' &&
        <Page wrap={false} orientation="portrait" size="A4">
          <View>
            <Image
              style={{ width: 'auto', height: '100%' }}
              src={pdfQuotationStepsInformationPage}
              crossOrigin="anonymous"
            />
          </View>
        </Page>
      }
    </Document>
  )
}

export default QuotationPdf
