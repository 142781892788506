import * as actionTypes from '../../actions/actionType';

export default (state = { filterOptions: [], rankBackendValue: '', rankCmsValue: '', isFilterResultsOn: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_OPTIONS:
      return { ...state, filterOptions: action.filterOptions }
    case actionTypes.SET_RANK_BACKEND_VALUE:
      return { ...state, rankBackendValue: action.rankBackendValue }
    case actionTypes.SET_RANK_CMS_VALUE:
      return { ...state, rankCmsValue: action.rankCmsValue }
    case actionTypes.IS_FILTER_RESULTS_ON:
      return { ...state, isFilterResultsOn: action.isFilterResultsOn }
    default:
      return state;
  }
};
