import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { HOUSE_YEAR_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useHouseYearQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(HOUSE_YEAR_QUERY, {client: cmsClient});

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    cardTitle: i18next.t(`card_title.${languages.language}`),
    title: i18next.t(`title.${languages.language}`),
  }

  return {
    houseYearLabels: lables,
    loading,
    error
  }
}

export default useHouseYearQuery
