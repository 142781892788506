import * as actionTypes from '../actions/actionType';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_ORDERS:
      return { ...state, Orders: action.customerOrders }
    case actionTypes.DELETE_CUSTOMER_ORDERS:
      return {}
    default:
      return state;
  }
};