import React, { useState, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as provisionAction from '../../redux/actions/provisionAction'
import useCardStyle from '../../hooks/useCardStyle'

const Documents = React.lazy(() => import('../../components/cards/Documents'))
const Visit = React.lazy(() => import('../../components/cards/Visit'))
const WhatExpect = React.lazy(() => import('../../components/cards/WhatExpect'))
const LocalAdvisor = React.lazy(() => import('../../components/cards/LocalAdvisor'))
const Info = React.lazy(() => import('../../components/cards/Info'))
const PriceList = React.lazy(() => import('../../components/cards/PriceList'))
const Video = React.lazy(() => import('../../components/cards/Video'))
const QuoteSteps = React.lazy(() => import('../../components/cards/QuoteSteps'))
const Assistance = React.lazy(() => import('../../components/cards/Assistance'))
const MeasurementAppLink = React.lazy(() => import('../../components/cards/MeasurementAppLink'))

const MyHomeVisit = (props) => {

  const [showLocalAdvisor, setShowLocalAdvisor] = useState('')
  const [showVisit, setShowVisit] = useState('')
  const [showWhatExpect, setShowWhatExpect] = useState('')
  const [showAssistance, setShowAssistance] = useState('')
  const [showInfo, setShowInfo] = useState('')
  const [showDocuments, setShowDocuments] = useState('')

  const [columnOne, setColumnOne] = useState('')
  const [columnTwo, setColumnTwo] = useState('')
  const [columnThree, setColumnThree] = useState('')
  const [columnFour, setColumnFour] = useState('')

  useEffect(() => {
    setColumnOne(showLocalAdvisor ? 'card border-0' : '')
    setColumnTwo(showVisit ? 'card border-0' : '')
    setColumnThree(showWhatExpect ? 'card border-0' : '')
    setColumnFour(showAssistance || showInfo || showDocuments ? 'card border-0' : '')

  }, [showLocalAdvisor, showVisit, showWhatExpect, showAssistance, showInfo, showDocuments, setColumnOne, setColumnTwo, setColumnThree, setColumnFour])

  const logins = useSelector(state => state.logins)
  const dispatch = useDispatch()
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { cardStyle } = useCardStyle()

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-home-visit">

        <div className={columnOne} style={cardStyle}>
          <LocalAdvisor id="local-advisor" setShowLocalAdvisor={setShowLocalAdvisor} />
        </div>

        <div className={columnTwo} style={cardStyle}>
          <Visit setShowVisit={setShowVisit} />
        </div>

        <div className={columnThree} style={cardStyle}>
          <WhatExpect setShowWhatExpect={setShowWhatExpect} />
        </div>

        <div className={columnFour} style={cardStyle}>
          <Assistance planVisit={true} setShowAssistance={setShowAssistance} />
          <Info components={
            [
              <PriceList />,
              < Video />,
              logins.accessType === 'employee' && <QuoteSteps />,
              logins.accessType === 'employee' && <MeasurementAppLink />
            ]
          }
            setShowInfo={setShowInfo} />
          <Documents setShowDocuments={setShowDocuments} />
        </div>
      </div>
    </Suspense>
  )
}

export default React.memo(MyHomeVisit)
