import React from 'react'
import * as logoutAction from '../redux/actions/logoutAction'
import * as impersonateAction from '../redux/actions/impersonateAction'
import * as alertBannerAction from '../redux/actions/alertBannerAction'
import * as quotationAction from '../redux/actions/quotationAction'
import * as resultsAction from '../redux/actions/quotation/resultsAction'
import * as customerProductsAction from '../redux/actions/customerProductsAction'
import * as customerOrdersAction from '../redux/actions/customerOrdersAction'
import { useDispatch, useSelector } from 'react-redux'
import { removeState } from '../session-storage/sessionStorage'
import { LOGOUT_USER } from '../queries/back-end/queries'
import { backendClient, logoutImpersonateClient } from '../graphql-endpoints/graphqlEndpoints'
import { useHistory } from "react-router-dom";
import useLoginQuery from '../hooks/cms/useLoginQuery'
import ErrorHandler from '../components/cards/ErrorHandler'
import Spinner from '../components/spinner/Spinner'

const useLogout = () => {
  let history = useHistory();

  const logins = useSelector(state => state.logins)
  const dispatch = useDispatch()
  const logoutUser = () => dispatch(logoutAction.logoutUser())
  const setImpersonateUsername = (username) => dispatch(impersonateAction.setImpersonateUsername(username))
  const setImpersonateParameter = (parameter) => dispatch(impersonateAction.setImpersonateParameter(parameter))
  const setImpersonification = (boolean) => dispatch(impersonateAction.setImpersonification(boolean))
  const setAlertBannerMessage = (message) => dispatch(alertBannerAction.setAlertBannerMessage(message))
  const deleteQuotation = () => dispatch(quotationAction.deleteQuotation())
  const setQuotationResults = (result) => dispatch(resultsAction.setQuotationResults(result));
  const deleteCustomerOrders = (products) => dispatch(customerOrdersAction.deleteCustomerOrders(products));
  const deleteCustomerProducts = (orders) => dispatch(customerProductsAction.deleteCustomerProducts(orders));

  const { loginLabels, loginLoading, loginError } = useLoginQuery()

  if (loginLoading) {
    return <Spinner />
  }

  if (loginError) {
    return <ErrorHandler />
  }

  const logout = async () => {

    logoutUser()
    if (logins.isAuthenticated === true) {
      await backendClient.mutate({
        mutation: LOGOUT_USER
      })
    }
    backendClient.cache.reset()
    removeState()
  }

  const logoutImpersonation = async (dashboardScreenUrl) => {
    backendClient.cache.reset()
    setImpersonateParameter('_exit')
    setImpersonateUsername('')
    if (history.action !== 'POP') {
      history.push(dashboardScreenUrl)
    }
    await logoutImpersonateClient()
    setImpersonification(false)

    setAlertBannerMessage(`${loginLabels.bannerMessageLogin} ${logins.username}`)
    deleteQuotation()
    setQuotationResults([])

    deleteCustomerOrders()
    deleteCustomerProducts()
  }

  return {
    logout: logout,
    logoutImpersonation: logoutImpersonation
  }
}

export default useLogout
