import { SAVE_CHOSEN_MODEL } from '../../queries/back-end/queries';
import { useMutation } from 'react-apollo';

const useSaveChosenModelMutation = () => {

  const [saveChosenModelMutation, { loading, error }] = useMutation(SAVE_CHOSEN_MODEL);

  if (loading) return { loading }
  if (error) return { error }

  return {
    saveChosenModelMutation,
     loading,
   error
  }
}

export default useSaveChosenModelMutation