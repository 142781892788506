import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { NOT_FOUND_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useNotFoundQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(NOT_FOUND_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    subTitle: i18next.t(`sub_title.${languages.language}`),
    backHomeButton: i18next.t(`back_home_button.${languages.language}`)
  }

  return {
    notFoundLabels: lables,
    loading,
    error
  }
}

export default useNotFoundQuery
