import { RESET_PASSWORD } from '../../queries/back-end/queries';
import { backendAuthclient } from '../../graphql-endpoints/graphqlEndpoints'
import { useMutation } from 'react-apollo';

const useResetPasswordMutation = () => {

  const [resetPasswordMutation, { loading, error }] = useMutation(RESET_PASSWORD, { client: backendAuthclient });

  if (loading) return {loading }
  if (error) return {  error }

  return {
    resetPasswordMutation,
     loading,
    error
  }
}

export default useResetPasswordMutation