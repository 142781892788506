import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from 'apollo-boost';
import axios from 'axios';
import { onError } from "apollo-link-error";
import * as logoutAction from '../redux/actions/logoutAction';
import { store } from '../index'
import { removeState } from '../session-storage/sessionStorage'
import { LOGOUT_USER } from '../queries/back-end/queries'
import { loadFromSessionStorage } from '../session-storage/sessionStorage'

const backendAuthenticationGraphqlEndpoint = process.env.REACT_APP_BACKEND_AUTHENTICATION_GRAPHQL_ENDPOINT
const backendGraphqlEndpoint = process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT
const cmsGraphqlEndpoint = process.env.REACT_APP_CMS_GRAPHQL_ENDPOINT

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production' && graphQLErrors) {
    console.log(process.env.REACT_APP_ENVIRONMENT)
    console.log(graphQLErrors)
    console.log(networkError)
    graphQLErrors.map(({ message, debugMessage, path }) => {
      console.log(process.env.REACT_APP_ENVIRONMENT)
      console.error(`[GraphQL error]: Message: ${message} or ${debugMessage}, Operation: ${operation.operationName}, Path: ${path}`)
      return null
    })
  }

  if (graphQLErrors && process.env.REACT_APP_ENVIRONMENT === 'production')
    graphQLErrors.map(({ message }) => {

      if (message === 'You have not enough rights for this action') {
        return null
      }

      if (message === 'Current password is incorrect') {
        return null
      }

      if (message === 'Invalid email or password') {
        return null
      }

      if (message === 'validation') {
        return null
      }

      if (message === 'This email is already taken') {
        return null
      }

      if (loadFromSessionStorage()?.logins?.isAuthenticated === true) {
        backendClient.mutate({ mutation: LOGOUT_USER })
        window.location.reload()
      }

      return (
        store.dispatch(logoutAction.logoutUser()),
        removeState()
      )
    }
    )
  if (networkError && (networkError.status !== undefined || networkError.statusCode !== undefined) && process.env.REACT_APP_ENVIRONMENT !== 'dev') {
    store.dispatch(logoutAction.logoutUser())
    if (loadFromSessionStorage()?.logins?.isAuthenticated === true) {
      backendClient.mutate({ mutation: LOGOUT_USER })
    }
    removeState()
  }

});

const cmsClientHttpLink = new HttpLink({
  uri: `${cmsGraphqlEndpoint}/graphql`,
});

const cmsClient = new ApolloClient({
  link: ApolloLink.from([errorLink, cmsClientHttpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: false
});

const backendAuthclientHttpLink = new HttpLink({
  uri: backendAuthenticationGraphqlEndpoint,
  credentials: "include"
});

const backendAuthclient = new ApolloClient({
  link: ApolloLink.from([errorLink, backendAuthclientHttpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: false
});

async function impersonateClient(username) {
  const backendClientParameter = `?_switch_user=${username}`
  return await axios.get(`${backendGraphqlEndpoint}${backendClientParameter}&query={impersonation{isImpersonating}}`,
    { withCredentials: true }
  ).then(() => { return true }).catch(() => {
    store.dispatch(logoutAction.logoutUser())
    backendClient.mutate({
      mutation: LOGOUT_USER
    })
    removeState()
    return false
  })
}

async function logoutImpersonateClient() {
  const backendClientParameter = `?_switch_user=_exit`
  await axios.get(`${backendGraphqlEndpoint}${backendClientParameter}&query={impersonation{isImpersonating}}`,
    { withCredentials: true }
  ).then(res => res).catch(() => {
    store.dispatch(logoutAction.logoutUser())
    backendClient.mutate({
      mutation: LOGOUT_USER
    })
    removeState()
  })
}

const backendClientHttpLink = new HttpLink({
  uri: `${backendGraphqlEndpoint}`,
  credentials: "include",
  connectToDevTools: true
});

const backendClient = new ApolloClient({
  link: ApolloLink.from([errorLink, backendClientHttpLink]),
  cache: new InMemoryCache()
})

export { cmsClient, backendAuthclient, backendClient, impersonateClient, logoutImpersonateClient };
