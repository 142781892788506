import React from 'react'
import { Spin } from 'antd'
import Logo_Spinner from '../../Logo_Spinner.gif'

const Spinner = () => {

  const antIcon = <img src={Logo_Spinner} alt="Spin" />

  return (
    <div className="spin" >
      <Spin indicator={antIcon} tip="" size="large" />
    </div>
  )
}

export default React.memo(Spinner)
