import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { LOGOUT_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useLogoutQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(LOGOUT_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    logout: i18next.t(`logout.${languages.language}`),
    logoutImpersonation: i18next.t(`logout_impersonation.${languages.language}`)
  }

  return {
    logoutQueryLabels: lables,
    loading,
    error
  }
}

export default useLogoutQuery
