import gql from 'graphql-tag';

const SCREENS_QUERY = gql`
query ScreensQuery{
  screens {
    name,
    id,
    url_path,
    menu_icon,
    internationalization_json,
    logo {
      ... on ComponentLogoLogo {
        background_image
        logo_url_en
        logo_url_nl
        logo_url_fr
        logo_url_de
        logo_url_it
        login_logo_url_en
        login_logo_url_nl
        login_logo_url_fr
        login_logo_url_de
        login_logo_url_it
      }
    }
    meta {
      ... on ComponentMetaMeta {
        description
        internationalization_json
      }
    }
  }
}
`

const PERSONAL_INFORMATION_QUERY = gql`
query PersonalInformationQuery{
  card(id: 3) {
    id
    Name
    Card {
      ... on ComponentCardsPersonalInformation {
        card_name
        show_card
        name
        first_name
        last_name
        address
        zip_code
        city
        email
        phone_number
        edit_button
        delete_button
        delete_account_popup_question
        delete_account_popup_cancel_button
        delete_account_popup_confirm_button
        preferred_language_value_flanders
        preferred_language_value_wallonia_brussels
        preferred_language_value_france
        preferred_language_value_germany
        preferred_language_value_the_netherlands
        preferred_language_value_italy
        internationalization_json
      }
    }
  }
}
`

const BROCHURE_QUERY = gql`
query brochureQuery{
  card(id: 4) {
    Card {
      ... on ComponentCardsPersonalInterest {
        card_name
        show_card
        brochure_button
        call_me_button
        brochure_icon
        internationalization_json
        products {
          category_name
          show_in_germany
          show_in_netherlands
          show_in_france
          show_in_belgium
          show_in_italy
          category_brochure
          icon_url
          internationalization_json
        }
      }
    }
  }
}
`

const DOCUMENTS_QUERY = gql`
query DocumentsQuery {
  card(id: 21) {
    Card {
      ... on ComponentCardsDocuments {
        card_name
        show_card
				search_button
        upload_button
        upload_text
        upload_information 
        upload_success_message
        upload_failed_message
        internationalization_json
      }
    }
  }
}
`

const LOCAL_ADVISOR_QUERY = gql`
query LocalAdvisorQuery {
  card(id: 13) {
    Card {
      ... on ComponentCardsLocalAdvisor {
        card_name
        show_card
        certified_text
        internationalization_json
      }
    }
  }
}
`

const QUOTATION_STEPS_QUERY = gql`
query QuotationStepsQuery($productId: Int ){
  products(where: { back_end_id: $productId }) {
    id
    name
    show_product
    image
    back_end_id
    results_title
    internationalization_json
    quotation {
      ... on ComponentQuotationQuotation {
        question {
          title
          question
          alert
          internationalization_json_question
        }
        answer {
          step
          is_dependent
          dependent_answer
          replicate_anwser
          internationalization_json_answer
          options {
            option_url
            answer_back_end_id
            alert
            alert_msg
            option_number
            dependent_option_number
            has_dependent
            which_answer_depend_of_me
            info
            info_msg
            internationalization_json_option
          }
        }
      }
    }
    filter {
      card_name
      filter_ranks_dropdown_name
      message_info_filter_ranks_dropdown
      message_error_no_results_title
      message_error_no_results_content
      message_error_no_results_ok_button
      button
      internationalization_json
      ... on ComponentFilterFilter {
        filter_icons{
          filter_icons_name
          filter_icons_id
          icon_on
          icon_off
          internationalization_json_filter_icons
        }
        filter_ranks {
          filter_ranks_dropdown_name
          filter_ranks_dropdown_id
          internationalization_json_filter_ranks
        }
      }
    }
    pre_order {
      finalise_button
      card_colors_title
      card_colors_option_one
      card_colors_option_two
      card_extra_services_title
      card_extra_services_option_one
      card_extra_services_option_two
      card_additional_codes_title
      card_additional_codes_table_description
      card_additional_codes_table_quantity
      card_additional_codes_table_price
      card_additional_codes_select_button
      card_additional_codes_delete_codes_button
      card_additional_codes_popup_title
      card_additional_codes_popup_option
      card_additional_codes_popup_dropdown_text
      card_additional_codes_popup_button
      card_additional_codes_popup_error_message
      internationalization_json_pre_order
    }
    order {
      card_product_specifications
      card_product_color_option_one
      card_product_color_option_two
      card_product_extra_service_option_one
      card_product_extra_service_option_two
      card_product_additional_codes_title
      card_product_additional_codes_table_description
      card_product_additional_codes_table_quantity
      card_product_additional_codes_table_price
      card_payment_method_title
      card_payment_method_dropdown_text
      card_payment_method_dropdown_info_message
      payment_method_dropdown_options {
        ... on ComponentPaymentMethodDropdownOptionsPaymentMethodDropdownOptions {
          option
          internationalization_json_payment_method
        }
      }
      card_special_promotions_title
      card_special_promotions_promo_code
      card_special_promotions_promo_code_message
      card_special_promotions_additional_discount
      card_special_promotions_additional_discount_message
      card_special_promotions_button
      card_special_promotions_info_message
      card_remarks_title
      card_remarks_text
      card_delivery_address_title
      card_delivery_address_name
      card_delivery_address_address
      card_delivery_address_zip_code
      card_delivery_address_phone
      card_delivery_address_email
      card_delivery_address_edit_button
      card_delivery_address_success_message_title
      card_delivery_address_success_message_content
      card_delivery_address_error_message_title
      card_delivery_address_error_message_content
      card_confirm_order_title
      card_confirm_order_content
      card_confirm_order_check_box
      card_confirm_order_send_button
      card_confirm_order_popup_title
      card_confirm_order_popup_content
      card_confirm_order_popup_cancel_button
      card_confirm_order_popup_ok_button
      internationalization_json_order
    }
  }
}
`

const PRODUCTS_QUERY = gql`
query ProductsQuery{
  products {
    id
    name
    show_product
    image
    back_end_id
    internationalization_json
  }
}
`

const VISIT_QUERY = gql`
query VisitQuery {
  card(id: 7) {
    Card {
      ... on ComponentCardsHomeVisit {
        card_name
        show_card
        icon
        icon_first_home_visit
        text
        scheduled
        edit_button
        drawer_message
        drawer_select_button
        card_name_maintenance_visit
        icon_maintenance_visit
        text_maintenance_visit
        advisor_maintenance_visit
        scheduled_maintenance_visit
        drawer_message_maintenance_visit
        drawer_select_button_maintenance_visit
        internationalization_json
      }
    }
  }
}
`

const COOKIE_CONSENT_QUERY = gql`
query CookieConsentQuery {
  card(id: 27) {
    id
    Name
    Card {
      ... on ComponentCardsCookieConsent {
        name
        banner_text
        drawer_button
        banner_button
        drawer_content
        internationalization_json
      }
    }
  }
}
`

const APPOINTMENTS_QUERY = gql`
query AppointmentsQuery {
  card(id: 28) {
    Card {
      ... on ComponentCardsAppointments {
        card_name
        icon
        internationalization_json
      }
    }
  }
}
`

const ASSISTANCE_QUERY = gql`
query AssistanceQuery {
  card(id: 15) {
    Card {
      ... on ComponentCardsAssistance {
        card_name
        show_card
        internationalization_json
        call_back {
          ... on ComponentCardsCallBack {
            card_name
            icon
          }
        }
        more_information {
          ... on ComponentCardsMoreInformation {
            card_name
            icon
          }
        }
        plan_appointment {
          ... on ComponentCardsPlanAppointment {
            card_name
            icon
          }
        }
      }
    }
  }
}
`

const CLIENT_QUOTATION_QUERY = gql`
query ClientQuotationQuery {
  card(id: 29) {
    Card {
      ... on ComponentCardsClientQuotation {
        card_name
        show_card
        edit_button
        internationalization_json
      }
    }
  }
}
`

const COMMERCIAL_APPOINTMENTS_QUERY = gql`
query CommercialAppointmentsQuery {
  card(id: 30) {
    Card {
      ... on ComponentCardsCommercialAppointments {
        card_name
        customer_name
        localization_button
        impersonate_button
        internationalization_json
      }
    }
  }
}
`

const COMMERCIAL_PERSONAL_INFORMATION_QUERY = gql`
query CommercialPersonalInformationQuery  {
  card(id: 31) {
    Card {
      ... on ComponentCardsCommercialPersonalInformation {
        card_title
        name
        email
        submit_button
        edit_button
        rule_insert_name 
        rule_insert_email 
        rule_invalid_email
        internationalization_json
      }
    }
  }
}
`

const CONFIRMATION_QUERY = gql`
query ConfirmationQuery  {
  card(id: 32) {
    Card {
      ... on ComponentCardsConfirmation {
        card_name
        drawer_document
        icon
        internationalization_json
      }
    }
  }
}
`

const CONTRACT_INFORMATION_QUERY = gql`
query ContractInformationQuery  {
  card(id: 20) {
    Card {
      ... on ComponentCardsContractInformation {
        card_name
        show_card
        contract_date
        warranty_date
        internationalization_json
      }
    }
  }
}
`

const CUSTOMER_ORDER_QUERY = gql`
query CustomerOrderQuery  {
  card(id: 33) {
    Card {
      ... on ComponentCardsCustomerOrder {
        card_name
        show_card
        order_code
        search_button
        date
        internationalization_json
      }
    }
  }
}
`

const FOOTER_NOTE_QUERY = gql`
query FooterNoteQuery  {
  card(id: 34) {
    Card {
      ... on ComponentCardsFooterNote{
        card_name
        text
        internationalization_json
      }
    }
  }
}
`

const INFORMATION_QUERY = gql`
query InformationQuery  {
  card(id: 9) {
    Card {
      ... on ComponentCardsInformation{
        card_name
        show_card
        internationalization_json
      }
    }
  }
}
`

const CUSTOMER_ORDER_PROCESS_QUERY = gql`
query CustomerOrderProcessQuery  {
  card(id: 18) {
    Card {
      ... on ComponentCardsProcessOrder{
        card_name
        delivery_time
        show_card
        internationalization_json        
      }
    }
  }
}
`

const ORDERS_QUERY = gql`
query OrdersQuery  {
  card(id: 16) {
    Card {
      ... on ComponentCardsOrder {
        card_name
        drawer_document
        icon
        internationalization_json
      }
    }
  }
}
`

const VIDEO_QUERY = gql`
query VideoQuery  {
  card(id: 35) {
    Card {
      ... on ComponentCardsVideo {
        card_name
        drawer_video
        drawer_content
        icon
        internationalization_json
      }
    }
  }
}
`

const PRICE_LIST_QUERY = gql`
query PriceListQuery  {
  card(id: 36) {
    Card {
      ... on ComponentCardsPriceList {
        card_name
        drawer_content
        icon
        internationalization_json
      }
    }
  }
}
`

const AGREED_PAYMENT_QUERY = gql`
query AgreedPaymentQuery  {
  card(id: 17) {
    Card {
      ... on ComponentCardsPayment {
        card_name
        show_card
        of_label
        paid_status
        unpaid_status
        due_status
        currency
        internationalization_json
      }
    }
  }
}
`

const PRODUCT_INFORMATION_QUERY = gql`
query ProductInformationQuery  {
  card(id: 19) {
    Card {
      ... on ComponentCardsProductInformation {
        card_name
        show_card
        image
        delivery_date
        warranty_date
        internationalization_json
      }
    }
  }
}
`

const QUOTE_FINAL_RESULTS_QUERY = gql`
query QuoteFinalResultsQuery  {
  card(id: 37) {
    Card {
      ... on ComponentCardsQuoteFinalResults {
        card_name
        icon
        internationalization_json
      }
    }
  }
}
`

const QUOTE_STEPS_QUERY = gql`
query QuoteStepsQuery  {
  card(id: 38) {
    Card {
      ... on ComponentCardsQuote{
        card_name
        icon
        internationalization_json
      }
    }
  }
}
`

const SPECIAL_PROMOTIONS_QUERY = gql`
query SpecialPromotionsQuery  {
  card(id: 39) {
    Card {
      ... on ComponentCardsSpecialPromotions{
        card_name
        show_card
        title
        text
        image
        button
        drawer_content
        internationalization_json
      }
    }
  }
}
`

const SUBVENTION_QUERY = gql`
query SubventionQuery {
  card(id: 10) {
    Card {
      ... on ComponentCardsSubvention {
        card_name
        show_card
				search_button
        upload_button
        upload_text
        upload_information 
        upload_success_message
        upload_failed_message
        internationalization_json
      }
    }
  }
}
`

const WHAT_EXPECT_QUERY = gql`
query WhatExpectnQuery {
  card(id: 12) {
    Card {
      ... on ComponentCardsWhatExpect {
        card_name
        show_card
        experience_video_title
        experience_video_text
        experience_video
        visualization_video_title
        visualization_video_text
        visualization_video
        quotation_video_title
        quotation_video_text
        quotation_video
        play_button
        internationalization_json
      }
    }
  }
}
`

const WORKFLOW_QUERY = gql`
query WorkflowQuery {
  card(id: 8) {
    Card {
      ... on ComponentCardsWorkflow {
        card_name
        show_card
        interaction_title
        last_interaction_title
        document_title
        last_document_title
        home_visit_title
        search_button
        icon_activity_type_appointment
        icon_activity_type_call
        icon_activity_type_email
        internationalization_json
      }
    }
  }
}
`

const NEW_USER_QUERY = gql`
query NewUserQuery {
  card(id: 40) {
    Card {
      ... on ComponentCardsNewUser {
        card_name
        first_name
        last_name
        email
        password
        confirm_password
        agreement
        agreement_button
        agreement_popup_title
        aggrement_popup_content
        aggreement_popup_button
        register_button
        success_message_title
        success_message_ok_button
        error_message_title 
        error_message_content 
        error_message_ok_button 
        rule_passwords_consistency 
        rule_agreement
        rule_tooltip_user_name 
        rule_first_name 
        rule_last_name 
        rule_insert_email  
        rule_invalid_email 
        rule_password 
        rule_password_confirmation 
        rule_read_agreement 
        add_customer_button
        search_customer_button
        internationalization_json
      }
    }
  }
}
`

const LOGIN_QUERY = gql`
query LoginQuery {
  card(id: 41) {
    Card {
      ... on ComponentCardsLogin {
        card_name   
        new_account   
        username
        username_error_message
        password
        password_error_message
        username_or_password_error_message
        inactive_account_error_message
        remember_me
        forgot_password
        login_button
        option
        country_error_message
        country_value_flanders
        country_value_wallonia_brussels
        country_value_france
        country_value_germany
        country_value_the_netherlands
        country_value_italy
        preferred_language_value_flanders
        preferred_language_value_wallonia_brussels
        preferred_language_value_france
        preferred_language_value_germany
        preferred_language_value_the_netherlands
        preferred_language_value_italy
        internationalization_json
      }
    }
  }
}
`

const INVOICE_QUERY = gql`
query InvoiceQuery  {
  card(id: 43) {
    Card {
      ... on ComponentCardsInvoice {
        card_name
        drawer_document
        icon
        internationalization_json
      }
    }
  }
}
`

const LOGOUT_QUERY = gql`
query InvoiceQuery  {
  card(id: 44) {
    Card {
      ... on ComponentCardsLogouts {
        card_name
        logout
        logout_impersonation
        internationalization_json
      }
    }
  }
}
`

const PROMOTE_HOME_VISIT_QUERY = gql`
query PromoteHomeVisitQuery  {
  card(id: 45) {
    Card {
      ... on ComponentCardsPromoteHomeVisit{
        card_name
        show_card
        title
        content
        image
        button
        modal_content
        category_value_walk_in_shower
        category_value_walk_in_bath
        category_value_relax_recliner_custom_order
        category_value_homelift
        category_value_mobility_scooter_delux	
        category_value_platform_hublift
        category_value_stairlift_curved
        category_value_stairlift_straight
        internationalization_json
      }
    }
  }
}
`

const NOT_FOUND_QUERY = gql`
query NotFoundQuery  {
  card(id: 46) {
    Card {
      ... on ComponentCardsNotFound{
        sub_title
        back_home_button
        internationalization_json
      }
    }
  }
}
`

const SALESMAN_STATISTICS_QUERY = gql`
query SalesmanStatisticsQuery {
  card(id: 47) {
    Card {
      ... on ComponentCardsSalesmanStatistics {
        card_name_year
        card_name_month
        appointments
        sales
        conversion
        sales_bruto
        margin
        provision
        internationalization_json
      }
    }
  }
}
`

const RESET_PASSWORD_QUERY = gql`
query ResetPasswordQuery {
  card(id: 48) {
    Card {
      ... on ComponentCardsResetPassword {
        card_name
        title
        password
        confirm_password
        request_button
        rule_passwords_consistency
        rule_password
        rule_password_confirmation
        popup_error_message
        popup_success_message
        internationalization_json
      }
    }
  }
}
`

const SEARCH_USER_QUERY = gql`
query SearchUserQuery {
  card(id: 49) {
    Card {
      ... on ComponentCardsSearchUser {
        card_name
        impersonate_card
        user_id
        impersonate_tooltip
        search_button
        success_message
        error_message
        internationalization_json
      }
    }
  }
}
`

const SIGNATURE_QUERY = gql`
query SignatureQuery {
  card(id: 50) {
    Card {
      ... on ComponentCardsSignature {
        internationalization_json
      }
    }
  }
}
`

const HOUSE_YEAR_QUERY = gql`
query HouseYearQuery {
  card(id: 51) {
    Card {
      ... on ComponentCardsHouseYear {
        internationalization_json
      }
    }
  }
}
`

const QUTATION_PDF_QUERY = gql`
query QuotationPdfQuery {
  card(id: 52) {
    Card {
      ... on ComponentCardsQuotationPdf {
        internationalization_json
      }
    }
  }
}
`

const ALERT_BANNER_QUERY = gql`
query AlertBannerQuery {
  card(id: 53) {
    Card {
      ... on ComponentCardsAlertBanner {
        internationalization_json
      }
    }
  }
}
`

const QUOTE_THROUGH_FILE_QUERY = gql`
query QuoteThroughFileQuery {
  card(id: 54) {
    Card {
      ... on ComponentCardsQuoteThroughFile {
        internationalization_json
      }
    }
  }
}
`

export {
  SCREENS_QUERY,
  PERSONAL_INFORMATION_QUERY,
  BROCHURE_QUERY,
  DOCUMENTS_QUERY,
  LOCAL_ADVISOR_QUERY,
  QUOTATION_STEPS_QUERY,
  PRODUCTS_QUERY,
  VISIT_QUERY,
  COOKIE_CONSENT_QUERY,
  APPOINTMENTS_QUERY,
  ASSISTANCE_QUERY,
  CLIENT_QUOTATION_QUERY,
  COMMERCIAL_APPOINTMENTS_QUERY,
  COMMERCIAL_PERSONAL_INFORMATION_QUERY,
  CONFIRMATION_QUERY,
  CONTRACT_INFORMATION_QUERY,
  CUSTOMER_ORDER_QUERY,
  FOOTER_NOTE_QUERY,
  INFORMATION_QUERY,
  CUSTOMER_ORDER_PROCESS_QUERY,
  ORDERS_QUERY,
  VIDEO_QUERY,
  PRICE_LIST_QUERY,
  AGREED_PAYMENT_QUERY,
  PRODUCT_INFORMATION_QUERY,
  QUOTE_FINAL_RESULTS_QUERY,
  QUOTE_STEPS_QUERY,
  SPECIAL_PROMOTIONS_QUERY,
  SUBVENTION_QUERY,
  WHAT_EXPECT_QUERY,
  WORKFLOW_QUERY,
  NEW_USER_QUERY,
  LOGIN_QUERY,
  INVOICE_QUERY,
  LOGOUT_QUERY,
  PROMOTE_HOME_VISIT_QUERY,
  NOT_FOUND_QUERY,
  SALESMAN_STATISTICS_QUERY,
  RESET_PASSWORD_QUERY,
  SEARCH_USER_QUERY,
  SIGNATURE_QUERY,
  HOUSE_YEAR_QUERY,
  QUTATION_PDF_QUERY,
  ALERT_BANNER_QUERY,
  QUOTE_THROUGH_FILE_QUERY
}
