import * as actionTypes from './actionType';

export const setImpersonification = (boolean) => {
  return {
    type: actionTypes.IS_IMPERSONATE,
    isImpersonate: boolean
  }
};

export const setImpersonateParameter = (parameter) => {
  return {
    type: actionTypes.SET_IMPERSONATE_PARAMETER,
    impersonateParameter: parameter
  }
};

export const setImpersonateUsername = (Username) => {
  return {
    type: actionTypes.SET_IMPERSONATE_USERNAME,
    impersonateUsername: Username
  }
};