import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { SIGNATURE_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useSignatureQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(SIGNATURE_QUERY, {client: cmsClient});

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    cardName: i18next.t(`card_name.${languages.language}`),
    title: i18next.t(`title.${languages.language}`),
    undoButton: i18next.t(`undo_button.${languages.language}`),
    clearButton: i18next.t(`clear_button.${languages.language}`),
    saveButton: i18next.t(`save_button.${languages.language}`),
    popupSuccessMessage: i18next.t(`popup_success_message.${languages.language}`)
  }

  return {
    signatureLabels: lables,
    loading,
    error
  }
}

export default useSignatureQuery
