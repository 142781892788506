import React, { useState, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCardStyle from '../../hooks/useCardStyle'
import useOrdersQuery from '../../hooks/backend/useOrdersQuery'
import * as customerProductsAction from '../../redux/actions/customerProductsAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import withHandleQuery from '../../hoc/withHandleQuery'

const ProductInformation = React.lazy(() => import('../../components/cards/ProductInformation'));
const Assistance = React.lazy(() => import('../../components/cards/Assistance'));
const ContractInformation = React.lazy(() => import('../../components/cards/ContractInformation'));
const MaintenanceVisit = React.lazy(() => import('../../components/cards/MaintenanceVisit'));
const Documents = React.lazy(() => import('../../components/cards/Documents'));
const Workflow = React.lazy(() => import('../../components/cards/Workflow'));
const Info = React.lazy(() => import('../../components/cards/Info'));
const Video = React.lazy(() => import('../../components/cards/Video'));
const Appointments = React.lazy(() => import('../../components/cards/Appointments'));

const MyService = (props) => {

  const {
    dataHoc,
  } = props

  const [showProductInformation, setShowProductInformation] = useState('')
  const [showContractInformation, setShowContractInformation] = useState('')
  const [showMaintenanceVisit, setShowMaintenanceVisit] = useState('')
  const [showWorkflowt, setShowWorkflow] = useState('')
  const [showAssistance, setShowAssistance] = useState('')
  const [showInfo, setShowInfo] = useState('')
  const [showDocuments, setShowDocuments] = useState('')

  const [columnOne, setColumnOne] = useState('')
  const [columnTwo, setColumnTwo] = useState('')
  const [columnThree, setColumnThree] = useState('')
  const [columnFour, setColumnFour] = useState()

  const [customerLastDocument, setCustomerLastDocument] = useState({ date: new Date('Mon Jan 01 1900 00:00:00 GMT+0100 (Central European Standard Time)'), fileName: '', documentType: '' })

  useEffect(() => {
    setColumnOne(showProductInformation || showContractInformation ? 'card border-0' : '')
    setColumnTwo(showMaintenanceVisit ? 'card border-0' : '')
    setColumnThree(showWorkflowt ? 'card border-0' : '')
    setColumnFour(showAssistance || showInfo || showDocuments ? 'card border-0' : '')

  }, [showProductInformation, showContractInformation, showMaintenanceVisit, showWorkflowt, showAssistance, showInfo, showDocuments, setColumnOne, setColumnTwo, setColumnThree, setColumnFour])

  const logins = useSelector(state => state.logins)
  const customerProducts = useSelector(state => state.customerProducts)
  const dispatch = useDispatch()
  const setCustomerProducts = (products) => dispatch(customerProductsAction.setCustomerProducts(products));
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  const { cardStyle } = useCardStyle()

  const ordersQueryData = dataHoc[0] !== undefined && dataHoc[0].ordersQueryData

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // to do - should we need this condition? Test...
    if (ordersQueryData /*&& Object.keys(customerProducts).length === 0*/) {
      const products = ordersQueryData.orders.map((product) => {
        return product
      })
      setCustomerProducts(products)
    }
  }, [ordersQueryData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-service">
        <div className={columnOne} style={cardStyle}>
          {
            Object.keys(customerProducts).length > 0 && customerProducts.Products.length > 0 &&
            <React.Fragment>
              <ProductInformation setShowProductInformation={setShowProductInformation} ordersQueryData={ordersQueryData} />
              <ContractInformation setShowContractInformation={setShowContractInformation} />
            </React.Fragment>
          }
        </div>

        <div className={columnTwo} style={cardStyle}>
          <MaintenanceVisit setShowMaintenanceVisit={setShowMaintenanceVisit} />
        </div>

        <div className={columnThree} style={cardStyle}>
          <Workflow setShowWorkflow={setShowWorkflow} customerLastDocument={customerLastDocument} />
        </div>

        <div className={columnFour} style={cardStyle}>
          <Assistance planVisit={false} setShowAssistance={setShowAssistance} />
          <Info components={[<Appointments />, <Video />]} setShowInfo={setShowInfo} />
          <Documents setShowDocuments={setShowDocuments} setCustomerLastDocument={setCustomerLastDocument} />
        </div>
      </div>
    </Suspense>
  )
}

export default withHandleQuery([useOrdersQuery])(React.memo(MyService))
