import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import * as loginAction from '../redux/actions/loginAction'
import * as languageAction from '../redux/actions/languageAction'
import * as provisionAction from '../redux/actions/provisionAction'
import useResetPasswordCmsQuery from '../hooks/cms/useResetPasswordCmsQuery'
import withHandleQuery from '../hoc/withHandleQuery'
import useResetPasswordMutation from '../hooks/backend/useResetPasswordMutation'
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import { CheckOutlined } from '@ant-design/icons'

const { Meta } = Card;

const ResetPassword = (props) => {

  const {
    dataHoc,
    loginScreenUrl
  } = props

  const logins = useSelector(state => state.logins)

  let history = useHistory();

  const [urlTokenValue, setUrlTokenValue] = useState()
  const [confirmDirty, setConfirmDirty] = useState(false)

  // http://dev.my.mobilae.com:9801/reset-password?token=6327f100d46f30d9ec755fab8ef412e72&lang=en&locale=nl_NL
  const dispatch = useDispatch()
  const setAuthentication = (value) => dispatch(loginAction.setAuthentication(value))
  const setLanguage = (lang, locale) => dispatch(languageAction.setLanguage(lang, locale))
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  const urlTokenParameter = 'token'
  const urlLanguageParameter = 'lang'
  const urlLocaleParameter = 'locale'

  useEffect(() => {
    setAuthentication(false)

    const urlLanguage = window.location.search.match(new RegExp(`(?:[?&]${urlLanguageParameter}=)([^&]+)`))?.[1]
    const urlLocale = window.location.search.match(new RegExp(`(?:[?&]${urlLocaleParameter}=)([^&]+)`))?.[1]

    setUrlTokenValue(window.location.search.match(new RegExp(`(?:[?&]${urlTokenParameter}=)([^&]+)`))?.[1])

    if (urlLanguage !== null && urlLanguage !== undefined && urlLocale !== null && urlLocale !== undefined) {
      setLanguage(urlLanguage.toLowerCase(), urlLocale)
    }

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const resetPasswordLabels = dataHoc[0] !== undefined && dataHoc[0].resetPasswordLabels
  const resetPasswordMutation = dataHoc[1] !== undefined && dataHoc[1].resetPasswordMutation

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value)

  };

  const compareToFirstPassword = async (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      throw new Error(resetPasswordLabels.rulePasswordsConsistency)
    }
  }

  const validateToNextPassword = async (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true })
    }
  }

  const handleSubmit = async (values) => {
    resetPasswordMutation({
      variables:
      {
        password: values.password,
        token: urlTokenValue
      }
    }).then(() => {
      Modal.success({
        content: resetPasswordLabels.popupSuccessMessage,
        okText: <CheckOutlined className="btn d-flex" />,
        onOk: () => {
          history.push({ pathname: loginScreenUrl })
        }
      });
    }).catch(() => {
      Modal.error({
        content: resetPasswordLabels.popupErrorMessage,
        okText: <CheckOutlined className="btn d-flex" />,
        onOk: () => history.push({ pathname: loginScreenUrl })
      });
    })
  }

  const onFinishFailed = ({ errorFields, outOfDate, values }) => {
    if (errorFields.length === 0) {
      handleSubmit(values)
    }
  }

  const cardStyle = !logins.isMobile ? { background: 'transparent', width: '320px', marginLeft: 'auto', marginRight: 'auto' } : { background: 'transparent', width: '95%', marginLeft: 'auto', marginRight: 'auto' }

  const [form] = Form.useForm()

  return (
    <div className="card border-0" style={cardStyle}>
      <Card
        style={{ marginTop: 16 }}
        title={<b>{resetPasswordLabels.cardName}</b>}
        bordered={false}
      >
        <Meta
          title={<b>{resetPasswordLabels.title}</b>}
          description={
            <div>

              <Form
                form={form}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
              >

                <Form.Item
                  name="password"
                  label={resetPasswordLabels.password}
                  hasFeedback
                  rules={
                    [
                      {
                        required: true,
                        min: 8,
                        message: resetPasswordLabels.rulePassword,
                      },
                      {
                        validator: validateToNextPassword,
                      },
                    ]
                  }
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={resetPasswordLabels.confirmPassword}
                  hasFeedback
                  rules={
                    [
                      {
                        required: true,
                        min: 8,
                        message: resetPasswordLabels.rulePasswordConfirmation,
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                    ]
                  }
                >
                  <Input.Password onBlur={handleConfirmBlur} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {resetPasswordLabels.requestButton}
                  </Button>
                </Form.Item>
              </Form>

            </div>
          }
        />
      </Card>
    </div>
  )
}

export default withHandleQuery([useResetPasswordCmsQuery, useResetPasswordMutation])(ResetPassword)