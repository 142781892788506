
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { QUOTE_THROUGH_FILE_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useQuoteThroughFileQuery = () => {
  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(QUOTE_THROUGH_FILE_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    cardName: i18next.t(`card_name.${languages.language}`),
    filterSection: i18next.t(`filter_section.${languages.language}`),
    categorySection: i18next.t(`category_section.${languages.language}`),
    categoryOptionOne: i18next.t(`category_option_one.${languages.language}`),
    categoryOptionTwo: i18next.t(`category_option_two.${languages.language}`),
    categoryOptionThree: i18next.t(`category_option_three.${languages.language}`),
    categoryOptionFour: i18next.t(`category_option_four.${languages.language}`),
    csvFileSection: i18next.t(`csv_file_section.${languages.language}`),
    uploadCsvFileTitle: i18next.t(`upload_csv_file_title.${languages.language}`),
    uploadCsvFileDescription: i18next.t(`upload_csv_file_description.${languages.language}`),
    uploadCsvFileSuccessMessage: i18next.t(`upload_csv_file_success_message.${languages.language}`),
    uploadCsvFileErrorMessage: i18next.t(`upload_csv_file_error_message.${languages.language}`),
    imageSection: i18next.t(`image_section.${languages.language}`),
    uploadImageFileTitle: i18next.t(`upload_image_file_title.${languages.language}`),
    uploadImageFileDescription: i18next.t(`upload_image_file_description.${languages.language}`),
    uploadImageFileSuccessMessage: i18next.t(`upload_image_file_success_message.${languages.language}`),
    uploadImageFileErrorMessage: i18next.t(`upload_image_file_error_message.${languages.language}`)
  }

  return {
    quoteThroughFileQueryLabel: lables,
    loading,
    error
  }
}

export default useQuoteThroughFileQuery