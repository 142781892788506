// Quotation Answers
export const CREATE_NEW_ANSWER = 'CREATE_NEW_ANSWER'
export const DELETE_ANSWER = 'DELETE_ANSWER'
export const DELETE_ALL_ANSWERS = 'DELETE_ALL_ANSWERS'

// Quotation Results
export const SET_QUOTATION_RESULTS = 'SET_QUOTATION_RESULTS'

// Quotation Filter
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const SET_RANK_BACKEND_VALUE = 'SET_RANK_BACKEND_VALUE'
export const SET_RANK_CMS_VALUE = 'SET_RANK_CMS_VALUE'
export const IS_FILTER_RESULTS_ON = 'IS_FILTER_RESULTS_ON'
export const SET_QUOTATION_PERCENTAGE = 'SET_QUOTATION_PERCENTAGE'

// Products
export const SET_PRODUCT = 'SET_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'

// Login
export const SET_USERNAME = 'SET_USERNAME'
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const SET_ACCESS_TYPE = 'SET_ACCESS_TYPE'
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const IS_MOBILE = 'IS_MOBILE';

// Alert Banner
export const SET_ALERT_BANNER_VISIBILITY = 'SET_ALERT_BANNER_VISIBILITY'
export const SET_ALERT_BANNER_MESSAGE = 'SET_ALERT_BANNER_MESSAGE'
export const SET_ALERT_BANNER_TYPE = 'SET_ALERT_BANNER_TYPE'

// Impersonate
export const IS_IMPERSONATE = 'IS_IMPERSONATE'
export const SET_IMPERSONATE_PARAMETER = 'SET_IMPERSONATE_PARAMETER'
export const SET_IMPERSONATE_USERNAME = 'SET_IMPERSONATE_USERNAME'

// Logout
export const USER_LOGOUT = 'USER_LOGOUT'

// Language
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Customer Orders
export const SET_CUSTOMER_ORDERS = 'SET_CUSTOMER_ORDERS'
export const DELETE_CUSTOMER_ORDERS = 'DELETE_CUSTOMER_ORDERS'

// Customer Products
export const SET_CUSTOMER_PRODUCTS = 'SET_CUSTOMER_PRODUCTS'
export const DELETE_CUSTOMER_PRODUCTS = 'DELETE_CUSTOMER_PRODUCTS'

// Provision Code
export const SET_PROVISION_CODE = 'SET_PROVISION_CODE'
export const SET_PROVISION_VISIBILITY = 'SET_PROVISION_VISIBILITY'
export const DELETE_PROVISION_CODE = 'DELETE_PROVISION_CODE'

// Notification
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

// Quotation
export const SET_QUOTATION = 'SET_QUOTATION'
export const DELETE_QUOTATION = 'DELETE_QUOTATION'