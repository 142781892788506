import React from 'react'
import { Button } from 'antd';
import './BackAndForwardButton.scss'

const BackAndForwardButton = (props) => {

  const {
    goBack,
    goForward
  } = props

  const handleGoBack = () => {
    goBack()
  }

  const handleGoForward = () => {
    goForward()
  }

  return (
    <React.Fragment>
      <Button.Group
        className="d-flex justify-content-center"
        size="default"
      >
        <div
          className="btn btn-orange"
          onClick={handleGoBack}
        >
          <b>{'<'}</b>
        </div>
        <div
          className="btn btn-orange"
          onClick={handleGoForward}
        >
          <b>{'>'}</b>
        </div>
      </Button.Group>
    </React.Fragment>
  )
}

export default React.memo(BackAndForwardButton)