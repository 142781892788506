import React, { useState, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as productAction from '../../redux/actions/productAction'
import * as answerAction from '../../redux/actions/quotation/answerAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import useCardStyle from '../../hooks/useCardStyle'
import useQuotationQuery from '../../hooks/backend/useQuotationQuery'
import withHandleQuery from '../../hoc/withHandleQuery'

const PersonalInterest = React.lazy(() => import('../../components/cards/Brochure'))
const Documents = React.lazy(() => import('../../components/cards/Documents'))
const Subvention = React.lazy(() => import('../../components/cards/Subvention'))
const LocalAdvisor = React.lazy(() => import('../../components/cards/LocalAdvisor'))
const Assistance = React.lazy(() => import('../../components/cards/Assistance'))
const Info = React.lazy(() => import('../../components/cards/Info'));
const ClientQuotation = React.lazy(() => import('../../components/cards/ClientQuotation'))
const PriceList = React.lazy(() => import('../../components/cards/PriceList'))
const Video = React.lazy(() => import('../../components/cards/Video'));

const MyQuotation = (props) => {

  const {
    dataHoc,
    orderScreenUrl,
    quotationResultsScreenUrl,
  } = props

  const [showBrochure, setShowBrochure] = useState('')
  const [showSubvention, setShowSubvention] = useState('')
  const [showLocalAdvisor, setShowLocalAdvisor] = useState('')
  const [showClientQuotation, setShowClientQuotation] = useState('')
  const [showAssistance, setShowAssistance] = useState('')
  const [showInfo, setShowInfo] = useState('')
  const [showDocuments, setShowDocuments] = useState('')

  const [columnOne, setColumnOne] = useState('')
  const [columnTwo, setColumnTwo] = useState('')
  const [columnThree, setColumnThree] = useState('')
  const [columnFour, setColumnFour] = useState('')

  useEffect(() => {
    setColumnOne(showBrochure || showSubvention ? 'card border-0' : '')
    setColumnTwo(showLocalAdvisor ? 'card border-0' : '')
    setColumnThree(showClientQuotation ? 'card border-0' : '')
    setColumnFour(showAssistance || showInfo || showDocuments ? 'card border-0' : '')

  }, [showBrochure, showSubvention, showLocalAdvisor, showClientQuotation, showAssistance, showInfo, showDocuments, setColumnOne, setColumnTwo, setColumnThree, setColumnFour])

  const logins = useSelector(state => state.logins)
  const dispatch = useDispatch()
  const deleteProduct = () => dispatch(productAction.deleteProduct())
  const deleteAllAnswers = () => dispatch(answerAction.deleteAllAnswers())
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  useEffect(() => {
    deleteProduct()
    deleteAllAnswers()
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { cardStyle } = useCardStyle()

  const quotationQueryData = dataHoc[0] !== undefined && dataHoc[0].quotationQueryData

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-quotation">
        <div className={columnOne} style={cardStyle}>
          <PersonalInterest setShowBrochure={setShowBrochure} />
          <Subvention setShowSubvention={setShowSubvention} />
        </div>

        <div className={columnTwo} style={cardStyle}>
          <LocalAdvisor setShowLocalAdvisor={setShowLocalAdvisor} />
        </div>

        {(quotationQueryData.data?.quotations?.length > 0) &&
          <div className={columnThree} style={cardStyle}>
            <ClientQuotation
              quotationResultsScreenUrl={quotationResultsScreenUrl}
              orderScreenUrl={orderScreenUrl}
              setShowClientQuotation={setShowClientQuotation}
              quotationQueryData={quotationQueryData}
            />
          </div>
        }

        <div className={columnFour} style={cardStyle}>
          <Assistance planVisit={true} setShowAssistance={setShowAssistance} />
          <Info components={[<PriceList />, <Video />]} setShowInfo={setShowInfo} />
          <Documents setShowDocuments={setShowDocuments} />
        </div>
      </div>
    </Suspense>
  )
}

export default withHandleQuery([useQuotationQuery])(React.memo(MyQuotation))
