import { UPDATE_QUOTATION_ISREADY } from '../../queries/back-end/queries'
import { useMutation } from 'react-apollo'

const useUpdateQuotationIsReadyMutation = () => {

  const [updateQuotationIsReadyMutation, { loading, error }] = useMutation(UPDATE_QUOTATION_ISREADY);

  if (loading) return { loading }
  if (error) return { error }

  return {
    updateQuotationIsReadyMutation,
    loading,
    error
  }

}

export default useUpdateQuotationIsReadyMutation