
// import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { PRODUCTS_QUERY } from '../../queries/cms/queries'
// import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useProductsQuery = () => {

  //const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(PRODUCTS_QUERY, {client: cmsClient});

  if (loading) return { loading }
  if (error) return { error }

  // const internationalizationJson = data.card.Card[0].internationalization_json
  // const I18next = () => useI18next(internationalizationJson)
  // const { i18next } = I18next()

  // const lables = {
  //   cardTitle: i18next.t(`card_name.${languages.language}`),
  //   drawerContent: i18next.t(`drawer_content.${languages.language}`),
  //   icon: data.card.Card[0].icon
  // }

  return {
    productsQueryLabels: data,
    loading,
    error
  }
}

export default useProductsQuery
