import React from 'react'
import { Card } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import QuoteQuestionCardDrawer from '../../drawers/QuoteQuestionCardDrawer';
import useDrawer from '../../../hooks/useDrawer'
import useI18next from '../../../hooks/useI18next'
import { useSelector } from 'react-redux'

const { Meta } = Card;

const QuoteQuestionCard = (props) => {

  const {
    questions
  } = props

  const languages = useSelector(state => state.languages)

  const { showDrawer, isDrawerVisible, closeDrawer } = useDrawer()

  const internationalizationJson = questions?.internationalization_json_question
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const title = i18next.t(`title.${languages.language}`)
  const question = i18next.t(`question.${languages.language}`)
  const alert = i18next.t(`alert.${languages.language}`)

  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bordered={false}
        title={<b>{title} - {question}</b>}
      >
        <InfoCircleTwoTone className="mb-3" onClick={showDrawer} style={{ cursor: 'pointer' }} />
        <br></br>
        <Meta
          description={alert}
        />
      </Card>
      <QuoteQuestionCardDrawer isDrawerVisible={isDrawerVisible} closeDrawer={closeDrawer} title={<InfoCircleTwoTone className="mb-3" onClick={showDrawer} style={{ cursor: 'pointer' }} />} alert={alert} />
    </div>
  )
}

export default React.memo(QuoteQuestionCard)