import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { NEW_USER_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useNewUserQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(NEW_USER_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    cardTitle: i18next.t(`card_name.${languages.language}`),
    firstName: i18next.t(`first_name.${languages.language}`),
    lastName: i18next.t(`last_name.${languages.language}`),
    email: i18next.t(`email.${languages.language}`),
    password: i18next.t(`password.${languages.language}`),
    confirmPassword: i18next.t(`confirm_password.${languages.language}`),
    agreement: i18next.t(`agreement.${languages.language}`),
    agreementButton: i18next.t(`agreement_button.${languages.language}`),
    agreementPopupTitle: i18next.t(`agreement_popup_title.${languages.language}`),
    aggrementPopupContent: i18next.t(`aggrement_popup_content.${languages.language}`),
    aggreementPopupButton: i18next.t(`aggreement_popup_button.${languages.language}`),
    registerButton: i18next.t(`register_button.${languages.language}`),
    successMessageTitle: i18next.t(`success_message_title.${languages.language}`),
    successMessageContent: i18next.t(`success_message_content.${languages.language}`),
    successMessageOkButton: i18next.t(`success_message_ok_button.${languages.language}`),
    errorMessageTitle: i18next.t(`error_message_title.${languages.language}`),
    errorMessageContent: i18next.t(`error_message_content.${languages.language}`),
    errorMessageSameEmailContent: i18next.t(`error_message_same_email_content.${languages.language}`),
    errorMessageOkButton: i18next.t(`error_message_ok_button.${languages.language}`),
    rulePasswordsConsistency: i18next.t(`rule_passwords_consistency.${languages.language}`),
    ruleAgreement: i18next.t(`rule_agreement.${languages.language}`),
    ruleTooltipUserName: i18next.t(`rule_tooltip_user_name.${languages.language}`),
    ruleFirstName: i18next.t(`rule_first_name.${languages.language}`),
    ruleLastName: i18next.t(`rule_last_name.${languages.language}`),
    ruleInsertEmail: i18next.t(`rule_insert_email.${languages.language}`),
    ruleInvalidEmail: i18next.t(`rule_invalid_email.${languages.language}`),
    rulePassword: i18next.t(`rule_password.${languages.language}`),
    rulePasswordConfirmation: i18next.t(`rule_password_confirmation.${languages.language}`),
    ruleReadAgreement: i18next.t(`rule_read_agreement.${languages.language}`),
    addCustomerButton: data.card.Card[0].add_customer_button,
    searchCustomerButton: data.card.Card[0].search_customer_button
  }

  return {
    newUserLabels: lables,
    loading,
    error
  }
}

export default useNewUserQuery