import { useState } from 'react'

const useDrawer = () => {

  const [isDrawerVisible, setDrawerVisible] = useState(false)

  const showDrawer = () => {
    setDrawerVisible(true)
  };

  const closeDrawer = () => {
    setDrawerVisible(false)
  };

  return {
    showDrawer: showDrawer,
    isDrawerVisible: isDrawerVisible,
    closeDrawer: closeDrawer
  }
}

export default useDrawer;