import React, { useEffect } from 'react'
import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom'
import useNotFoundQuery from '../hooks/cms/useNotFoundQuery'
import withHandleQuery from '../hoc/withHandleQuery'
import * as provisionAction from '../redux/actions/provisionAction'
import { useDispatch } from 'react-redux'

const NotFound = (props) => {

  const {
    dataHoc
  } = props

  const dispatch = useDispatch()
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const notFoundLabels = dataHoc[0] !== undefined && dataHoc[0].notFoundLabels

  return (
    <Result
      status="404"
      title="404"
      subTitle={notFoundLabels.subTitle}
      extra={<NavLink exact to="/"><Button type="primary">{notFoundLabels.backHomeButton}</Button></NavLink>}
    />
  )
}

export default withHandleQuery([useNotFoundQuery])(React.memo(NotFound))