import React from 'react'
import { Drawer } from 'antd';
import { useSelector } from 'react-redux'

const QuoteQuestionCardDrawer = (props) => {

  const {
    closeDrawer,
    isDrawerVisible
  } = props

  const logins = useSelector(state => state.logins)

  return (
    <Drawer
      destroyOnClose={true}
      title={props.title}
      height="auto"
      width={!logins.isMobile ? "50%" : "100%"}
      placement='right'
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerVisible}

    >
      <div className="container">

        <div>
          {props.alert}
        </div>

      </div>
    </Drawer>
  )
}

export default QuoteQuestionCardDrawer
