import React from 'react'
import { Modal } from 'antd';
import error_handler_backgound_image from '../../error_handler_backgound_image.png'
import useLogout from '../../hooks/useLogout'
import { CheckOutlined } from '@ant-design/icons'

const ErrorHandler = (props) => {

  const {
    content,
    logMeOut = true
  } = this.props

  const { logout } = useLogout()
  const redirect = logMeOut ? () => logout() : () => null

  return (
    <div>
      {
        Modal.error({
          style: { marginTop: '300px' },
          maskStyle: { backgroundImage: `url(${error_handler_backgound_image}`, backgroundSize: 'cover' },
          content: content !== undefined ? content : 'Something went wrong, please try again',
          okText: <CheckOutlined className="btn d-flex" />,
          onOk: () => { window.location.reload(); return redirect }
        })
      }
    </div>
  )
}

export default React.memo(ErrorHandler)
