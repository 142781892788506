import * as actionTypes from '../actions/actionType';

export default (state = { isAlertBannerVisible: false, alertBannerMessage: '', alertBannerType: '' }, action) => {
  switch (action.type) {
    case actionTypes.SET_ALERT_BANNER_VISIBILITY:
      return { ...state, isAlertBannerVisible: action.isAlertBannerVisible }
    case actionTypes.SET_ALERT_BANNER_MESSAGE:
      return { ...state, alertBannerMessage: action.alertBannerMessage }
    case actionTypes.SET_ALERT_BANNER_TYPE:
      return { ...state, alertBannerType: action.alertBannerType }
    default:
      return state;
  }
};