import React from 'react'
import { Drawer } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

/**
 * Drawer responsable to present the Cookie Statement
 * @param {boolean} isDrawerVisible boolean that determine if the drawer is vicible or not
 * @param {function} closeDrawer function responsable for closing and open the drawer
 * @param {string} drawerContent string that contains the drawer body content. This string is HTML code
 * @param {string} cardTitle string that contains the drawer title
 *
 * @return React element
 */
const CookieConsentDrawer = (props) => {

  const {
    closeDrawer,
    cardTitle,
    isDrawerVisible,
    drawerContent
  } = props

  const logins = useSelector(state => state.logins)

  let history = useHistory();

  const closeAction = () => {
    history.push(history.location.pathname);
    closeDrawer()
  }

  return (
    <Drawer
      destroyOnClose={true}
      title={cardTitle}
      height="auto"
      width={!logins.isMobile ? "50%" : "100%"}
      placement='right'
      closable={true}
      onClose={closeAction}
      visible={isDrawerVisible}
    >
      <div className="container">
        {ReactHtmlParser(drawerContent)}
      </div>
    </Drawer>
  )
}

export default CookieConsentDrawer