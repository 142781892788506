import * as actionTypes from '../actionType';

export const createAnswer = (answer, step) => {
    return {
      type: actionTypes.CREATE_NEW_ANSWER,
      answer: answer,
      step: step
    }
  };

  export const deleteAnswer = (step) => {
    return {
      type: actionTypes.DELETE_ANSWER,
      step: step
    }
  };

  export const deleteAllAnswers = () => {
    return {
      type: actionTypes.DELETE_ALL_ANSWERS,
    }
  };

