import { SEND_FORGOT_PASSWORD_EMAIL } from '../../queries/back-end/queries'
import { backendAuthclient } from '../../graphql-endpoints/graphqlEndpoints'
import { useMutation } from 'react-apollo'

const useSendForgotPasswordEmailMutation = () => {

  const [sendForgotPasswordEmailMutation, { loading, error }] = useMutation(SEND_FORGOT_PASSWORD_EMAIL, { client: backendAuthclient });

  if (loading) return { loading }
  if (error) return {  error }

  return {
    sendForgotPasswordEmailMutation,
    loading,
    error
  }
}

export default useSendForgotPasswordEmailMutation
