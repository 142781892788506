import React, { useEffect, useState } from 'react'
import { Card, Table } from 'antd';
import { useSelector } from 'react-redux'
import useI18next from '../../../hooks/useI18next'
import { occasionCardQuery, buybackCardQuery } from '../../../lib/queries/cms/queries'
import sanityClient from '../../../lib/client.js'
import Spinner from '../../spinner/Spinner'

const { Meta } = Card;
const { Column } = Table;

const OrderProduct = (props) => {

  const {
    filters,
    productName,
    discount,
    totalPrice,
    modelPicture,
    cardProductSpecifications,
    cardProductColorOptionOne,
    cardProductColorOptionTwo,
    cardProductExtraServiceOptionOne,
    cardProductExtraServiceOptionTwo,
    cardProductAdditionalCodesTitle,
    cardProductAdditionalCodesTableDescription,
    cardProductAdditionalCodesTableQuantity,
    cardProductAdditionalCodesTablePrice,
    questions,
    bordered = false,
  } = props

  const [occasionCMSData, setOccasionCMSData] = useState(null)
  const [buybackCMSData, setBuybackCMSData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const quotations = useSelector(state => state.quotations)
  const answers = useSelector(state => state.answers)
  const languages = useSelector(state => state.languages)
  const products = useSelector(state => state.products)

  // fetch Ocasion Component from Sanity CMS
  // fetch Buyback Component from Sanity CMS
  useEffect(() => {
    const queryOccasion = occasionCardQuery
    const paramsOccasion = { language: languages.locale }

    sanityClient.fetch(queryOccasion, paramsOccasion)
      .then((data) => {
        setOccasionCMSData(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })

    const queryBuyback = buybackCardQuery
    const paramsBuyback = { language: languages.locale }

    sanityClient.fetch(queryBuyback, paramsBuyback)
      .then((data) => {
        setBuybackCMSData(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })

  }, [languages.locale])

  let actions = []
  if (products.productId !== 24) {
    answers.map((e, index) => {

      const internationalizationJson = questions[index]?.internationalization_json_answer
      const I18next = () => useI18next(internationalizationJson)
      const { i18next } = I18next()

      actions.push(
        <div key={index} className="m-auto">
          <div className='m-4'>
            <div className='mb-2 text-center' style={{ width: "80px" }}>
              {i18next.t(`step.${languages.language}`)}
            </div>
            <img height={80} width={80} src={e[0]?.option_url} alt={i18next.t(`step.${languages.language}`)} />
          </div>
        </div>
      )
      return null
    })
  }

  let walkInShowerProductDetails = []
  if (products.productId === 24 && quotations.quotation?.WizardCompositeKey?.WizardCompositeKeyArticleCodes?.length > 0) {
    quotations.quotation.WizardCompositeKey.WizardCompositeKeyArticleCodes.map((article) => {
      const articleDescription = article.ArticleCode.descriptions.filter((language) => language.language === languages.language.split('_')[0]).pop()?.value

      walkInShowerProductDetails.push(
        <li key={article.ArticleCode.code} >
          ({article.quantity}x) {article.ArticleCode.code}: <b>{articleDescription}</b>
        </li>
      )
      return null
    })
  }

  let additionalCodes = []

  if (quotations.quotation?.ExtraServiceResponse?.length > 0) {
    quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 3).map((service, index) => {

      const description = service.descriptions.find(e => e.language === languages.language)?.value
      const quantity = service.quantity
      // const price = service.price

      additionalCodes.push({
        key: index,
        description: description,
        quantity: quantity,
        // price: price,
      }
      )
      return null
    })
  }

  const quotationTotalPrice = () => {

    const buybackPrice = quotations.quotation.BuyBackModel ? (quotations.quotation.BuyBackModel?.amount * quotations.quotation.BuyBackModel?.quantity) : 0

    const occasionPrice = quotations.quotation.OccasionModel ? (quotations.quotation.OccasionModel?.amount * quotations.quotation.OccasionModel?.quantity) : 0

    return totalPrice - discount - buybackPrice - occasionPrice
  }

  return (
    <>
      {
        isLoading &&
        <Spinner />
      }
      <Card
        style={{ marginTop: 16 }}
        bordered={bordered}
        title={<b>{productName}</b>}
      >
        <Meta
          title={
            <div style={{ float: 'right' }}>
              <h4 style={{
                marginBottom: 0
              }}>
                <b>€{quotationTotalPrice()}</b>
              </h4>
              {
                quotations.quotation.discount > 0 &&
                <p style={{
                  color: '#FF5A00',
                }}>
                  -€{quotations.quotation.discount}
                </p>
              }
              {
                quotations.quotation.OccasionModel &&
                <p style={{
                  color: '#FF5A00',
                }}>
                  -€{Number(quotations.quotation.OccasionModel?.amount) * Number(quotations.quotation.OccasionModel?.quantity)}: {occasionCMSData?.cardName.toLowerCase()}
                </p>
              }
              {
                quotations.quotation.BuyBackModel &&
                <p style={{
                  color: '#FF5A00',
                }}>
                  -€{Number(quotations.quotation.BuyBackModel?.amount) * Number(quotations.quotation.BuyBackModel?.quantity)}: {buybackCMSData?.cardName.toLowerCase()}
                </p>
              }
            </div>
          }
          description={
            < div >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                <div>
                  <img alt={productName} height="250" src={modelPicture} />
                </div>

                <div className="mr-3 ml-3">

                  <div className="d-flex justify-content-center">
                    <b>{cardProductSpecifications}</b>
                  </div>

                  <div style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {/* ***Colors*** */}
                    {
                      products.productId !== 24 &&
                      <div className="d-flex">
                        {
                          quotations.quotation.ColorResponse.find(e => e.type === "Rail") !== undefined &&
                          <div className="m-3" >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                              <b>{cardProductColorOptionOne}</b>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                              <img
                                alt={cardProductColorOptionOne}
                                src={quotations.quotation.ColorResponse.find(e => e.type === "Rail").pictureUrl}
                                height="50"
                                width="50"
                                style={{ margin: '5px' }}
                              />
                            </div>
                          </div>
                        }

                        {
                          quotations.quotation.ColorResponse.find(e => e.type === "Chair") !== undefined &&
                          <div className="m-3">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                              <b>{cardProductColorOptionTwo}</b>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                              <img
                                alt={cardProductColorOptionTwo}
                                src={quotations.quotation.ColorResponse.find(e => e.type === "Chair").pictureUrl}
                                height="50"
                                width="50"
                                style={{ margin: '5px' }}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    }
                    {/* ***Fixation*** */}
                    {
                      quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
                        === 1).length > 0 &&
                      <div>
                        <div className="d-flex justify-content-center">
                          <b>{cardProductExtraServiceOptionOne}</b>
                        </div>

                        <div className="d-flex">
                          {
                            quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 1).map((fixation, index) => {
                              return (
                                <div key={index} className="m-3">
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    <div>
                                      <p
                                        className="mb-0"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}
                                      >
                                        {fixation.quantity}x
                                      </p>
                                      <img
                                        alt={cardProductExtraServiceOptionOne}
                                        src={fixation.picture}
                                        height="50"
                                        width="50"
                                        style={{ margin: '5px' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }

                    {/* ***Extra Services*** */}
                    {
                      quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
                        === 2).length > 0 &&
                      <div>
                        <div className="d-flex justify-content-center">
                          <b>{cardProductExtraServiceOptionTwo}</b>
                        </div>

                        <div className="d-flex">
                          {
                            <div className="m-3">
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                                <img
                                  alt={cardProductExtraServiceOptionTwo}
                                  src={quotations.quotation.ExtraServiceResponse.find(e => e.properties[0].property === 2).picture}
                                  height="50"
                                  width="50"
                                  style={{ margin: '5px' }}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </div>

                </div>
              </div>

              {
                additionalCodes.length > 0 && <div className="mt-2 mb-2">
                  <b>{cardProductAdditionalCodesTitle}</b>
                  <Table dataSource={additionalCodes} pagination={false}>
                    <Column title={cardProductAdditionalCodesTableDescription} dataIndex="description" key="description" />
                    <Column title={cardProductAdditionalCodesTableQuantity} dataIndex="quantity" key="quantity" />
                    {/* We remove the price from the extra services */}
                    {/* {
                      !filters?.filterOptions?.includes(6) &&
                      <Column title={cardProductAdditionalCodesTablePrice} dataIndex="price" key="price" />
                    } */}
                  </Table>
                </div>
              }
              {
                products.productId !== 24 &&
                <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
                  {actions}
                </div>
              }
              {
                products.productId === 24 &&
                <div className="pt-4" style={{ marginRight: '0px', marginLeft: '0px' }}>
                  <ul>
                    {walkInShowerProductDetails}
                  </ul>
                </div>
              }
            </div >
          }
        />
      </Card >
    </>
  )
}

export default React.memo(OrderProduct)
