import * as actionTypes from './actionType'

export const setUsername = (username) => {
  return {
    type: actionTypes.SET_USERNAME,
    username: username
  }
}

export const setAuthentication = (value) => {
  return {
    type: actionTypes.IS_AUTHENTICATED,
    isAuthenticated: value
  }
}

export const setAccessType = (type) => {
  return {
    type: actionTypes.SET_ACCESS_TYPE,
    accessType: type
  }
}

export const setEmployee = (name, role, rating) => {
  return {
    type: actionTypes.SET_EMPLOYEE,
    role: role,
    rating: rating,
    employeeName: name
  }
}

export const setMobileMode = (value) => {
  return {
    type: actionTypes.IS_MOBILE,
    isMobile: value
  }
}
