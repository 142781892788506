import * as actionTypes from './actionType';

export const setProvision = (provisionCode) => {
  return {
    type: actionTypes.SET_PROVISION_CODE,
    provisionCode: provisionCode
  }
};

export const setProvisionVisibility = (provisionVisibility) => {
  return {
    type: actionTypes.SET_PROVISION_VISIBILITY,
    provisionVisibility: provisionVisibility
  }
};

export const deleteProvision = () => {
  return {
    type: actionTypes.DELETE_PROVISION_CODE
  }
};
