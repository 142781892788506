import React from 'react'
import NotFound from '../screens/NotFound'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = function PrivateRoute(
  { comp: Component,
    access,
    logins,
    impersonates,
    quotationResultsScreenUrl,
    quotationStepsScreenUrl,
    myHomeVisitScreenUrl,
    loginScreenUrl,
    dashboardScreenUrl,
    myQuotationScreenUrl,
    myOrderScreenUrl,
    myServiceScreenUrl,
    orderScreenUrl,
    preOrderScreennUrl,
    dataJson,
    ctdCheckScreenUrl,
    ...rest
  }) {
  if (logins.isAuthenticated) {
    if (access.includes(logins.accessType)) {
      return <Route
        {...rest}
        render={props =>
          <Component
            {...props}
            quotationResultsScreenUrl={quotationResultsScreenUrl}
            quotationStepsScreenUrl={quotationStepsScreenUrl}
            myHomeVisitScreenUrl={myHomeVisitScreenUrl}
            loginScreenUrl={loginScreenUrl}
            dashboardScreenUrl={dashboardScreenUrl}
            myQuotationScreenUrl={myQuotationScreenUrl}
            myOrderScreenUrl={myOrderScreenUrl}
            myServiceScreenUrl={myServiceScreenUrl}
            orderScreenUrl={orderScreenUrl}
            preOrderScreennUrl={preOrderScreennUrl}
            dataJson={dataJson}
            ctdCheckScreenUrl={ctdCheckScreenUrl}
          />
        }
      />
    } else {
      return <Route render={() => <NotFound />} />
    }
  } else {
    return <Route
      {...rest}
      render={props =>
        <Redirect to={{ pathname: loginScreenUrl, state: { from: props.location } }} />
      }
    />
  }
}

export default React.memo(PrivateRoute)