import React, { useState, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as customerOrdersAction from '../../redux/actions/customerOrdersAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import useCardStyle from '../../hooks/useCardStyle'
import useOrdersQuery from '../../hooks/backend/useOrdersQuery'
import withHandleQuery from '../../hoc/withHandleQuery'

const Documents = React.lazy(() => import('../../components/cards/Documents'));
const Assistance = React.lazy(() => import('../../components/cards/Assistance'));
const CustomerOrder = React.lazy(() => import('../../components/cards/CustomerOrder'));
const Payment = React.lazy(() => import('../../components/cards/Payment'));
const OrderProcess = React.lazy(() => import('../../components/cards/OrderProcess'));
const Info = React.lazy(() => import('../../components/cards/Info'));
const DeliveryNote = React.lazy(() => import('../../components/cards/DeliveryNote'));
const Confirmation = React.lazy(() => import('../../components/cards/Confirmation'));
const Invoice = React.lazy(() => import('../../components/cards/Invoice'));

const MyOrder = (props) => {

  const {
    dataHoc,
  } = props

  const [showCustomerOrder, setShowCustomerOrder] = useState('')
  const [showPayment, setShowPayment] = useState('')
  const [showOrderProcess, setShowOrderProcess] = useState('')
  const [showAssistance, setShowAssistance] = useState('')
  const [showInfo, setShowInfo] = useState('')
  const [showDocuments, setShowDocuments] = useState('')
  const [columnOne, setColumnOne] = useState('')
  const [columnTwo, setColumnTwo] = useState('')
  const [columnThree, setColumnThree] = useState('')
  const [columnFour, setColumnFour] = useState('')

  useEffect(() => {
    setColumnOne(showCustomerOrder ? 'card border-0' : '')
    setColumnTwo(showPayment ? 'card border-0' : '')
    setColumnThree(showOrderProcess ? 'card border-0' : '')
    setColumnFour(showAssistance || showInfo || showDocuments ? 'card border-0' : '')

  }, [showCustomerOrder, showPayment, showOrderProcess, showAssistance, showInfo, showDocuments, setColumnOne, setColumnTwo, setColumnThree, setColumnFour])

  const logins = useSelector(state => state.logins)
  const customerOrders = useSelector(state => state.customerOrders)
  const dispatch = useDispatch()
  const setCustomerOrders = (orders) => dispatch(customerOrdersAction.setCustomerOrders(orders));
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  const { cardStyle } = useCardStyle()
  const ordersQueryData = dataHoc[0] !== undefined && dataHoc[0].ordersQueryData

  useEffect(() => {
    if (ordersQueryData && Object.keys(customerOrders).length === 0) {
      setCustomerOrders(ordersQueryData)
    }
  }, [ordersQueryData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={null}>
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-order">
        <div className={columnOne} style={cardStyle}>
          {
            Object.keys(customerOrders).length > 0 && customerOrders.Orders.orders.length > 0 &&
            <CustomerOrder setShowCustomerOrder={setShowCustomerOrder} ordersQueryData={ordersQueryData} />
          }
        </div>

        <div className={columnTwo} style={cardStyle}>
          {
            Object.keys(customerOrders).length > 0 && customerOrders.Orders.orders.length > 0 &&
            <Payment setShowPayment={setShowPayment} />
          }
        </div>

        <div className={columnThree} style={cardStyle}>
          {
            Object.keys(customerOrders).length > 0 && customerOrders.Orders.orders.length > 0 &&
            <OrderProcess setShowOrderProcess={setShowOrderProcess} />
          }
        </div>

        <div className={columnFour} style={cardStyle}>
          <Assistance planVisit={false} setShowAssistance={setShowAssistance} />
          {
            Object.keys(customerOrders).length > 0 && customerOrders.Orders.orders.length > 0 &&
            <Info components={[<Confirmation />, <DeliveryNote />, <Invoice />]} setShowInfo={setShowInfo} />
          }
          <Documents setShowDocuments={setShowDocuments} />
        </div>
      </div>
    </Suspense>
  )
}

export default withHandleQuery([useOrdersQuery])(React.memo(MyOrder))
