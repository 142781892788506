import * as actionTypes from './actionType';

export const setProduct = (productName, productId) => {
  return {
    type: actionTypes.SET_PRODUCT,
    productName: productName,
    productId: productId
  }
};

export const deleteProduct = () => {
  return {
    type: actionTypes.DELETE_PRODUCT,
  }
};
