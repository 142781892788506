import { useQuery } from '@apollo/react-hooks';
import { PAYMENT_SCHEME_QUERY } from '../../queries/back-end/queries'

const usePaymentSchemeQuery = () => {

  const { data, loading, error } = useQuery(PAYMENT_SCHEME_QUERY);

  if (loading) return { loading }
  if (error) return { error }

  return {
    paymentSchemeData: data,
    loading,
    error
  }
}

export default usePaymentSchemeQuery