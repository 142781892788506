import { SAVE_COLOR } from '../../queries/back-end/queries'
import { useMutation } from 'react-apollo'

const useSaveColorMutation = () => {

  const [saveColorMutation, { loading, error }] = useMutation(SAVE_COLOR);

  if (loading) return { loading }
  if (error) return { error }

  return {
    saveColorMutation,
    loading,
    error
  }
}

export default useSaveColorMutation