import React from 'react'
import { Alert, Dropdown, Menu } from 'antd'
import { DownCircleTwoTone } from '@ant-design/icons'
import { withRouter } from "react-router"
import * as answerAction from '../../redux/actions/quotation/answerAction'
import * as productAction from '../../redux/actions/productAction'
import * as filterAction from '../../redux/actions/quotation/filterAction'
import { useDispatch, useSelector } from 'react-redux'
import useI18next from '../../hooks/useI18next'
import useProductsQuery from '../../hooks/cms/useProductsQuery'
import withHandleQuery from '../../hoc/withHandleQuery'

const SelectProductBanner = (props) => {

  const {
    history,
    quotationStepsScreenUrl,
    dataHoc,
    productName
  } = props

  const languages = useSelector(state => state.languages)
  const dispatch = useDispatch()
  const deleteAllAnswers = () => dispatch(answerAction.deleteAllAnswers())
  const deleteProduct = () => dispatch(productAction.deleteProduct())
  const setFilterOptions = (option) => dispatch(filterAction.setFilterOptions(option))
  const setRankBackendValue = (value) => dispatch(filterAction.setRankBackendValue(value))
  const setRankCmsValue = (value) => dispatch(filterAction.setRankCmsValue(value))
  const setProduct = (name, id) => dispatch(productAction.setProduct(name, id))

  const onSelect = (productName, productId) => {
    deleteProduct()
    deleteAllAnswers()
    setProduct(productName, parseInt(productId))
  }

  const productDeletion = () => {
    history.push({
      pathname: quotationStepsScreenUrl,
      state: {
        stepNumber: 0
      }
    })
    deleteProduct()
    deleteAllAnswers()
    setFilterOptions([])
    setRankBackendValue('')
    setRankCmsValue('')
  }

  const productsQueryLabels = dataHoc[0] !== undefined && dataHoc[0].productsQueryLabels

  const menu = (
    <Menu>
      <Menu.Item key="" onClick={productDeletion}>
        -
      </Menu.Item>
      {
        productsQueryLabels.products.map((product) => {
          if (product.show_product) {
            const internationalizationJson = product.internationalization_json
            const I18next = () => useI18next(internationalizationJson)
            const { i18next } = I18next()

            return (
              <Menu.Item
                key={product.back_end_id}
                id={product.name}
                onClick={() => onSelect(product.name, product.back_end_id)}
              >
                {i18next.t(`name.${languages.language}`)}
              </Menu.Item>
            )
          } else {
            return null
          }
        })
      }
    </Menu>
  )

  const message = (
    <div>
      <span >
        <Dropdown
          overlay={menu}
          trigger={['click']}
        >
          <span
            className="ant-dropdown-link"
            style={{ cursor: 'pointer' }}
            onClick={e => e.preventDefault()}
          >
            {productName}: <DownCircleTwoTone />
          </span>
        </Dropdown>
      </span>
    </div>
  )

  return (
    <div>
      <Alert message={message} type="success" />
    </div>
  )
}

export default withHandleQuery([useProductsQuery])(withRouter(React.memo(SelectProductBanner)))
