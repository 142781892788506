import * as actionTypes from './actionType';

export const setCustomerProducts = (products) => {
  return {
    type: actionTypes.SET_CUSTOMER_PRODUCTS,
    customerProducts: products,
  }
}

export const deleteCustomerProducts = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_PRODUCTS,
  }
};