import React, { Component } from 'react'
import StatusBar from '../../components/bars/quote-status-bar/QuoteStatusBar'
import QuestionCard from '../../components/cards/quote/QuoteQuestionCard'
import QuoteAnswerCard from '../../components/cards/quote/QuoteAnswerCard'
import ProductSelection from '../../components/cards/ProductSelection'
import BackAndForwardButton from '../../components/buttons/BackAndForwardButton'
import ResultCard from '../../components/cards/quote/QuoteResultCard'
import { connect } from 'react-redux';
import * as answerAction from '../../redux/actions/quotation/answerAction'
import * as resultsAction from '../../redux/actions/quotation/resultsAction'
import * as filterAction from '../../redux/actions/quotation/filterAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import { QUOTATION_STEPS_QUERY } from '../../queries/cms/queries'
import { Query } from 'react-apollo';
import { store } from '../../index'
import { Button } from 'antd';
import { isBrowser } from "react-device-detect"
import { CREATE_QUOTATION } from '../../queries/back-end/queries'
import { backendClient } from '../../graphql-endpoints/graphqlEndpoints'
import SelectProductBanner from '../../components/banners/SelectProductBanner'
import useI18next from '../../hooks/useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'
import ErrorHandler from '../../components/cards/ErrorHandler'
import Spinner from '../../components/spinner/Spinner'
import QuoteThroughFile from '../../components/cards/quote/QuoteThroughFile'
import { SearchOutlined } from '@ant-design/icons'

let isStatusBarDependent = false
let statusBarDependency = 0

class QuoteSteps extends Component {
  state = {
    statusBar: 0,
    showSalesmanButtons: false,
    loading: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.products.productName !== this.props.products.productName) {
      this.setState({ statusBar: 0 })
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    this.props.setProvisionVisibility(false)

    if (this.props.location.state !== null) {
      this.setState({ statusBar: this.props.location.state?.stepNumber })
      this.props.history.push({ state: { stepNumber: 0 } })
    }

    if (this.props.location.state?.showSalesmanButtons === true) {
      this.setState({ showSalesmanButtons: true })
    }

  }

  render() {

    const goBack = () => {
      let status = this.state.statusBar
      if (status > 0) {
        status === 0 ? status = 0 : status--;
        this.setState({ statusBar: status });
        goToStep(status)
      }
    }

    const setResult = (answer) => {
      if (answer.has_dependent) {
        this.props.deleteAnswer(answer.which_answer_depend_of_me - 1)
        this.props.createAnswer(answer, this.state.statusBar)
        this.setState({ showSalesmanButtons: false })
      } else {
        this.props.createAnswer(answer, this.state.statusBar)
      }
      goForward()
    }

    const goToStep = (stepNumber) => {
      this.setState(
        {
          statusBar: stepNumber
        },
        () => {
          if (isStatusBarDependent) {
            this.setState({ statusBar: statusBarDependency })
          }
        }
      )
    }

    const goForward = () => {
      let status = this.state.statusBar

      if (store.getState().answers.length === statusBar.length) {
        if (this.props.logins.accessType !== 'employee') {
          goToQuoteResults()
        } else {
          this.setState({ showSalesmanButtons: true })
        }
      }

      if (status < statusBar.length - 1 && store.getState().answers.length !== statusBar.length) {
        status === statusBar.length - 1 ? status = statusBar.length - 1 : status++;
        this.setState({ statusBar: status });
        goToStep(status)
      }
    }

    const goToQuoteResults = async (salesmanPriceFactor, data) => {

      let quotationAnswersArray = []
      let quotationResultsArray = []
      this.props.answers.map((e) => {
        return quotationAnswersArray.push({
          questionId: e[1] + 1,
          answerId: e[0].answer_back_end_id
        })
      })

      this.setState({ loading: true })
      data.map((element, index) => {

        // Stairlift Curved - backend id: 10 - Needs to replicate the answers until the quotationAnswersArray arrays has 9 items
        // Stairlift Straight - backend id: 11 - Needs to replicate the answers until the quotationAnswersArray arrays has 8 items
        if (element.replicate_anwser === true) {
          switch (this.props.products.productId) {
            case 10:
              while (quotationAnswersArray.length < 9) {
                quotationAnswersArray.push({
                  questionId: quotationAnswersArray.length + 1,
                  answerId: quotationAnswersArray[index].answerId
                })
              }
              break;
            case 11:
              while (quotationAnswersArray.length < 8) {
                quotationAnswersArray.push({
                  questionId: quotationAnswersArray.length + 1,
                  answerId: quotationAnswersArray[index].answerId
                })
              }
              break;
            default:
              break;
          }
          return null
        }

        return null
      })

      await backendClient.mutate({
        mutation: CREATE_QUOTATION,
        variables: {
          productCategory: this.props.products.productName,
          quotationAnswers: quotationAnswersArray,
          salesmanPriceFactor: salesmanPriceFactor
        }
      }).then((result) => quotationResultsArray.push(result.data.createQuotation)).catch(() => {
        return <ErrorHandler />
      })
      this.props.setQuotationResults(quotationResultsArray[0])
      this.setState({ loading: false })

      this.props.isFilterResultsOn(true)
      this.props.history.push({
        pathname: this.props.quotationResultsScreenUrl
      })
    }

    const checkDependency = (data) => {
      const currentAnswer = data.products[0].quotation.answer[this.state.statusBar]
      const clientAnswers = this.props.answers

      if (currentAnswer?.is_dependent === true) {
        if (clientAnswers.length === 0 || clientAnswers.every((answer) => answer[1] !== currentAnswer.dependent_answer - 1)) {

          isStatusBarDependent = true
          statusBarDependency = currentAnswer.dependent_answer - 1
          return data.products[0].quotation.answer[currentAnswer.dependent_answer - 1].options
        } else {
          isStatusBarDependent = false
          return currentAnswer.options.filter(option => {
            return option.dependent_option_number === clientAnswers.filter((answer) => {
              return answer[1] === currentAnswer.dependent_answer - 1
            })[0][0].option_number
          }
          )
        }
      } else {
        isStatusBarDependent = false
        return currentAnswer?.options
      }
    }

    const statusBar = []

    if (!this.props.impersonates.isImpersonate) {
      this.props.history.push({
        pathname: this.props.dashboardScreenUrl
      })
    }

    return (
      <div>
        {this.props.products.productName === '' &&
          <ProductSelection data={this.props} />
        }

        {this.props.products?.productName !== '' && this.props.products?.productId !== 24 &&
          <Query query={QUOTATION_STEPS_QUERY} client={cmsClient} variables={{ productId: parseInt(this.props.products.productId) }}>
            {({ loading, error, data }) => {

              if (loading) {
                return <Spinner />
              }

              if (error) {
                return <ErrorHandler />
              }

              data.products[0].quotation.question.map((e, index) => {
                const internationalizationJson = data.products[0].quotation.answer[index].internationalization_json_answer
                const internationalizationJsonQuestion = e.internationalization_json_question
                const I18next = () => useI18next({ ...internationalizationJson, ...internationalizationJsonQuestion })
                const { i18next } = I18next()

                return statusBar.push({
                  title: i18next.t(`title.${this.props.languages.language}`),
                  description: i18next.t(`step.${this.props.languages.language}`)
                })
              })

              return (
                <React.Fragment>
                  {
                    this.props.logins.isAuthenticated &&
                    <SelectProductBanner
                      productName={this.props.products.productName}
                      quotationStepsScreenUrl={this.props.quotationStepsScreenUrl}
                    />
                  }
                  <div className="card-deck p-3 p-lg-5">

                    {
                      isBrowser && !this.props.logins.isMobile ?
                        (
                          <div className="card border-0" style={{ background: 'transparent', alignItems: 'center' }}>
                            <div className="m-4">
                              <StatusBar statusBar={statusBar} status={this.state.statusBar} data={this.props.answers} goToStep={goToStep} />
                            </div>
                          </div>
                        )
                        : null
                    }

                    <div className="card border-0" style={{ background: 'transparent', marginBottom: 0 }}>
                      <QuestionCard questions={data.products[0].quotation.question[this.state.statusBar]} />

                      <QuoteAnswerCard
                        result={setResult}
                        answers={checkDependency(data)}
                        currentSelection={this.props.answers}
                        stepNumber={this.state.statusBar + 1}
                      />

                      <div className="d-flex justify-content-center m-2 mt-5">
                        {this.state.showSalesmanButtons && <Button.Group className="d-flex justify-content-center" size="default">
                          <div className="btn btn-orange" onClick={() => { goToQuoteResults('0.90', data.products[0].quotation.answer) }} trigger={isBrowser && !this.props.logins.isMobile ? 'hover' : 'contextMenu'}><b>{'<<'}</b></div>
                          <div className="btn btn-orange" onClick={() => { goToQuoteResults('0.95', data.products[0].quotation.answer) }} trigger={isBrowser && !this.props.logins.isMobile ? 'hover' : 'contextMenu'}><b>{'<'}</b></div>
                          <div className="btn btn-orange" data-cy="salesman-factor-1" onClick={() => { goToQuoteResults('1.00', data.products[0].quotation.answer) }} trigger={isBrowser && !this.props.logins.isMobile ? 'hover' : 'contextMenu'}><SearchOutlined /></div>
                          <div className="btn btn-orange" onClick={() => { goToQuoteResults('1.05', data.products[0].quotation.answer) }} trigger={isBrowser && !this.props.logins.isMobile ? 'hover' : 'contextMenu'}><b>{'>'}</b></div>
                          <div className="btn btn-orange" onClick={() => { goToQuoteResults('1.10', data.products[0].quotation.answer) }} trigger={isBrowser && !this.props.logins.isMobile ? 'hover' : 'contextMenu'}><b>{'>>'}</b></div>
                        </Button.Group>}
                      </div>
                      <div className="d-flex justify-content-center m-2">
                        <BackAndForwardButton goBack={goBack} goForward={goForward} />
                      </div>
                    </div>

                    <div className="card border-0" style={{ background: 'transparent' }}>
                      <ResultCard results={this.props.answers.sort((a, b) => a[1] - b[1])} questions={data.products[0].quotation.question} goToStep={goToStep} showFilter={false} resultsTitleJson={data.products[0].internationalization_json} />
                    </div>

                    {
                      !isBrowser || this.props.logins.isMobile ?
                        (
                          <div className="card border-0 w-100" style={{ background: 'transparent', alignItems: 'center', flex: 'auto' }}>
                            <div className="m-4">
                              <StatusBar statusBar={statusBar} status={this.state.statusBar} data={this.props.answers} goToStep={goToStep} />
                            </div>
                          </div>
                        )
                        : null
                    }
                  </div>
                </React.Fragment>
              )
            }}
          </Query>
        }

        {
          this.state.loading && <Spinner />
        }

        {this.props.products?.productId === 24 &&
          <Query query={QUOTATION_STEPS_QUERY} client={cmsClient} variables={{ productId: parseInt(this.props.products.productId) }}>
            {({ loading, error, data }) => {

              if (loading) {
                return <Spinner />
              }

              if (error) {
                return <ErrorHandler />
              }
              return (
                <QuoteThroughFile
                  preOrderScreennUrl={this.props.preOrderScreennUrl}
                  history={this.props.history}
                  quotationStepsLabels={data}
                  productName={this.props.products.productName}
                  quotationStepsScreenUrl={this.props.quotationStepsScreenUrl}
                />
              )
            }}
          </Query>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
    products: state.products,
    logins: state.logins,
    impersonates: state.impersonates,
    languages: state.languages
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAnswer: (answer, step) => dispatch(answerAction.createAnswer(answer, step)),
    deleteAnswer: (step) => dispatch(answerAction.deleteAnswer(step)),
    setQuotationResults: (result) => dispatch(resultsAction.setQuotationResults(result)),
    isFilterResultsOn: (boolean) => dispatch(filterAction.isFilterResultsOn(boolean)),
    setProvisionVisibility: (value) => dispatch(provisionAction.setProvisionVisibility(value))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(QuoteSteps));