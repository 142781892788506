import React from 'react'
import { Card } from 'antd';
import useI18next from '../../../hooks/useI18next'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import * as filterAction from '../../../redux/actions/quotation/filterAction';
import { CheckCircleOutlined } from '@ant-design/icons'

const QuoteResultCard = (props) => {

  const {
    resultsTitleJson,
    goToStep,
    results,
    questions,
    showFilter,
    messageInfoFilterRanksDropdown,
    rankValue,
    goToFilter,
    filterCardTitle
  } = props

  let actions = []

  const languages = useSelector(state => state.languages)
  const dispatch = useDispatch()
  const isFilterResultsOn = (boolean) => dispatch(filterAction.isFilterResultsOn(boolean));

  const I18next = () => useI18next(resultsTitleJson)
  const { i18next } = I18next()

  const resultsTitle = i18next.t(`results_title.${languages.language}`)

  const handleGoToStep = (stepNumber) => {
    goToStep(stepNumber)
    isFilterResultsOn(true)
  }

  results.map((e, index) => {

    const internationalizationJson = questions[e[1]].internationalization_json_question
    const I18next = () => useI18next(internationalizationJson)
    const { i18next } = I18next()

    return actions.push(
      <div key={index} className="m-auto">
        <div className='m-2'>
          <div className='m-2'>
            <CheckCircleOutlined className='m-2' />
            {i18next.t(`title.${languages.language}`)}
          </div>
          <img
            className='m-2 img-hover'
            style={{ cursor: 'pointer' }}
            height={100}
            width={100}
            src={e[0]?.option_url}
            alt={`Result ${index}`}
            onClick={() => { handleGoToStep(e[1]) }}
          />
        </div>
      </div>
    )
  })

  const cssIEorNot = (false || !!document.documentMode) ? { top: '0px', maxWidth: '582px' } : { top: '140px', display: 'table' }

  return (
    <Card
      style={{ marginTop: 16, position: 'sticky', ...cssIEorNot }}
      title={<b>{resultsTitle}</b>}
      bordered={false}
    >
      <div className="container">

        {
          showFilter &&
          <div>
            <div style={{ textAlign: 'center', color: '#143e47', fontSize: '16px', fontWeight: 'bold' }}>
              {`${messageInfoFilterRanksDropdown} ${rankValue}`}
            </div>
            <div className="mt-3 btn btn-orange w-100" onClick={() => goToFilter()}>
              {filterCardTitle}
            </div>
          </div>
        }

        <div className="row">
          {actions}
        </div>
      </div>
    </Card >
  )
}

export default React.memo(QuoteResultCard)