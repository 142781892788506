import React, { useEffect, useState, Suspense } from 'react';
import { Modal } from 'antd';
import './Login.scss';
import * as loginAction from '../../redux/actions/loginAction';
import * as alertBannerAction from '../../redux/actions/alertBannerAction';
import * as languageAction from '../../redux/actions/languageAction';
import { useDispatch } from 'react-redux';
import useLoginUserMutation from '../../hooks/backend/useLoginUserMutation'
import useSendForgotPasswordEmailMutation from '../../hooks/backend/useSendForgotPasswordEmailMutation'
import useDrawer from '../../hooks/useDrawer'
import { withRouter } from "react-router";
import useI18next from '../../hooks/useI18next'
import axios from 'axios';
import useNewUserQuery from '../../hooks/cms/useNewUserQuery'
import useLoginQuery from '../../hooks/cms/useLoginQuery'
import withHandleQuery from '../../hoc/withHandleQuery'

const LoginForm = React.lazy(() => import('../../components/cards/LoginForm'));
const LoginArea = React.lazy(() => import('../../components/cards/LoginArea'));
const CreateAccount = React.lazy(() => import('../../components/cards/CreateAccount'));
const LoginSocialMedia = React.lazy(() => import('../../components/cards/LoginSocialMedia'));
const NewUserDrawer = React.lazy(() => import('../../components/drawers/NewUserDrawer'));

const Login = (props) => {

  const {
    dataHoc,
    location,
    loginScreenUrl,
    history
  } = props

  const [loginForm, setLoginForm] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [emailConfirmation, setEmailConfirmation] = useState(false)

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let i
    for (i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    const checkCookie = () => {
      let registered = getCookie("registered");
      if (registered === "") {
        setLoginForm(false)
      } else {
        setLoginForm(true)
      }
    }
    checkCookie()
  }, [])

  const dispatch = useDispatch()
  const setUsername = (username) => dispatch(loginAction.setUsername(username))
  const setEmployee = (name, role, rating) => dispatch(loginAction.setEmployee(name, role, rating))
  const setAuthentication = (value) => dispatch(loginAction.setAuthentication(value))
  const setAccessType = (type) => dispatch(loginAction.setAccessType(type))
  const setAlertBannerVisibility = (boolean) => dispatch(alertBannerAction.setAlertBannerVisibility(boolean))
  const setAlertBannerMessage = (message) => dispatch(alertBannerAction.setAlertBannerMessage(message))
  const setAlertBannerType = (type) => dispatch(alertBannerAction.setAlertBannerType(type))
  const setLanguage = (lang, locale) => dispatch(languageAction.setLanguage(lang, locale))

  const { showDrawer, isDrawerVisible, closeDrawer } = useDrawer()

  const getURL = (json, language) => {
    let userLanguage = "nl"
    if (language === undefined || language === '' || language === null) {
      switch (navigator.language) {
        case 'en-US':
          userLanguage = 'en'
          break
        case 'nl':
          userLanguage = 'nl'
          break
        case 'fr':
          userLanguage = 'fr'
          break
        case 'de':
          userLanguage = 'de'
          break
        case 'it':
          userLanguage = 'it'
          break
        default:
          userLanguage = 'en'
      }
    } else {
      /**
       * this switch allows the frontend to format the user preferredLanguage value comming from the database. 
       * the preferredLanguage value does not have a consistent format.
       * to do - simplefy. This code exist 2 times
       */
      switch (language.toLowerCase()) {
        case 'en':
        case 'en_en':
          userLanguage = 'en'
          break
        case 'nl':
        case 'nl_nl':
          userLanguage = 'nl'
          break
        case 'fr':
        case 'fr_fr':
          userLanguage = 'fr'
          break
        case 'de':
        case 'de_de':
          userLanguage = 'de'
          break
        case 'it':
        case 'it_it':
          userLanguage = 'it'
          break
        case 'nl_be':
          userLanguage = 'nl_BE'
          break
        case 'fr_be':
          userLanguage = 'fr_BE'
          break
        default:
          userLanguage = 'nl'
      }
    }
    const I18next = () => useI18next(json)
    const { i18next } = I18next()
    return i18next.t(`url_path.${userLanguage}`)
  }

  const newUserLabels = dataHoc[0] !== undefined && dataHoc[0].newUserLabels
  const loginLabels = dataHoc[1] !== undefined && dataHoc[1].loginLabels
  const loginUserMutation = dataHoc[2] !== undefined && dataHoc[2].loginUserMutation
  const sendForgotPasswordEmailMutation = dataHoc[3] !== undefined && dataHoc[3].sendForgotPasswordEmailMutation

  const labels = { ...newUserLabels, ...loginLabels }

  if (location.search.search('email_confirmation_token') === 1 && loadingModal === false) {
    emailConfirmation === false ? setLoadingModal(true) : setLoadingModal(false)

    const search = location.search;
    const params = new URLSearchParams(search);
    const emailConfirmationToken = params.get('email_confirmation_token');

    const registrationURL = `${process.env.REACT_APP_BACKEND_CONFIRM_REGISTRATION_ENDPOINT}${emailConfirmationToken}`

    if (emailConfirmation === false) {
      setEmailConfirmation(true)
      axios.get(registrationURL).then(() => {
        Modal.success({
          title: labels.confirmRegistrationSuccessTitle,
          content: labels.confirmRegistrationSuccessContent,
          okText: labels.confirmRegistrationSucessButton,
          okType: 'default',
          onOk: () => { setLoadingModal(false); return history.push(loginScreenUrl) }
        })
      }).catch((error) => {
        Modal.error({
          title: labels.confirmRegistrationErrorTitle,
          content: labels.confirmRegistrationErrorContent,
          okText: labels.confirmRegistrationErrorButton,
          okType: 'default',
          onOk: () => { setLoadingModal(false); return history.push(loginScreenUrl) }
        })
      })
    }
  }

  const login = (userEmail, userType, preferredLanguage, customerJourney, locale, employee = null) => {
    setUsername(userEmail)
    setAuthentication(true)

    if (employee != null) {
      setEmployee({
        firstName: employee.firstName,
        lastName: employee.lastName,
        role: employee.Roles,
        rating: employee.StarRatings
      })
    }

    switch (customerJourney) {
      case 'suspect':
        setAccessType('suspect')
        break
      case 'prospect':
        setAccessType('prospect')
        break
      case 'customer':
        setAccessType('customer')
        break
      default:
        userType === 'customer' ? setAccessType('suspect') : setAccessType(userType)
    }

    if (preferredLanguage !== null && preferredLanguage !== undefined && locale !== null && locale !== undefined) {
      // to do - 
      let userLanguage = "nl"
      switch (preferredLanguage.toLowerCase()) {
        case 'en':
        case 'en_en':
          userLanguage = 'en'
          break
        case 'nl':
        case 'nl_nl':
          userLanguage = 'nl'
          break
        case 'fr':
        case 'fr_fr':
          userLanguage = 'fr'
          break
        case 'de':
        case 'de_de':
          userLanguage = 'de'
          break
        case 'nl_be':
          userLanguage = 'nl'
          break
        case 'fr_be':
          userLanguage = 'fr'
          break
        case 'it':
        case 'it_it':
          userLanguage = 'it'
          break
        default:
          userLanguage = 'nl'
      }

      setLanguage(userLanguage, locale)
    }

    const myPersonalScreenUrl = getURL(props.myPersonalScreenUrl, preferredLanguage)
    const dashboardScreenUrl = getURL(props.dashboardScreenUrl, preferredLanguage)

    if (userType === 'customer') {
      history.push(myPersonalScreenUrl)
    } else {
      history.push(dashboardScreenUrl)
    }

    setAlertBannerVisibility(true)
    setAlertBannerMessage(`${labels.bannerMessageLogin} ${userEmail}`)
    setAlertBannerType('success')
    document.cookie = "registered=true";

  }

  return (
    <Suspense fallback={null}>
      <React.Fragment>
        {!loginForm &&
          <div>
            <div className="justify-content-center h-auto" style={{ display: 'grid' }}>
              <LoginArea setLoginForm={setLoginForm} labels={labels} />
              <CreateAccount showDrawer={showDrawer} labels={labels} />
            </div>
            <LoginSocialMedia login={login} labels={labels} />
          </div>
        }

        {loginForm &&
          <div className="d-flex justify-content-center pt-4" style={{ height: 'auto' }}>
            <LoginForm login={login} loginUserMutation={loginUserMutation} sendForgotPasswordEmailMutation={sendForgotPasswordEmailMutation} loginLabels={loginLabels} showDrawer={showDrawer} labels={labels} newUserLabels={newUserLabels} />
          </div>
        }
        <NewUserDrawer isDrawerVisible={isDrawerVisible} closeDrawer={closeDrawer} labels={labels} />
      </React.Fragment>
    </Suspense>
  )
}

export default withHandleQuery([useNewUserQuery, useLoginQuery, useLoginUserMutation, useSendForgotPasswordEmailMutation])(withRouter(React.memo(Login)))