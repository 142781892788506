import { createStore } from 'redux';
import appReducer from '../reducers';
import { loadFromSessionStorage } from '../../session-storage/sessionStorage'

const persistedState = loadFromSessionStorage()

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = {
      languages: {
        language: state.languages.language
      },
      logins: {
        isAuthenticated: false
      }
    }
  }
  return appReducer(state, action)
}

export default function configureStore(initialState) {
  return createStore(rootReducer, persistedState, initialState);
}