import * as actionTypes from '../actions/actionType';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_QUOTATION:
      return { ...state, quotation: action.quotation }
    case actionTypes.DELETE_QUOTATION:
      return { quotation: {} }
    default:
      return state;
  }
}