import * as actionTypes from '../actions/actionType';

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_PRODUCTS:
      return { ...state, Products: action.customerProducts }
    case actionTypes.DELETE_CUSTOMER_PRODUCTS:
      return {}
    default:
      return state;
  }
};