import React, { useEffect, useState } from 'react'
import MyPersonal from '../screens/my-personal/MyPersonal'
import MyHomeVisit from '../screens/my-home-visit/MyHomeVisit'
import MyQuotation from '../screens/my-quotation/MyQuotation'
import MyOrder from '../screens/my-order/MyOrder'
import MyService from '../screens/my-service/MyService'
import QuoteSteps from '../screens/quoteSteps/QuoteSteps'
import NotFound from '../screens/NotFound'
import QuoteResult from '../screens/quoteResult/QuoteResult'
import QuotationOptions from '../screens/quotation-options/QuotationOptions'
import CookieConsentDrawer from '../components/drawers/CookieConsentDrawer'
import Order from '../screens/order/Order'
import Login from '../screens/login/Login'
import ResetPassword from '../screens/ResetPassword'
import Dashboard from '../screens/dashboard/Dashboard'
import { Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from '../auth/auth'
import { useSelector } from 'react-redux'
import useI18next from '../hooks/useI18next'

// Mobilae 2.0
import CtdCheck from '../screens/quotation-check/CtdCheck'
import sanityClient from '../lib/client.js'
import { screensSlugByIdAndLanguageQuery } from '../lib/queries/cms/queries'

const Routes = (props) => {

  const {
    dataJson,
    history
  } = props

  const [ctdCheckScreenSlug, setCtdCheckScreenSlug] = useState(null)

  const languages = useSelector(state => state.languages)
  const logins = useSelector(state => state.logins)
  const impersonates = useSelector(state => state.impersonates)

  // Mobilae 2.0
  useEffect(() => {

    if (languages.locale) {
      const query = screensSlugByIdAndLanguageQuery
      const params = { id: 'a7fcbbd7-cfa7-4e67-9721-04671997da33*', language: languages.locale }

      sanityClient.fetch(query, params)
        .then((data) => {
          setCtdCheckScreenSlug(data.slugComponent.slug.current)
        })
        .catch(console.error)
    }
  }, [languages.locale])

  const getURL = (json) => {
    const I18next = () => useI18next(json)
    const { i18next } = I18next()
    return i18next.t(`url_path.${languages.language}`)
  }

  const myPersonalScreenUrl = getURL(dataJson.myPersonalScreenJson)
  const myHomeVisitScreenUrl = getURL(dataJson.myHomeVisitScreenJson)
  const myQuotationScreenUrl = getURL(dataJson.myQuotationScreenJson)
  const myOrderScreenUrl = getURL(dataJson.myOrderScreenJson)
  const myServiceScreenUrl = getURL(dataJson.myServiceScreenJson)
  const dashboardScreenUrl = getURL(dataJson.dashboardScreenJson)
  const quotationStepsScreenUrl = getURL(dataJson.quotationStepsScreenJson)
  const quotationResultsScreenUrl = getURL(dataJson.quotationResultsScreenJson)
  const loginScreenUrl = getURL(dataJson.loginScreenJson)
  const preOrderScreennUrl = getURL(dataJson.preOrderScreenJson)
  const orderScreenUrl = getURL(dataJson.orderScreenJson)
  const resetPasswordScreenUrl = window.location.search.match(new RegExp(`(?:[?&]lang=)([^&]+)`)) === null ? getURL(dataJson.resetPasswordScreenJson) : dataJson.resetPasswordScreenJson['url_path'][window.location.search.match(new RegExp(`(?:[?&]lang=)([^&]+)`))?.[1]]

  // Handle the slug change when the language change
  // Redirect the user for the My Personal Screen or the Dashboard Screen
  useEffect(() => {

    if (logins.isAuthenticated === true) {
      if (logins.accessType === 'employee' && impersonates.isImpersonate === false) {
        history.push(dashboardScreenUrl)
      }
      // Remove this option because the salesmen went to the myPersonalScreen when they refresh the page during the quotation process
      // else {
      //   history.push(myPersonalScreenUrl)
      // }
    }
  }, [languages])

  // Mobilae 2.0
  const role = {
    general: 'general',
    suspect: 'suspect',
    prospect: 'prospect',
    customer: 'customer',
    employee: 'employee',
    partner: 'partner'
  }

  const routesArray = [
    // Mobilae 2.0
    {
      path: `/${ctdCheckScreenSlug}`,
      component: CtdCheck,
      access: [
        role.employee
      ]
    },
    {
      path: loginScreenUrl,
      component: Login,
      access: [
        role.general
      ]
    },
    {
      path: resetPasswordScreenUrl,
      component: ResetPassword,
      access: [
        role.general
      ]
    },
    {
      path: '/cookie-consent',
      component: CookieConsentDrawer,
      access: [
        role.suspect,
        role.prospect,
        role.customer,
        role.employee,
        role.partner
      ]
    },
    {
      path: myPersonalScreenUrl,
      component: MyPersonal,
      access: [
        role.suspect,
        role.prospect,
        role.customer,
        role.employee,
        role.partner
      ]
    },
    {
      path: myHomeVisitScreenUrl,
      component: MyHomeVisit,
      access: [
        role.prospect,
        role.customer,
        role.employee,
        role.partner
      ]
    },
    {
      path: myQuotationScreenUrl,
      component: MyQuotation,
      access: [
        role.employee,
        role.partner
      ]
    },
    {
      path: myOrderScreenUrl,
      component: MyOrder,
      access: [
        role.customer,
        role.employee,
        role.partner
      ]
    },
    {
      path: myServiceScreenUrl,
      component: MyService,
      access: [
        role.customer,
        role.employee,
        role.partner
      ]
    },
    {
      path: quotationStepsScreenUrl,
      component: QuoteSteps,
      access: [
        role.employee
      ]
    },
    {
      path: quotationResultsScreenUrl,
      component: QuoteResult,
      access: [
        role.prospect,
        role.customer,
        role.employee
      ]
    },
    {
      path: preOrderScreennUrl,
      component: QuotationOptions,
      access: [
        role.customer,
        role.employee
      ]
    },
    {
      path: orderScreenUrl,
      component: Order,
      access: [
        role.customer,
        role.employee
      ]
    },
    {
      path: dashboardScreenUrl,
      component: Dashboard,
      access: [
        role.employee,
        role.partner
      ]
    },
  ]

  const redirectRoutesArray = [
    {
      path: '/',
      redirect: myPersonalScreenUrl,
      access: [
        role.suspect,
        role.prospect,
        role.customer,
        role.employee,
        role.partner
      ]
    }
  ]

  return (
    <Switch>
      <Route
        exact
        path={loginScreenUrl}
        render={() => <Login myPersonalScreenUrl={dataJson.myPersonalScreenJson} dashboardScreenUrl={dataJson.dashboardScreenJson} />}
      />
      <Route
        exact
        path={resetPasswordScreenUrl}
        render={() => <ResetPassword loginScreenUrl={loginScreenUrl} />}
      />
      {
        routesArray.map((route, index) => {
          return <PrivateRoute
            key={route.path + index}
            exact
            path={route.path}
            comp={route.component}
            access={route.access}
            logins={logins}
            quotationResultsScreenUrl={quotationResultsScreenUrl}
            quotationStepsScreenUrl={quotationStepsScreenUrl}
            myHomeVisitScreenUrl={myHomeVisitScreenUrl}
            loginScreenUrl={loginScreenUrl}
            dashboardScreenUrl={dashboardScreenUrl}
            myQuotationScreenUrl={myQuotationScreenUrl}
            myOrderScreenUrl={myOrderScreenUrl}
            myServiceScreenUrl={myServiceScreenUrl}
            myPersonalScreenUrl={myPersonalScreenUrl}
            orderScreenUrl={orderScreenUrl}
            preOrderScreennUrl={preOrderScreennUrl}
            dataJson={dataJson}
            ctdCheckScreenUrl={ctdCheckScreenSlug}
          />
        })
      }
      {
        <PrivateRoute
          exact
          path={redirectRoutesArray[0].path}
          access={redirectRoutesArray[0].access}
          logins={logins}
          quotationResultsScreenUrl={quotationResultsScreenUrl}
          loginScreenUrl={loginScreenUrl} >
          <Redirect to={redirectRoutesArray[0].redirect} />
        </PrivateRoute>

      }
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes;