const graphqlMutation = async (variables, query, url, customHeaders = {}) => {

  const graphqlBody = JSON.stringify({
    query,
    variables,
  });

  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers,
    body: graphqlBody,
    redirect: 'follow',
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return {
      data: result,
      headers: Object.fromEntries(response.headers.entries()),
    };

  } catch (error) {
    console.error('Error in graphqlMutation:', error);
    throw error;
  }
};

export { graphqlMutation };
