import * as actionTypes from './actionType'

export const setCustomerOrders = (orders) => {
  return {
    type: actionTypes.SET_CUSTOMER_ORDERS,
    customerOrders: orders,
  }
}

export const deleteCustomerOrders = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_ORDERS,
  }
};