import { ORDERS_QUERY } from '../../queries/back-end/queries';
import { useQuery } from '@apollo/react-hooks';

const useOrdersQuery = () => {

  const { data, loading, error } = useQuery(ORDERS_QUERY);

  if (loading) return { loading }
  if (error) return { error }

  return {
    ordersQueryData: data,
    loading,
    error
  }
}

export default useOrdersQuery