import { QUOTATION_QUERY } from '../../queries/back-end/queries';
import { useQuery } from '@apollo/react-hooks';

const useQuotationQuery = () => {

  const { data, loading, error } = useQuery(QUOTATION_QUERY);

  if (loading) return { loading }
  if (error) return { error }

  const backendData = {
    data: data
  }

  return {
    quotationQueryData: backendData,
    loading,
    error
  }
}

export default useQuotationQuery