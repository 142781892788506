export const allScreensSlugsByLanguageQuery = `
* [_type == "screen" && __i18n_lang == $language] {
  _id,
  slug
}
`

export const screensSlugByIdAndLanguageQuery = `
*[_type == "screen" && _id match $id && __i18n_lang == $language][0] {
  _id,
  slugComponent-> {
    slug {
      current
    }
  }
}
`

export const loginCardQuery = `
select(_type == "loginCard") => {
  __i18n_lang,
  _id,
  _type,
  forgetPasswordPlaceHolder,
  forgotPassword,
  forgotPasswordErrorMessage,
  forgotPasswordSuccessMessage,
  gorgetPasswordTitle,
  loginButton,
  newAccount,
  option,
  password,
  passwordErrorMessage,
  rememberMe,
  typeName,
  username,
  usernameErrorMessage,
  usernameOrPasswordErrorMessage
}
`

export const brochureEntryQuery = `
select(_type == "brochureEntry") => {
  __i18n_lang,
  _id,
  _type,
  show_in_belgium,
  show_in_france,
  show_in_germany,
  show_in_netherlands,
  show_in_italy,
  category_name,
  show_brochure_url,
  category_brochure,
  icon_url,
  showBrochureErrorTitle,
  showBrochureErrorMessage,
  callMeSuccessTitle,
  callMeSuccessMessage,
  callMeErrorTitle,
  callMeErrorMessage,
  cardTitle,
  drawerBrochureButton,
  drawerCallMeButton
}
`

export const brochureQuery = `
select(_type == "brochure") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  showCard,
  ${brochureEntryQuery}
}
`

export const maintenanceVisitQuery = `
select(_type == "maintenanceVisitQuery") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  iconMaintenanceVisit,
  textMaintenanceVisit,
  advisorMaintenanceVisit
}
`

export const workflowQuery = `
select(_type == "workflow") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  iconActivityTypeAppointment,
  iconActivityTypeCall,
  iconActivityTypeEmail,
  lastInteractionTitle,
  lastDocumentTitle,
  cardTitle,
  searchButton,
  cardTitleDrawer
}
`

export const subventionQuery = `
select(_type == "subvention") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  searchButton,
  uploadButton,
  cardTitleDrawer,
  uploadSuccessMessage,
  uploadFailedMessag,
  uploadText,
  uploadInformation,
  closeButton
}
`

export const whatExpectQuery = `
select(_type == "whatExpect") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  playButton,
  experienceVideoTitle,
  experienceVideoText,
  experienceVideo,
  visualizationVideoTitle,
  visualizationVideoText,
  visualizationVideo,
  quotationVideoTitle,
  quotationVideoText,
  quotationVideo,
  cardTitleDrawer,
  title,
  video
}
`

export const localAdvisorQuery = `
select(_type == "localAdvisor") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  certifiedText
}
`

export const assistanceQuery = `
select(_type == "assistance") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  callMeSuccessMessage,
  callMeErrorMessage,
  customerNumber,
  callBackIcon,
  callMeTitle
}
`

export const deliveryNoteQuery = `
select(_type == "deliveryNote") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  icon,
  cardTitleDrawer,
  drawerDocumentLabel
}
`

export const agreedPaymentQuery = `
select(_type == "agreedPayment") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  ofLabel,
  currency,
  paymentButton,
  unpaidStatus,
  paidStatus,
  dueStatus,
  firstTitle,
  secondTitle,
  thirdTitle
}
`

export const orderProcessQuery = `
select(_type == "orderProcess") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  processStepOne,
  processStepTwo,
  processStepThree,
  processStepFour,
  deliveryTime
}
`

export const productInformationQuery = `
select(_type == "productInformation") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  searchButton,
  userManualBtn,
  deliveryDate,
  warrantyDate,
  drawerTitleUserManual,
  drawerUserManualBtn,
  drawerTitleProductInformation
}
`

export const contractInformationQuery = `
select(_type == "contractInformation") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  noContractTitle,
  noContractContent,
  noContractImage,
  contractDate,
  warrantyDate,
  contractType,
  externalNumber,
  serialNumber
}
`

export const documentsQuery = `
select(_type == "documents") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  searchButton,
  uploadButton,
  cardTitleDrawer,
  documentTypeQuotation,
  documentTypeOrderForm,
  documentTypeDeliveryNote,
  documentTypeInvoice,
  documentTypeSubventionDocuments,
  documentTypePersonalDocuments,
  documentTypeOrderConfirmation,
  documentTypeMaintenanceAbonnement,
  popupMessageDocumentType,
  popupMessageDocumentTypeAndSize,
  uploadSuccessMessage,
  uploadFailedMessage,
  documentType,
  uploadText,
  uploadInformation,
  closeButton
}
`

export const cookieBannerQuery = `
select(_type == "cookieBanner") => {
  __i18n_lang,
  _id,
  _type,
  bannerButton,
  bannerText,
  drawerButton,
  drawerContent,
  cardTitle
}
`

export const appointmentsQuery = `
select(_type == "appointments") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  homeVisitSuccessMessage,
  homeVisitErrorMessage,
  icon
}
`

export const clientQuotationQuery = `
select(_type == "clientQuotation") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  subtitle,
  cardTitleDrawer,
  editButton
}
`

export const commercialAppointmentsQuery = `
select(_type == "commercialAppointments") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  drawerTitle,
  bannerMessageLabel,
  localizationButtonLabel,
  impersonateButtonLabel,
  customerNameLabel,
}
`

export const commercialPersonalInformationQuery = `
select(_type == "commercialPersonalInformation") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  editButtonLabel,
  nameLabel,
  emailLabel,
  DrawerTitle,
  nameLabelDrawer,
  ruleInsertName,
  emailLabelDrawer,
  ruleInsertEmail,
  ruleInvalidEmail,
  submitButtonLabel
}
`
export const confirmationQuery = `
select(_type == "confirmation") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  icon,
  cardTitleDrawer,
  drawerDocumentLabel,
}
`

export const customerOrderQuery = `
select(_type == "customerOrder") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  searchButton,
  orderCode,
  DrawerTitle
}
`
export const priceListQuery = `
select(_type == "priceList") => {
  __i18n_lang,
  _id,
  _type,
  icon,
  cardTitle,
  drawerTitle,
  drawerContent
}
`

export const quoteFinalResultsQuery = `
select(_type == "quoteFinalResults") => {
  __i18n_lang,
  _id,
  _type,
  icon,
  cardTitle
}
`

export const quoteStepsQuery = `
select(_type == "quoteSteps") => {
  __i18n_lang,
  _id,
  _type,
  icon,
  cardTitle
}
`

export const specialPromotionsQuery = `
select(_type == "specialPromotions") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  title,
  text,
  image,
  button,
  cardTitleDrawer,
  drawerContent
}
`

export const newUserQuery = `
select(_type == "newUser") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  addCustomerButton,
  searchCustomerButton,
  cardTitleDrawer,
  agreementPopupTitle,
  aggrementPopupContent,
  aggreementPopupButton,
  successMessageTitle,
  successMessageContent,
  successMessageOkButton,
  errorMessageSameEmailContent,
  errorMessageTitle,
  errorMessageOkButton,
  errorMessageContent,
  rulePasswordsConsistency,
  ruleAgreement,
  firstName,
  ruleTooltipUserName,
  ruleFirstName,
  lastName,
  ruleLastName,
  country,
  countryErrorMessage,
  countryValueFlanders,
  countryOptionFlanders,
  countryValueWallonia_brussels,
  countryOptionWalloniaBrussels,
  countryValueFrance,
  countryOptionFrance,
  countryValueGermany,
  countryOptionGermany,
  countryValueTheNetherlands,
  countryOptionTheNetherlands,
  countryValueItaly,
  countryOptionItaly,
  preferredLanguage,
  preferredLanguageErrorMessage,
  preferredLanguageValueTheNetherlands,
  preferredLanguageOptionTheNetherlands,
  preferredLanguageValueWalloniaBrussels,
  preferredLanguageOptionWalloniaBrussels,
  preferredLanguageValueFlanders,
  preferredLanguageOptionFlanders,
  preferredLanguageValueFrance,
  preferredLanguageOptionFrance,
  preferredLanguageValueGermany,
  preferredLanguageOptionGermany,
  preferredLanguageValueItaly,
  preferredLanguageOptionItaly,
  email,
  ruleInvalidEmail,
  ruleInsertEmail,
  password,
  rulePassword,
  confirmPassword,
  rulePasswordConfirmation,
  ruleReadAgreement,
  agreement,
  agreementButton,
  registerButton
}
`

export const invoiceQuery = `
select(_type == "invoice") => {
  __i18n_lang,
  _id,
  _type,
  icon,
  cardTitle,
  drawerTitle,
  drawerDocumentLabel
}
`

export const salesmanStatisticsQuery = `
select(_type == "salesmanStatistics") => {
  __i18n_lang,
  _id,
  _type,
  appointments,
  sales,
  conversion,
  margin
}
`

export const resetPasswordQuery = `
select(_type == "resetPassword") => {
  __i18n_lang,
  _id,
  _type,
  cardName,
  rulePasswordsConsistency,
  popupSuccessMessage,
  popupErrorMessage,
  title,
  password,
  rulePassword,
  confirmPassword,
  rulePasswordConfirmation,
  requestButton
}
`

export const seachUserQuery = `
select(_type == "seachUser") => {
  __i18n_lang,
  _id,
  _type,
  cardName,
  userId,
  searchButton,
  impersonateTooltip,
  impersonateCard
}
`

export const signatureQuery = `
select(_type == "signature") => {
  __i18n_lang,
  _id,
  _type,
  title,
  popupSuccessMessage,
  undoButton,
  clearButton,
  saveButton
}
`

export const houseYearQuery = `
select(_type == "houseYear") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  title
}
`

export const overviewPdfQuery = `
select(_type == "overviewPdf") => {
  __i18n_lang,
  _id,
  _type,
  pdfCoverPage,
  pdfQuotationStepsInformationPage,
  pdfAuthor,
  pdfKeywords,
  pdfSubject,
  pdfTitle,
  productNameLabel,
  customerLabel,
  dateLabel,
  priceLable,
  techicalDetailsLabel,
  pdfQuotationFooter,
  pdfOrderFooter,
  pdfLogo,
  quotationLabel,
  orderLabel,
  pdfOrderGeneralConditions,
  customerNumber,
  localAdviser,
  subventionValue,
  versionNumber,
  optionsAndConditionsLabel,
  orderValueLabel,
  discountLabel,
  valueToPayLabel
}
`

export const alertBannerQuery = `
select(_type == "alertBanner") => {
  __i18n_lang,
  _id,
  _type,
  alertMessageCommercial,
  alertMessageAppointment,
  alertMessageDebt
}
`

export const quoteThroughFileQuery = `
select(_type == "quoteThroughFile") => {
  __i18n_lang,
  _id,
  _type,
  cardName,
  filterSection,
  categorySection,
  categoryOptionOne,
  categoryOptionTwo,
  categoryOptionThree,
  categoryOptionFour,
  csvFileSection,
  uploadCsvFileTitle,
  uploadCsvFileDescription,
  uploadCsvFileSuccessMessage,
  uploadCsvFileErrorMessage,
  imageSection,
  uploadImageFileTitle,
  uploadImageFileDescription,
  uploadImageFileSuccessMessage,
  uploadImageFileErrorMessage
}
`

export const visitQuery = `
select(_type == "visit") => {
  __i18n_lang,
  _id,
  _type,
  showCard,
  cardTitle,
  icon,
  text,
  suitableDate
}
`

const personalInformationQuery = `
select(_type == "personalInformation") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  tooltipEdit,
  nameLabel,
  addressLabel,
  zipCode,
  phoneNumberLabel,
  emailLabel,
  showCard,
  drawerTitle,
  successMessageTitle,
  successMessageContent,
  errorMessageTitle,
  errorMessageContent,
  firstNameLabel,
  ruleFirstName,
  lastNameLabel,
  ruleLastName,
  preferredLanguage,
  preferredLanguageErrorMessage,
  preferredLanguageValueTheNetherlands,
  preferredLanguageOptionTheNetherlands,
  preferredLanguageValueWalloniaBrussels,
  preferredLanguageOptionWalloniaBrussels,
  preferredLanguageValueFlanders,
  preferredLanguageOptionFlanders,
  preferredLanguageValueFrance,
  preferredLanguageOptionFrance,
  preferredLanguageValueGermany,
  preferredLanguageOptionGermany,
  preferredLanguageValueItaly,
  preferredLanguageOptionItaly,
  addressLabelDrawer,
  ruleAddress,
  zipCodeDrawer,
  ruleZipCode,
  city,
  ruleCity,
  phoneNumberLabelDrawer,
  rulePhoneNumber,
  ruleButton,
  changePasswordSuccessMessage,
  changePasswordErrorMessageIncorrect,
  currentPassword,
  ruleCurrentPassword,
  newPassword,
  ruleNewPassword,
  ruleButton,
  changeEmailSuccessMessage,
  changeEmailErrorMessage,
  newEmailLabel,
  ruleNewEmail,
  submitEmailButton
}
`

export const personalInformationByLocaleQuery = `
*[_type == "personalInformation" && __i18n_lang == $language][0] {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  tooltipEdit,
  nameLabel,
  addressLabel,
  zipCode,
  phoneNumberLabel,
  emailLabel,
  showCard,
  drawerTitle,
  successMessageTitle,
  successMessageContent,
  errorMessageTitle,
  errorMessageContent,
  firstNameLabel,
  ruleFirstName,
  lastNameLabel,
  ruleLastName,
  preferredLanguage,
  preferredLanguageErrorMessage,
  preferredLanguageValueTheNetherlands,
  preferredLanguageOptionTheNetherlands,
  preferredLanguageValueWalloniaBrussels,
  preferredLanguageOptionWalloniaBrussels,
  preferredLanguageValueFlanders,
  preferredLanguageOptionFlanders,
  preferredLanguageValueFrance,
  preferredLanguageOptionFrance,
  preferredLanguageValueGermany,
  preferredLanguageOptionGermany,
  preferredLanguageValueItaly,
  preferredLanguageOptionItaly,
  addressLabelDrawer,
  ruleAddress,
  zipCodeDrawer,
  ruleZipCode,
  city,
  ruleCity,
  phoneNumberLabelDrawer,
  rulePhoneNumber,
  ruleButton,
  changePasswordSuccessMessage,
  changePasswordErrorMessageIncorrect,
  currentPassword,
  ruleCurrentPassword,
  newPassword,
  ruleNewPassword,
  ruleButton,
  changeEmailSuccessMessage,
  changeEmailErrorMessage,
  newEmailLabel,
  ruleNewEmail,
  submitEmailButton
}
`
export const footerNoteQuery = `
select(_type == "footerNote") => {
  __i18n_lang,
  _id,
  _type,
  text
}
`

export const videoEntryQuery = `
select(_type == "videoEntry") => {
  __i18n_lang,
  _id,
  _type,
  title,
  video
}
`

export const videoQuery = `
select(_type == "video") => {
  __i18n_lang,
  _id,
  _type,
  icon,
  cardTitle,
  cardTitleDrawer,
  drawerContent,
  ${videoEntryQuery}
}
`
export const notFoundQuery = `
select(_type == "notFound") => {
  __i18n_lang,
  _id,
  _type,
  subTitle,
  backHomeButton
}
`

export const informationQuery = `
select(_type == "information") => {
  __i18n_lang,
  _id,
  _type,
  cardTitle,
  showCard,
  cardEntry[]-> {
    ${priceListQuery},
    ${videoQuery},
    ${confirmationQuery},
    ${quoteStepsQuery},
    ${documentsQuery},
    ${appointmentsQuery}
  }
}
`

const ctdCheckCategoryEntryQuery = `
ctdCheckCategoryEntry[] -> {
  __i18n_lang,
  _id,
  _type,
  typeName,
  title,
  id,
  ctdQuestionEntry[] -> {
    ...,
    ctdCheckRequestOptions-> {
      ...
    }
  }
}
`

const ctdInstallationInformationQuery = `
installationInformation-> {
__i18n_lang,
_id,
_type,
typeName,
title,
installationTime->,
complexity->
}
`

const ctdCheckBodyQuery = `
__i18n_lang,
_id,
_type,
cardTitle,
ctdCheckProductEntry[]-> {
  __i18n_lang,
  _id,
  _type,
  typeName,
  product-> {
    ...
  },
  ${ctdCheckCategoryEntryQuery},
  ctdApproveComment-> {
    ...
  },
},
ctdChecksTableTitle,
ctdChecksTableColumnName,
ctdChecksTableColumnCMPCode,
ctdChecksTableColumnProduct,
ctdChecksTableColumnWaiting,
ctdChecksTableColumnAction,
ctdChecksTableActions,
ctdRequestsTableTitle,
ctdRequestsTableColumnCMPCode,
ctdRequestsTableColumnSalesman,
ctdRequestsTableColumnQuotation,
ctdRequestsTableColumnCountry,
ctdRequestsTableColumnStatus,
ctdRequestsTableColumnProduct,
ctdRequestsTableColumnWaiting,
ctdRequestsTableColumnHandler,
ctdRequestsTableColumnAction,
ctdRequestsTableColumnIsLiveClosing,
ctdRequestsTableActions,
backButtonLabel,
submitCtdCheckButtonLabel,
updateCtdCheckButtonLabel,
errorMessageForCtdCheckSubmit,
successMessageForCtdCheckSubmit,
mandatoryQuestionsLabel,
liveClosingLabel,
ctdRequestDrawerTitle,
approveButtonLabel,
requestChangesButtonLabel,
handleRequestButtonLabel,
${ctdInstallationInformationQuery},
ctdPdfHeader,
ctdPdfApprovalComment
`

const ctdChecksQuery = `
ctdCheck-> {
 ${ctdCheckBodyQuery}
}
`

export const ctdChecksComponentQuery = `
*[_type == "ctdCheck" && __i18n_lang == $language][0] {
  ${ctdCheckBodyQuery}
}
`

export const buybackCardQuery = `
*[_type == "buyback" && __i18n_lang == $language][0] {
  __i18n_lang,
  _id,
  _type,
  cardName,
  buybackCodeLabel,
  buybackCode,
  buybackQuantityLabel,
  buybackAmountLabel,
  buybackCode[]-> {
   ...
  },
  submitButtonLabel,
  requiredMessage,
  buybackSuccessMessage,
  buybackErrorMessage
}
`

export const occasionCardQuery = `
*[_type == "occasion" && __i18n_lang == $language][0] {
  __i18n_lang,
  _id,
  _type,
  cardName,
  occasionCodeLabel,
  occasionCode,
  occasionAmountLabel,
  occasionCode[]-> {
   ...
  },
  submitButtonLabel,
  requiredMessage,
  occasionSuccessMessage,
  occasionErrorMessage
}
`

export const screenBySlugAndLocaleQuery = `
*[_type == "screen" && slugComponent->.slug.current == $slug && __i18n_lang == $language] [0]{
  _id,
  typeName,
  backgroundImage,
  ${personalInformationQuery},
  ${loginCardQuery},
  ${brochureQuery},
  ${maintenanceVisitQuery},
  ${workflowQuery},
  ${subventionQuery},
  ${whatExpectQuery},
  ${localAdvisorQuery},
  ${assistanceQuery},
  ${deliveryNoteQuery},
  ${agreedPaymentQuery},
  ${orderProcessQuery},
  ${productInformationQuery},
  ${contractInformationQuery},
  ${documentsQuery},
  ${appointmentsQuery},
  ${clientQuotationQuery},
  ${commercialAppointmentsQuery},
  ${commercialPersonalInformationQuery},
  ${confirmationQuery},
  ${customerOrderQuery},
  ${priceListQuery},
  ${quoteFinalResultsQuery},
  ${quoteStepsQuery},
  ${specialPromotionsQuery},
  ${newUserQuery},
  ${invoiceQuery},
  ${salesmanStatisticsQuery},
  ${resetPasswordQuery},
  ${seachUserQuery},
  ${signatureQuery},
  ${houseYearQuery},
  ${overviewPdfQuery},
  ${alertBannerQuery},
  ${quoteThroughFileQuery},
  ${visitQuery},
  ${videoQuery},
  ${informationQuery},
  ${ctdChecksQuery},
}
`