import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { RESET_PASSWORD_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useResetPasswordCmsQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(RESET_PASSWORD_QUERY, { client: cmsClient })

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    cardName: i18next.t(`card_name.${languages.language}`),
    title: i18next.t(`title.${languages.language}`),
    password: i18next.t(`password.${languages.language}`),
    confirmPassword: i18next.t(`confirm_password.${languages.language}`),
    requestButton: i18next.t(`request_button.${languages.language}`),
    rulePasswordsConsistency: i18next.t(`rule_passwords_consistency.${languages.language}`),
    rulePassword: i18next.t(`rule_password.${languages.language}`),
    rulePasswordConfirmation: i18next.t(`rule_password_confirmation.${languages.language}`),
    popupErrorMessage: i18next.t(`popup_error_message.${languages.language}`),
    popupSuccessMessage: i18next.t(`popup_success_message.${languages.language}`),
  }

  return {
    resetPasswordLabels: lables,
    loading,
    error
  }
}

export default useResetPasswordCmsQuery