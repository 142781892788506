// Mobilae 2.0
import React, { useRef, useEffect, useState } from 'react'
import sanityClient from '../../lib/client.js'
import { screenBySlugAndLocaleQuery } from '../../lib/queries/cms/queries'
import { useSelector } from 'react-redux'
import Spinner from '../../components/spinner/Spinner'
import useCardStyle from '../../hooks/useCardStyle'
import { Card, Input, InputNumber, Button, message, Upload, Image, Select, Drawer, Checkbox } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { fetchWrapper } from '../../helpers/fetchWrapper.js'
import {
  UPLOAD_CTD_FILE_MUTATION,
  CTD_REQUESTS_QUERY,
  CTD_UPDATE_ANSWERS_MUTATION,
  CTD_CREATE_REQUEST_MUTATION,
  CTD_ADD_COMMENT_MUTATION
} from '../../queries/back-end/queries.js'
import moment from 'moment'

const { TextArea } = Input
const { Option } = Select

const backendGraphqlEndpoint = process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT

const CtdCheck = (props) => {

  const {
    ctdCheckScreenUrl,
    orderScreenUrl,
    history
  } = props

  const inputRef = useRef(null)

  const [ctdCheckScreenData, setCtdCheckScreenData] = useState(null)
  const [ctdRequestInput, setCtdRequestInput] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [questionsAndAnswersComponent, setQuestionsAndAnswersComponent] = useState(null)
  const [mandatoryQuestions, setMandatoryQuestions] = useState([])
  const [ctdRequestsData, setCtdRequestsData] = useState(null)
  const [updateCtdRequestInput, setUpdateCtdRequestInput] = useState({})
  const [visible, setVisible] = useState(false)
  const [isLiveClosing, setIsLiveClosing] = useState(false)

  const languages = useSelector(state => state.languages)
  const logins = useSelector(state => state.logins)
  const quotations = useSelector(state => state.quotations)
  const products = useSelector(state => state.products)

  const hasErrors = history.location.search.includes('?ctd=')

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const hideQuestions = (hide, dropdownIndex, dropdownOptions) => {

    if (hide) {
      hide.forEach((question) => {

        if (question.salesmanLevel >= 1) {
          setMandatoryQuestions(mandatoryQuestions => mandatoryQuestions.filter((el) => {
            return !(el.categoryId === question.questionCategoryId && el.questionId === question.questionId)
          }))

          if (document.getElementById(`question${question.questionCategoryId}-${question.questionId}`)) {
            document.getElementById(`question${question.questionCategoryId}-${question.questionId}`).style.visibility = 'hidden'
            document.getElementById(`question${question.questionCategoryId}-${question.questionId}`).style.height = '0px'
            document.getElementById(`question${question.questionCategoryId}-${question.questionId}`).style.padding = '0px'
            document.getElementById(`question${question.questionCategoryId}-${question.questionId}`).style.marginBottom = '0px'
          }
        }

      })
    }

    dropdownOptions.forEach((element, index) => {

      if (index !== dropdownIndex && element?.hide !== undefined) {

        element.hide.forEach((el) => {

          if (el.salesmanLevel >= 1) {
            setMandatoryQuestions(mandatoryQuestions => [...mandatoryQuestions, {
              categoryId: el.questionCategoryId,
              questionId: el.questionId
            }])

            if (document.getElementById(`question${el.questionCategoryId}-${el.questionId}`)) {
              document.getElementById(`question${el.questionCategoryId}-${el.questionId}`).style.visibility = 'visible'
              document.getElementById(`question${el.questionCategoryId}-${el.questionId}`).style.height = 'initial'
              document.getElementById(`question${el.questionCategoryId}-${el.questionId}`).style.padding = '15px'
              document.getElementById(`question${el.questionCategoryId}-${el.questionId}`).style.marginBottom = '15px'
            }
          }

        })
      }
    })
  }

  const typeBuilder = (questionData, categoryId, divId, requestChanges = null) => {

    if (questionData?.isMandatoryForSalesman && questionData.salesmanLevel >= 1) {
      setMandatoryQuestions(mandatoryQuestions => [...mandatoryQuestions, {
        categoryId: categoryId,
        questionId: questionData?.id
      }])
    }

    let defaultData = requestChanges?.find((change) => {
      return change.categoryId === categoryId && change.questionId === questionData?.id
    }).answer

    switch (questionData.questionType) {
      case 'dropdown':
        return (
          <div >
            <Select
              onChange={(value) => onChangeDropdown(value, categoryId, questionData, divId)}
              style={{
                width: 200,
              }}
              placeholder={
                !requestChanges ? questionData.dropdownPlaceholder : defaultData
              }
            >
              {
                questionData?.dropdownOptions?.map((option, index) => {
                  return <Option
                    key={index}
                    value={option.option}
                  >
                    <div
                      onClick={() => hideQuestions(option.hide, index, questionData?.dropdownOptions)}
                    >
                      {
                        option.option
                      }
                    </div>
                  </Option>
                })
              }
            </Select>
          </div>
        )
      case 'number':
        return (
          <div>
            <InputNumber min={0} onChange={(value) => onChangeInputNumber(value, categoryId, questionData, divId)}
              defaultValue={
                !requestChanges ? 0 : defaultData
              }
            />
          </div>
        )
      case 'text':
        return (
          <div>
            <TextArea
              maxLength={100}
              onChange={(e) => onChangeTextArea(e, categoryId, questionData, divId)}
              placeholder={
                !requestChanges ? null : defaultData
              }
            />
          </div>
        )
      case 'file':
        return (
          <div>
            {
              requestChanges &&
              <Image
                width={200}
                src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}ctd/${ctdRequestsData.QuotationOverview.id}/${defaultData}`}
                style={{ margin: "2px 10px 2px 2px", cursor: 'pointer', paddingBottom: '10px' }}
                alt="Image"
              />
            }
            <div>
              <Upload
                beforeUpload={(file) => {
                  return file.type === ""
                }}
                onChange={(info) => handleUploadFile(info, categoryId, questionData, divId)}
                multiple={false}
                maxCount={1}
                listType="picture"
                showUploadList={
                  {
                    showRemoveIcon: false,
                    showPreviewIcon: false,
                    showDownloadIcon: false
                  }
                }
              >
                <Button>
                  <UploadOutlined />
                  {questionData.uploadFileButtonText}
                </Button>
              </Upload >
            </div>
          </div>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (Object.keys(ctdRequestInput).length !== 0) {
      setMandatoryQuestions(mandatoryQuestions.filter((e) => {
        return !ctdRequestInput?.answers?.some(answer => answer.categoryId === e.categoryId && answer.questionId === e.questionId)
      }))
    }
  }, [ctdRequestInput])

  // Filter for the correct CTD Request, after the CTD employee asked for changes
  useEffect(() => {
    // Backend Data
    if (hasErrors) {
      setIsLoading(true)
      const getCtdRequestsData = async () => {

        const variables = {
          id: history.location.search.split('=').pop(),
        }

        await fetchWrapper.post(backendGraphqlEndpoint, CTD_REQUESTS_QUERY, variables)
          .then(({ data }) => {

            const ctdRequestData = data?.ctdRequests[0]
            setCtdRequestsData(ctdRequestData)

            // Open the CTD employee comments drawer if comments exist
            if (ctdRequestData?.comments.length) {
              setVisible(true)
            }

            setIsLiveClosing(ctdRequestData?.isLiveClosing)
            setCtdRequestInput({
              requestId: history.location.search.split('=').pop(),
              answers: ctdRequestData?.answers,
              isLiveClosing: ctdRequestData?.isLiveClosing
            })

            setIsLoading(false)
          })
          .catch((error) => {
            setIsLoading(false)
          })
      }
      getCtdRequestsData()
    }
  }, [])

  useEffect(() => {
    // CMS Data
    const query = screenBySlugAndLocaleQuery
    const params = { slug: ctdCheckScreenUrl, language: languages.locale }

    setIsLoading(true)
    sanityClient.fetch(query, params)
      .then((data) => {

        setCtdCheckScreenData(data)

        setQuestionsAndAnswersComponent(data?.ctdCheck.ctdCheckProductEntry.find((product) => {
          return product.product.backendId === products.productId
        }).ctdCheckCategoryEntry?.map((entry, index) => {
          const categoryId = entry.id

          // First time that the Salesmen are doing a CTD request
          if (ctdRequestsData === null && !hasErrors) {
            return (
              <div key={index} className={'card border-0'} style={{ ...cardStyle, alignItems: 'center' }} >
                <Card
                  style={{ marginTop: 16, maxWidth: 'auto' }}
                  bordered={false}
                  title={<b>{categoryId} - {entry.title}</b>}
                >
                  {
                    entry.ctdQuestionEntry.map((questionData, index) => {
                      const divId = `question${questionData?.id}-${categoryId}`

                      return (
                        <>
                          {
                            questionData.salesmanLevel >= 1 && // logins.employeeName.level 
                            <div
                              id={divId}
                              style={{
                                padding: '15px',
                                border: `${questionData?.isMandatoryForSalesman ? '2px solid #7fbfb8' : '2px solid rgb(232, 232, 232)'}`,
                                borderRadius: '15px',
                                marginBottom: '15px'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: '#7fbfb8'
                                }}
                              >
                                {
                                  questionData?.isMandatoryForSalesman &&
                                  data?.ctdCheck.mandatoryQuestionsLabel
                                }
                              </div>
                              {
                                <div
                                  key={index}
                                >
                                  <p>
                                    {questionData.id} - {questionData.questionText}
                                  </p>
                                  <div>
                                    {
                                      typeBuilder(questionData, categoryId, divId)
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          }
                        </>
                      )
                    })
                  }
                </Card >
              </div >)
          } else {
            // Filter for the correct questions/answers, after the CTD employee asked for changes
            return (
              quotations.quotation.CTDRequest.changes.some(e => e.categoryId === categoryId) &&
              <div key={index} className={'card border-0'} style={{ ...cardStyle, alignItems: 'center' }} >
                <Card
                  key={index}
                  style={{ marginTop: 16, maxWidth: 'auto' }}
                  bordered={false}
                  title={<b>{categoryId} - {entry.title}</b>}
                >
                  {
                    entry.ctdQuestionEntry.filter((entry) => {
                      // filter for questions/answers with the same category id and question id. CMS Vs CTD request changes
                      return (quotations.quotation.CTDRequest.changes.some(e => e.categoryId === categoryId && e.questionId === entry.id))

                    }).map((questionData, index) => {
                      const divId = `question${questionData?.id}-${categoryId}`

                      return (
                        <div
                          key={index}
                          id={divId}
                          style={{
                            padding: '15px',
                            border: `${questionData?.isMandatoryForSalesman ? '2px solid #7fbfb8' : '2px solid rgb(232, 232, 232)'}`,
                            borderRadius: '15px',
                            marginBottom: '15px'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#7fbfb8'
                            }}
                          >
                            {
                              ctdRequestsData?.changes?.find((change) => {
                                return change.categoryId === categoryId && change.questionId === questionData.id
                              })?.comment
                            }
                          </div>
                          {
                            <div
                              key={index}
                            >
                              <p>
                                {questionData.id} - {questionData.questionText}
                              </p>
                              <div>
                                {typeBuilder(questionData, categoryId, divId, ctdRequestsData?.answers)}
                              </div>
                            </div>
                          }
                        </div>
                      )
                    })
                  }
                </Card >
              </div>

            )
          }

        }))

        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })


  }, [languages.locale, ctdRequestsData])

  useEffect(() => {

    if (Object.keys(updateCtdRequestInput).length !== 0) {

      let answers = []
      if (ctdRequestInput?.answers?.length > 0) {
        // Check if the current answer already exists and filter this answer out.
        // This is a overcomplicated solution to update answer already answered 
        ctdRequestInput.answers.filter((answer) => {
          return (answer.categoryId === updateCtdRequestInput?.answers?.categoryId && answer.questionId !== updateCtdRequestInput?.answers?.questionId) || answer.categoryId !== updateCtdRequestInput?.answers?.categoryId
        }).map((answer) => {
          answers.push(answer)
        })
      }

      if (updateCtdRequestInput?.answers) {
        answers.push(updateCtdRequestInput?.answers)
      }

      if (hasErrors) {
        setCtdRequestInput({
          requestId: history.location.search.split('=').pop(),
          answers: answers,
          isLiveClosing: isLiveClosing
        })
      } else {
        setCtdRequestInput({
          quotationId: updateCtdRequestInput.quotationId,
          answers: answers,
          isLiveClosing: isLiveClosing
        })
      }

    }

  }, [updateCtdRequestInput])

  const { cardStyle } = useCardStyle()

  const onChangeDropdown = (value, categoryId, questionData, divId) => {
    document.getElementById(divId).style.border = '2px solid rgb(232, 232, 232)'
    document.getElementById(divId).style.borderRadius = '15px'

    setUpdateCtdRequestInput({
      quotationId: quotations.quotation.id,
      answers: {
        questionId: questionData.id,
        categoryId: categoryId,
        dataType: questionData.questionType,
        answer: value,
      }
    })
  }

  const onChangeTextArea = (e, categoryId, questionData, divId) => {

    document.getElementById(divId).style.border = '2px solid rgb(232, 232, 232)'
    document.getElementById(divId).style.borderRadius = '15px'

    setUpdateCtdRequestInput({
      quotationId: quotations.quotation.id,
      answers: {
        questionId: questionData.id,
        categoryId: categoryId,
        dataType: questionData.questionType,
        answer: e.target.value,
      }
    })

  }

  const onReplyToCTDEmployee = () => {

    const variables = {
      requestId: ctdRequestsData?.id,
      comment: inputRef?.current?.resizableTextArea?.props?.value,
    }

    setIsLoading(true)
    fetchWrapper
      .post(backendGraphqlEndpoint, CTD_ADD_COMMENT_MUTATION, variables)
      .then(({ data, errors }) => {
        setIsLoading(false)
        if (errors) {
          message.error(`👎 ${errors[0].message}`, [10])
        } else {
          message.success("", [10])
          setCtdRequestsData(data.ctdAddComment)
          showDrawer()
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const onChangeInputNumber = (value, categoryId, questionData, divId) => {

    document.getElementById(divId).style.border = '2px solid rgb(232, 232, 232)'
    document.getElementById(divId).style.borderRadius = '15px'

    setUpdateCtdRequestInput({
      quotationId: quotations.quotation.id,
      answers: {
        questionId: questionData.id,
        categoryId: categoryId,
        dataType: questionData.questionType,
        answer: value,
      }
    })
  }

  const handleUploadFile = (info, categoryId, questionData, divId) => {

    const existingFileName = ctdRequestsData?.answers?.find((change) => {
      return change.categoryId === categoryId && change.questionId === questionData.id
    }).answer

    if (info.file.status !== 'uploading' && info.file.type !== "") {
      let variables = ''

      setIsLoading(true)

      if (existingFileName === undefined) {
        variables = `{"file":null,"quotationId":${quotations.quotation.id}}`
      } else {
        variables = `{"file":null,"quotationId":${quotations.quotation.id},"existingFileName":"${existingFileName}"}`
      }


      fetchWrapper.postAsset(backendGraphqlEndpoint, UPLOAD_CTD_FILE_MUTATION, variables, info.fileList[0].originFileObj)
        .then(({ data }) => {
          if (data.uploadCTDFile.success === true) {
            document.getElementById(divId).style.border = '2px solid rgb(232, 232, 232)'
            document.getElementById(divId).style.borderRadius = '15px'

            message.success(`${info.file.name} ${questionData.uploadFileSuccessMessage}`)

            setUpdateCtdRequestInput({
              quotationId: quotations.quotation.id,
              answers: {
                questionId: questionData.id,
                categoryId: categoryId,
                dataType: questionData.questionType,
                answer: data.uploadCTDFile.fileName,
              }
            })

            setIsLoading(false)
          } else {
            setIsLoading(false)
            message.error(`${info.file.name} ${questionData.uploadFileErrorMessage}`)
          }

        })
        .catch((error) => {
          setIsLoading(false)
          message.error(`${info.file.name} ${error}`)
        })

    } else {
      message.error(`Type: ${info.file.type} not valid!`)

    }
  }

  const ctdUpdateAnswers = () => {
    setIsLoading(true)

    // We are sending all the answers back. We only need to send the updated ones
    fetchWrapper.post(backendGraphqlEndpoint, CTD_UPDATE_ANSWERS_MUTATION, ctdRequestInput)
      .then(({ data, errors }) => {
        setIsLoading(false)
        if (!errors) {
          history.push({
            pathname: `${orderScreenUrl}`,
            search: '?ctd=done'
          })
        } else {
          message.error(ctdCheckScreenData.ctdCheck.errorMessageForCtdCheckSubmit)
        }
      })
      .catch((error) => {
        message.error(ctdCheckScreenData.ctdCheck.errorMessageForCtdCheckSubmit)
        setIsLoading(false)
      })
  }

  const submitCtdChecks = () => {
    setIsLoading(true)
    fetchWrapper.post(backendGraphqlEndpoint, CTD_CREATE_REQUEST_MUTATION, ctdRequestInput)
      .then(({ data }) => {
        message.success(ctdCheckScreenData.ctdCheck.successMessageForCtdCheckSubmit)
        setIsLoading(false)
        history.push({
          pathname: `${orderScreenUrl}`,
          search: '?ctd=done'
        })

      })
      .catch((error) => {
        message.error(ctdCheckScreenData.ctdCheck.errorMessageForCtdCheckSubmit)
        setIsLoading(false)
      })

  }

  return (
    <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`}>
      {
        ctdCheckScreenData &&
        <div className="w-100 d-flex justify-content-center">

          <div>
            <div className="w-100 d-flex justify-content-center mb-2">
              <Checkbox defaultChecked={ctdRequestsData?.isLiveClosing}
                onChange={(e) => { setIsLiveClosing(e.target.checked) }}
                style={{
                  color: "white",
                  fontWeight: "400",
                  fontSize: "1rem"
                }}>{ctdCheckScreenData?.ctdCheck.liveClosingLabel}</Checkbox>
            </div>

            <div className="w-100 d-flex justify-content-center">
              <div className="btn btn-orange" style={{ width: '275px' }} onClick={() => {
                history.push({
                  pathname: orderScreenUrl
                })
              }} >
                {ctdCheckScreenData?.ctdCheck.backButtonLabel}
              </div>
              {
                !hasErrors &&
                <div className={`btn btn-orange ${mandatoryQuestions.length > 0 ? 'disable' : ''}`} style={
                  {
                    width: '275px',
                  }
                } onClick={submitCtdChecks} >
                  {ctdCheckScreenData?.ctdCheck.submitCtdCheckButtonLabel}
                </div>
              }
              {
                hasErrors &&
                <div className={`btn btn-orange ${mandatoryQuestions.length > 0 ? 'disable' : ''}`} style={
                  {
                    width: '275px',
                  }
                } onClick={ctdUpdateAnswers} >
                  {ctdCheckScreenData?.ctdCheck.updateCtdCheckButtonLabel}
                </div>
              }
              {
                ctdRequestsData?.comments.length &&
                <div className="btn btn-orange" style={{ width: '275px' }} onClick={showDrawer} >
                  CTD Remarks
                </div>
              }
            </div>
          </div>
        </div>
      }
      {
        isLoading &&
        <Spinner />
      }
      {
        questionsAndAnswersComponent &&
        questionsAndAnswersComponent
      }
      <Drawer
        title="CTD Remarks"
        placement="right" jan
        onClose={onClose}
        open={visible}
      >
        <div style={{ borderBottom: "2px solid rgb(232, 232, 232)", whiteSpace: "pre-wrap" }}>
          <div>
            {
              // show CTD Comments
              ctdRequestsData?.comments.length > 0 &&
              ctdRequestsData?.comments.map((element, index) => {
                return <p key={`ctd-comments-${index}`}>
                  {`[${element.date} ${element.employeeName}]`} <b>{element.comment}</b> [End]
                </p>
              })
            }
          </div>

        </div>

        <div
          style={{
            marginTop: "10px",
          }}
        >
          <TextArea
            ref={inputRef}
            style={{
              marginBottom: "10px",
            }}
            rows={4}
            placeholder={`[${moment().format().replace('T', ' ').split('+')[0]} ${logins.employeeName.firstName} ${logins.employeeName.lastName}] `}
          />
          <div
            className="btn btn-dark-blue w-auto m-auto"
            onClick={onReplyToCTDEmployee}
          >
            {"Submit comment (in test)"}
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default CtdCheck