const postAsset = async (url, query = {}, variables = {}, file) => {
  const formdata = new FormData();
  formdata.append("operations", `{ "query": "${query}", "variables": ${variables} }`)
  formdata.append("map", `{ "0": ["variables.file"] }`)
  formdata.append("0", file)

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    body: formdata,
    redirect: 'follow'
  }

  return await fetch(url, requestOptions).then(handleResponse)
}

const post = (url, query = {}, variables = {}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      query: query,
      variables: variables
    })
  }

  // nProgress.start()

  return fetch(url, requestOptions).then(handleResponse)
}

const get = (url) => {
  const requestOptions = {
    method: 'GET'
  }

  return fetch(url, requestOptions).then(handleResponse)
}

const handleResponse = (response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error)
    }

    return data
  }).finally(() => {
    // nProgress.done()
  })
}


export const fetchWrapper = {
  post,
  get,
  postAsset
}