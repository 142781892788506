import * as actionTypes from '../actions/actionType';

export default (state = { provisionCode: '', provisionVisibility: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_PROVISION_CODE:
      return { ...state, provisionCode: action.provisionCode }
    case actionTypes.SET_PROVISION_VISIBILITY:
      return { ...state, provisionVisibility: action.provisionVisibility }
    case actionTypes.DELETE_PROVISION_CODE:
      return { provisionCode: '', provisionVisibility: false }
    default:
      return state;
  }
};