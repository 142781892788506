import React from 'react'
import { Drawer } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux'

const QuoteAnswerCardDrawer = (props) => {

  const {
    closeDrawer,
    isDrawerVisible,
    data
  } = props

  const logins = useSelector(state => state.logins)

  return (
    <Drawer
      destroyOnClose={true}
      height="auto"
      width={!logins.isMobile ? "50%" : "100%"}
      placement='right'
      closable={true}
      onClose={closeDrawer}
      visible={isDrawerVisible}

    >
      <div className="container">
        <div>
          {ReactHtmlParser(data.infoMsg)}
        </div>
      </div>
    </Drawer>
  )
}

export default QuoteAnswerCardDrawer