import * as actionTypes from '../actions/actionType';

export default (state = { username: '', isAuthenticated: false, accessType: '', employeeName: { firstName: '', lastName: '', role: '', rating: '' }, isMobile: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_USERNAME:
      return { ...state, username: action.username }
    case actionTypes.IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.isAuthenticated }
    case actionTypes.SET_ACCESS_TYPE:
      return { ...state, accessType: action.accessType }
    case actionTypes.SET_EMPLOYEE:
      return { ...state, employeeName: action.employeeName, role: action.role, rating: action.rating }
    case actionTypes.IS_MOBILE:
      return { ...state, isMobile: action.isMobile }
    default:
      return state;
  }
};