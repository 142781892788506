import React, { useState, Suspense, useEffect } from 'react'
import { Modal, Alert } from 'antd'
import './QuotationOptions.scss'
import { useSelector, useDispatch } from 'react-redux'
import { SAVE_COLOR } from '../../queries/back-end/queries'
import { SAVE_EXTRA_SERVICES } from '../../queries/back-end/queries'
import * as quotationAction from '../../redux/actions/quotationAction'
import * as provisionAction from '../../redux/actions/provisionAction'
import useLogout from '../../hooks/useLogout'
import useCardStyle from '../../hooks/useCardStyle'
import useQuotationStepsQuery from '../../hooks/cms/useQuotationStepsQuery'
import useSaveColorMutation from '../../hooks/backend/useSaveColorMutation'
import useSaveExtraServicesMutation from '../../hooks/backend/useSaveExtraServicesMutation'
import useUpdateQuotationPriceMutation from '../../hooks/backend/useUpdateQuotationPriceMutation'
import useRegisterWizardKeyMutation from '../../hooks/backend/useRegisterWizardKeyMutation'
import withHandleQuery from '../../hoc/withHandleQuery'
import { CheckOutlined } from '@ant-design/icons'

const QuotationProduct = React.lazy(() => import('../../components/cards/quotation-options/QuotationProduct'));
const QuotationColors = React.lazy(() => import('../../components/cards/quotation-options/QuotationColors'));
const QuotationExtraServicesOptions = React.lazy(() => import('../../components/cards/quotation-options/QuotationExtraServicesOptions'));
const QuotationAdditionalExtraServices = React.lazy(() => import('../../components/cards/quotation-options/QuotationAdditionalExtraServices'))
const UpdateQuotationPrice = React.lazy(() => import('../../components/cards/quotation-options/UpdateQuotationPrice'));

const QuotationOptions = (props) => {

  const {
    dataHoc,
    orderScreenUrl,
    history
  } = props

  const [orderCode, setOrderCode] = useState([])
  const [colorRailId, setColorRailId] = useState('')
  const [colorSeatId, setColorSeatId] = useState('')

  const [fixationOption, setFixationOption] = useState([])
  const [railOptionId, setRailOptionId] = useState('')

  const [quotationPrice, setQuotationPrice] = useState(1)

  const dispatch = useDispatch()
  const setQuotation = (quotation) => dispatch(quotationAction.setQuotation(quotation))
  const setProvision = (value) => dispatch(provisionAction.setProvision(value))
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { logout } = useLogout()

  const quotations = useSelector(state => state.quotations)
  const logins = useSelector(state => state.logins)
  const filters = useSelector(state => state.filters)
  const products = useSelector(state => state.products)

  const { cardStyle } = useCardStyle()

  const quotationStepsLabels = dataHoc[0] !== undefined && dataHoc[0].quotationStepsLabels
  const saveColorMutation = dataHoc[1] !== undefined && dataHoc[1].saveColorMutation
  const saveExtraServicesMutation = dataHoc[2] !== undefined && dataHoc[2].saveExtraServicesMutation
  const updateQuotationPriceMutation = dataHoc[3] !== undefined && dataHoc[3].updateQuotationPriceMutation
  const registerWizardKeyMutation = dataHoc[4] !== undefined && dataHoc[4].registerWizardKeyMutation

  const preOrderColorsLabels = {
    cardColorsTitle: quotationStepsLabels.lables.cardColorsTitle,
    cardColorsOptionOne: quotationStepsLabels.lables.cardColorsOptionOne,
    cardColorsOptionTwo: quotationStepsLabels.lables.cardColorsOptionTwo
  }

  const preOrderExtraServicesOtionsLabels = {
    cardExtraServicesTitle: quotationStepsLabels.lables.cardExtraServicesTitle,
    cardExtraServicesOptionOne: quotationStepsLabels.lables.cardExtraServicesOptionOne,
    cardExtraServicesOptionTwo: quotationStepsLabels.lables.cardExtraServicesOptionTwo
  }

  const preOrderAdditionalExtraServicesLabels = {
    cardAdditionalCodesTitle: quotationStepsLabels.lables.cardAdditionalCodesTitle,
    cardAdditionalCodesTableDescription: quotationStepsLabels.lables.cardAdditionalCodesTableDescription,
    cardAdditionalCodesTableQuantity: quotationStepsLabels.lables.cardAdditionalCodesTableQuantity,
    cardAdditionalCodesTablePrice: quotationStepsLabels.lables.cardAdditionalCodesTablePrice,
    cardAdditionalCodesSelectButton: quotationStepsLabels.lables.cardAdditionalCodesSelectButton,
    cardAdditionalCodesDeleteCodesButton: quotationStepsLabels.lables.cardAdditionalCodesDeleteCodesButton,
    cardAdditionalCodesPopupTitle: quotationStepsLabels.lables.cardAdditionalCodesPopupTitle,
    cardAdditionalCodesPopupOption: quotationStepsLabels.lables.cardAdditionalCodesPopupOption,
    cardAdditionalCodesPopupDropdownText: quotationStepsLabels.lables.cardAdditionalCodesPopupDropdownText,
    cardAdditionalCodesPopupButton: quotationStepsLabels.lables.cardAdditionalCodesPopupButton,
    cardAdditionalCodesPopupErrorMessage: quotationStepsLabels.lables.cardAdditionalCodesPopupErrorMessage
  }

  const updateQuotationPrice = async () => {

    await updateQuotationPriceMutation({
      variables: {
        quotationId: quotations.quotation.id ? quotations.quotation.id : quotations.quotation.id,
        price: quotationPrice
      }
    }).then((result) => {
      setQuotation(result.data.updateQuotationPrice)
      setProvision(result.data.updateQuotationPrice.provisionCode)
    }).catch(() => {
      logout()
    })

  }

  const saveOffer = async (needsCompositeKey = false) => {

    let extraServices = []
    let compositeKeyId = 0

    if (orderCode.length > 0) {
      orderCode.map((service) => {
        return extraServices.push({
          extraServiceId: parseInt(service.serviceId),
          extraServiceQuantity: parseInt(service.quantity)
        })
      })
    }

    // Rail option
    if (railOptionId !== "") {
      extraServices.push(
        {
          extraServiceId: railOptionId,
          extraServiceQuantity: 1
        }
      )
    }

    // Fixation Option
    if (fixationOption.length > 0) {
      fixationOption.map((option) => {
        extraServices.push({
          extraServiceId: option.id,
          extraServiceQuantity: option.quantity
        })
        return null
      })
    }

    if ((colorRailId !== '' && colorSeatId !== '' && !filters.filterOptions.includes(6)) || (colorRailId !== '' && colorSeatId !== '' && filters.filterOptions.includes(6) && quotationPrice > 0) || (products.productId === 24 && railOptionId !== "")) {

      if (fixationOption.length > 0 || railOptionId !== '' || (extraServices.length > 0)) {
        await saveExtraServicesMutation({
          mutation: SAVE_EXTRA_SERVICES,
          variables: {
            quotationId: quotations.quotation.id,
            extraServiceInput: extraServices
          }
        }).then((result) => {
          setQuotation(result.data.saveExtraServices)
          setProvision(result.data.saveExtraServices.provisionCode)
        }).catch((error) => {
          logout()
        })
      }

      if (products.productId !== 24) {
        await saveColorMutation({
          mutation: SAVE_COLOR,
          variables: { quotationId: quotations.quotation.id, colorsIds: [colorRailId, colorSeatId] }
        }).then((result) => {
          setQuotation(result.data.saveColor)
          compositeKeyId = result.data.saveColor.WizardCompositeKey.id
          setProvision(result.data.saveColor.provisionCode)
        }).catch(() => {
          logout()
        })
      }

      if (filters.filterOptions.includes(6) && quotationPrice > 0) {
        await updateQuotationPrice()
      }

      if (products.productId !== 24 && needsCompositeKey) {
        await registerWizardKeyMutation({
          variables: { quotationId: quotations.quotation.id, colorsIds: [colorRailId, colorSeatId] }
        }).then((result) => {
          Modal.success({
            content: result.data.registerWizardKey,
            okText: <CheckOutlined className="btn d-flex" />,
            onOk: () => {
              history.push({
                pathname: '/'
              })
            }
          })
        }).catch(() => {
          logout()
        })
      }

      if (!needsCompositeKey) {
        history.push({
          pathname: orderScreenUrl
        })
      }

    } else {
      Modal.error({
        title: quotationStepsLabels.lables.finaliseProcessErrorMessageTitle,
        content: quotationStepsLabels.lables.finaliseProcessErrorMessageContent,
        okText: <CheckOutlined className="btn d-flex" />,
        // onOk: () => window.location.reload()
      })
    }
  }

  const date = new Date()

  return (
    <Suspense fallback={null}>
      {
        filters.filterOptions.includes(6) &&
        <Alert message={`PC: ${quotations?.quotation?.quotationPrice}.${date.toLocaleDateString('de-DE')}`} type="success" closable />
      }


      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`}>
        <div className="w-100 d-flex justify-content-center">
          <div className="btn btn-orange" style={{ width: '275px' }} data-cy="save-quotation" onClick={() => saveOffer()} >{quotationStepsLabels.lables.finaliseButton}</div>
          {
            products.productId !== 24 &&
            <div className="btn btn-orange" style={{ width: '275px' }} data-cy="save-quotation" onClick={() => saveOffer(true)} >Create Partnumber</div>
          }
        </div>
        <div className="card border-0" style={cardStyle}>
          {
            filters.filterOptions.includes(6) &&
            <UpdateQuotationPrice
              quotationStepsLabels={quotationStepsLabels}
              quotationPrice={quotationPrice}
              setQuotationPrice={setQuotationPrice}
            />
          }

          <QuotationProduct />
        </div>

        {
          products.productId !== 24 &&
          <div className="card border-0" style={cardStyle}>
            <QuotationColors
              data={preOrderColorsLabels}
              setColorRailId={setColorRailId}
              setColorSeatId={setColorSeatId}
            />
          </div>
        }
        {
          quotations.quotation?.ExtraServiceResponse?.length > 0 &&
          <div className="card border-0" style={cardStyle}>
            <QuotationExtraServicesOptions
              data={preOrderExtraServicesOtionsLabels}
              setFixationOption={setFixationOption}
              setRailOptionId={setRailOptionId}
            />
          </div>
        }
        {
          quotations.quotation?.ExtraServiceResponse?.length > 0 &&
          <div className="card border-0" style={cardStyle}>
            <QuotationAdditionalExtraServices
              data={preOrderAdditionalExtraServicesLabels}
              setOrderCode={setOrderCode}
              orderCode={orderCode}
            />
          </div>
        }
      </div>
    </Suspense>
  )
}

export default withHandleQuery([useQuotationStepsQuery, useSaveColorMutation, useSaveExtraServicesMutation, useUpdateQuotationPriceMutation, useRegisterWizardKeyMutation])(React.memo(QuotationOptions))