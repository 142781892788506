import React from 'react'
import { Steps } from 'antd';

const { Step } = Steps;

const QuoteStatusBar = (props) => {

  const {
    statusBar,
    status,
    goToStep,
    data
  } = props

  let statusBarStatus = new Array(statusBar.length).fill('wait')

  data.map((e) => {
    return statusBarStatus[e[1]] = 'finish'
  })

  const handleGoToStep = (stepNumber) => {
    goToStep(stepNumber)
  }

  return (
    <Steps
      direction="vertical"
      current={status}
    >
      {statusBar.map((e, index) => {
        return (
          <Step
            style={{ cursor: 'pointer' }}
            key={index}
            title={e.title}
            description={e.description}
            status={status === index ? 'process' : statusBarStatus[index]}
            onClick={() => handleGoToStep(index)} />
        )
      })}
    </Steps>
  )
}

export default React.memo(QuoteStatusBar)
