import React, { useState } from 'react'
import { Card, Modal } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import QuoteAnswerCardDrawer from '../../drawers/QuoteAnswerCardDrawer';
import { useSelector } from 'react-redux'
import useI18next from '../../../hooks/useI18next'
import { CheckOutlined } from '@ant-design/icons'

const QuoteAnswerCard = (props) => {

  const {
    answers,
    currentSelection,
    stepNumber,
    result
  } = props

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [infoMsg, setInfoMsg] = useState('');

  const languages = useSelector(state => state.languages)

  const showDrawer = (infoMsg, test) => {
    setDrawerVisible(true)
    setInfoMsg({ infoMsg: infoMsg, test: test })
  };

  const closeDrawer = () => {
    setDrawerVisible(false)
  };

  const alertMessage = (message) => {
    Modal.info({
      content: message,
      okText: <CheckOutlined className="btn d-flex" />,
    });
  }

  return (
    <div>
      <Card
        style={{ marginTop: 16 }}
        bodyStyle={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}
      >
        {
          answers?.map((e, index) => {

            const internationalizationJson = e.internationalization_json_option
            const I18next = () => useI18next(internationalizationJson)
            const { i18next } = I18next()
            const infoMsg = i18next.t(`info_msg.${languages.language}`)
            const highLight = currentSelection.find(element => element[0].option_url === e.option_url) !== undefined
            const message = i18next.t(`alert_msg.${languages.language}`)

            if (e.alert === true) {
              return (
                <div key={index} style={{ paddingTop: (e.info ? '0px' : '22px') }}>
                  {e.info &&
                    <InfoCircleTwoTone
                      className="mb-2"
                      onClick={() => showDrawer(infoMsg, e.option_url)}
                      style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
                    />
                  }
                  <div
                    style={{ border: (highLight ? '5px solid #4BA59B' : '0px'), borderRadius: (highLight ? '15px' : '0px') }}
                  >
                    <img
                      className='m-2 img-hover'
                      height={100}
                      width={100}
                      src={e.option_url}
                      alt={`Answer ${index}`}
                      onClick={() => alertMessage(message)}
                    />
                  </div>
                </div>)
            } else {
              return (
                <div key={index} style={{ paddingTop: (e.info ? '0px' : '22px') }}>
                  {e.info &&
                    <InfoCircleTwoTone
                      className="mb-2"
                      onClick={() => showDrawer(infoMsg, e.option_url)}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    />
                  }
                  <div
                    style={{ border: (highLight ? '5px solid #4BA59B' : '0px'), borderRadius: (highLight ? '15px' : '0px') }}
                  >
                    <img
                      className='m-2 img-hover'
                      data-cy={`quotation-answer-${index}-step-${stepNumber}`}
                      height={100}
                      width={100}
                      src={e.option_url}
                      alt={`Answer ${index}`}
                      onClick={() => result(e)}
                    />
                  </div>
                </div>
              )
            }
          })
        }
      </Card>
      <QuoteAnswerCardDrawer
        data={infoMsg}
        isDrawerVisible={isDrawerVisible}
        closeDrawer={closeDrawer}
      />
    </div>
  )
}

export default React.memo(QuoteAnswerCard)