import React from 'react'
import { Card } from 'antd';
import useI18next from '../../hooks/useI18next'
import { useSelector, useDispatch } from 'react-redux'
import useProductsQuery from '../../hooks/cms/useProductsQuery'
import withHandleQuery from '../../hoc/withHandleQuery'
import * as productAction from '../../redux/actions/productAction'

const ProductSelection = (props) => {

  const {
    dataHoc,
  } = props

  const languages = useSelector(state => state.languages)
  const logins = useSelector(state => state.logins)
  const dispatch = useDispatch()
  const setProduct = (name, id) => dispatch(productAction.setProduct(name, id))

  const handleProductSelection = (productName, productId) => {
    setProduct(productName, productId)
  }
  const cardStyle = !logins.isMobile ? { background: 'transparent', alignItems: 'center' } : { background: 'transparent', minWidth: 'fit-content', alignItems: 'center', display: 'flex', justifyContent: 'center' }

  const productsQueryLabels = dataHoc[0] !== undefined && dataHoc[0].productsQueryLabels
  return (
    <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} style={{ justifyContent: 'center' }}>

      {productsQueryLabels.products.map((product, index) => {

        if (product.show_product) {
          const internationalizationJson = product.internationalization_json
          const I18next = () => useI18next(internationalizationJson)
          const { i18next } = I18next()

          return (
            <div className="m-4" data-cy={`quotation-product-${index}`} style={cardStyle} key={i18next.t(`name.${languages.language}`)} onClick={() => handleProductSelection(product.name, product.back_end_id)}>
              <Card
                hoverable
                bordered={false}
                style={{ width: 240 }}
                cover={<img alt={i18next.t(`name.${languages.language}`)} src={product.image} />}
                title={i18next.t(`name.${languages.language}`)}
                headStyle={{ textAlign: 'center', justifyContent: 'center', display: 'flex', height: '80px' }}
                bodyStyle={{ padding: 0 }}
              >
              </Card>
            </div>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default withHandleQuery([useProductsQuery])(React.memo(ProductSelection))