import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Card, Switch, Select, Button, Upload, Divider, message } from 'antd'
import useI18next from '../../../hooks/useI18next'
import * as filterAction from '../../../redux/actions/quotation/filterAction'
import * as quotationAction from '../../../redux/actions/quotationAction'
import { isBrowser } from "react-device-detect"
import axios from 'axios';
import Spinner from '../../spinner/Spinner'
// import useLogout from '../../../hooks/useLogout'
import useQuoteThroughFileQuery from '../../../hooks/cms/useQuoteThroughFileQuery'
import withHandleQuery from '../../../hoc/withHandleQuery'
import { InboxOutlined, SearchOutlined } from '@ant-design/icons'

const { Dragger } = Upload;

const SelectProductBanner = React.lazy(() => import('../../banners/SelectProductBanner'))
const { Option } = Select;

const QuoteThroughFile = (props) => {

  const [csvFile, setCsvFile] = useState([])
  const [imageFile, setImageFile] = useState([])
  const [productCategory, setProductCategory] = useState('')
  const [loading, setLoading] = useState(false)
  const [borderAlertCategory, setBorderAlertCategory] = useState({})
  const [borderAlertCSVFile, setBorderAlertCSVFile] = useState({})
  const [borderAlertImage, setBorderAlertImage] = useState({})

  const {
    dataHoc,
    quotationStepsLabels,
    productName,
    quotationStepsScreenUrl,
    preOrderScreennUrl,
    history
  } = props

  useEffect(() => {
    deleteQuotation()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCmsData = (json, tag) => {
    const I18next = () => useI18next(json)
    const { i18next } = I18next()
    return i18next.t(`${tag}.${languages.language}`)
  }

  const logins = useSelector(state => state.logins)
  const languages = useSelector(state => state.languages)
  const filters = useSelector(state => state.filters)
  const dispatch = useDispatch()
  const setFilterOptions = (option) => dispatch(filterAction.setFilterOptions(option));
  const deleteQuotation = () => dispatch(quotationAction.deleteQuotation())
  const setQuotation = (quotation) => dispatch(quotationAction.setQuotation(quotation))
  // const { logout } = useLogout()

  const quoteThroughFileQueryLabel = dataHoc[0] !== undefined && dataHoc[0].quoteThroughFileQueryLabel

  const {
    cardName,
    filterSection,
    categorySection,
    categoryOptionOne,
    categoryOptionTwo,
    categoryOptionThree,
    categoryOptionFour,
    csvFileSection,
    uploadCsvFileTitle,
    uploadCsvFileDescription,
    uploadCsvFileSuccessMessage,
    uploadCsvFileErrorMessage,
    imageSection,
    uploadImageFileTitle,
    uploadImageFileDescription,
    uploadImageFileSuccessMessage,
    uploadImageFileErrorMessage
  } = quoteThroughFileQueryLabel

  const filterLabels = {
    filterIcons: quotationStepsLabels.products[0].filter.filter_icons
  }

  const onChange = (e, filterNumber) => {
    if (e === true) {
      setFilterOptions([...filters.filterOptions, filterNumber])
    } else {
      setFilterOptions([...filters.filterOptions].filter((e) => {
        return e !== filterNumber
      }))
    }
  }

  const handleRemoveCsvFile = () => {
    setCsvFile([])
  }

  const handleUploadCsvFile = ({ file }) => {
    if (file.status !== 'removed') {
      if (file.type.includes('csv') || file.type.includes('vnd.ms-excel')) {
        setCsvFile([file])
        setBorderAlertCSVFile({})
        message.success(uploadCsvFileSuccessMessage)
      } else {
        message.error(`${file.name} ${uploadCsvFileErrorMessage}`)
      }
    }
  }

  const handleRemoveImageFile = () => {
    setImageFile([])
  }

  const handleUploadImageFile = ({ file }) => {
    if (file.status !== 'removed') {
      if (file.type.includes('png') || file.type.includes('jpeg') || file.type.includes('jpg')) {
        setImageFile([file])
        setBorderAlertImage({})
        message.success(uploadImageFileSuccessMessage)
      } else {
        message.error(`${file.name} ${uploadImageFileErrorMessage}`)
      }
    }
  }

  const outCardStyle = !logins.isMobile ? { background: 'transparent', flexFlow: 'row wrap', justifyContent: 'center', height: 'fit-content' } : { background: 'transparent', minWidth: 'auto' }

  const cardStyle = !logins.isMobile ? { margin: 16, height: 'fit-content', minWidth: '350px' } : { margin: 16, height: 'fit-content' }

  const goToQuoteResults = async (salesmanPriceFactor) => {


    if (productCategory !== '' && csvFile.length > 0 && imageFile.length > 0) {

      setLoading(true)

      let formData = new FormData()
      formData.append('operations', `{"query": "mutation createSaninetQuotation($model: String!, $csv: File!, $image: File!, $salesmanPriceFactor: Float) {createSaninetQuotation(input: {model: $model, csv: $csv, image: $image, salesmanPriceFactor: $salesmanPriceFactor}) {id,missingSaninetCodes, Status {id, status},quotationPrice,discount,quantity,WizardCompositeKey {id,compositeKey,Model {id,code,name,picture,isActive,ProductCategory {id,category,code}},WizardCompositeKeyArticleCodes {id,quantity,ArticleCode {id,code, descriptions {language,value}}}},ExtraServiceResponse {id,quantity,code,price,ranks{type,rank}properties{type,property}picture,descriptions {language,value}},ColorResponse {id,code,price,type,pictureUrl},assets {assetUrl,assetDescription {language,value}},modelSpecs{name{value,language}value,unit,description{value,language}},quotationGroupId,quotationGroupRank,Employee {firstName,lastName,resourceId,locale,city,country,Roles {id,role}},isReady}}","variables": {"model": "${productCategory}", "csv": null, "image": null, "salesmanPriceFactor": ${salesmanPriceFactor}}}`)
      formData.append('map', '{"0":["variables.csv"], "1": ["variables.image"]}')
      formData.append(0, csvFile[0])
      formData.append(1, imageFile[0])

      await axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      }).then((result) => {


        if (result.data.data.createSaninetQuotation) {
          setQuotation(result.data.data.createSaninetQuotation)
          if (result.data.data.createSaninetQuotation.missingSaninetCodes.length > 0) {
            alert(JSON.stringify(result.data.data.createSaninetQuotation.missingSaninetCodes))
          }
        } else {
          // logout()
          // console.log(result.data.errors[0].message)
          alert(JSON.stringify(result))
        }

      }).catch((error) => {
        alert(JSON.stringify(error))
        // logout()
      })
      setLoading(false)

      history.push({
        pathname: preOrderScreennUrl,
      })
    } else {
      productCategory === '' ? setBorderAlertCategory({ animation: 'blinker 2s linear infinite', color: 'red' }) : setBorderAlertCategory({})
      csvFile.length === 0 ? setBorderAlertCSVFile({ animation: 'blinker 2s linear infinite', color: 'red' }) : setBorderAlertCSVFile({})
      imageFile.length === 0 ? setBorderAlertImage({ animation: 'blinker 2s linear infinite', color: 'red' }) : setBorderAlertImage({})
    }

  }

  const onCategoryChange = (category) => {
    setProductCategory(category)
    setBorderAlertCategory({})
  }

  // const alertBlinker = borderAlert !== '' ? 'blinker 1s linear infinite' : ''

  return (
    <React.Fragment>
      {
        loading && <Spinner />
      }
      {
        logins.isAuthenticated &&
        <SelectProductBanner
          productName={productName}
          quotationStepsScreenUrl={quotationStepsScreenUrl}
        />
      }
      <div>
        <div className="card border-0" style={outCardStyle}>
          <Card
            style={cardStyle}
            title={<b>{cardName}</b>}
            bordered={false}
          >
            <div className="mt-3">

              <Divider>{filterSection}</Divider>
              {/* Filter */}
              {
                filterLabels.filterIcons.map((icon, index) => {
                  return (
                    <div className="d-flex" key={index}>
                      <div className="m-2">
                        <Switch
                          onChange={(e) => onChange(e, icon.filter_icons_id)}
                          checked={filters.filterOptions.includes(icon.filter_icons_id) ? true : false}
                          style={{ maxWidth: '50px' }}
                        />
                      </div>

                      <div className="m-2">
                        {getCmsData(icon.internationalization_json_filter_icons, 'filter_icons_name')}
                      </div>
                    </div>)
                })
              }

              <Divider style={borderAlertCategory}>{categorySection}</Divider>
              {/* Category Name */}
              <Select
                className="m-2"
                placeholder={categorySection}
                style={{ width: '100%' }}
                onChange={onCategoryChange}
              >
                {
                  [
                    <Option key="1" value={categoryOptionOne}>{categoryOptionOne}</Option>,
                    <Option key="2" value={categoryOptionTwo}>{categoryOptionTwo}</Option>,
                    <Option key="3" value={categoryOptionThree}>{categoryOptionThree}</Option>,
                    <Option key="4" value={categoryOptionFour}>{categoryOptionFour}</Option>
                  ]
                }
              </Select>

              <Divider style={borderAlertCSVFile}>{csvFileSection}</Divider>
              {/* CSV File */}
              <div className="m-2" >
                <React.Fragment>
                  <Dragger
                    className="mb-3"
                    fileList={csvFile}
                    beforeUpload={() => false}
                    listType="picture"
                    onRemove={handleRemoveCsvFile}
                    onChange={handleUploadCsvFile}
                    showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                  >
                    <div>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{uploadCsvFileTitle}</p>
                      <p className="ant-upload-hint">
                        {uploadCsvFileDescription}
                      </p>
                    </div>
                  </Dragger>
                </React.Fragment>
              </div>

              <Divider style={borderAlertImage}>{imageSection}</Divider>
              {/* Image  */}
              <div className="m-2" >
                <React.Fragment>
                  <Dragger
                    className="mb-3"
                    fileList={imageFile}
                    beforeUpload={() => false}
                    listType="picture"
                    onRemove={handleRemoveImageFile}
                    onChange={handleUploadImageFile}
                    showUploadList={{ showRemoveIcon: true, showPreviewIcon: false }}
                    multiple={false}
                  >
                    <div>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{uploadImageFileTitle}</p>
                      <p className="ant-upload-hint">
                        {uploadImageFileDescription}
                      </p>
                    </div>
                  </Dragger>
                </React.Fragment>
              </div>

              <Divider></Divider>
              {/* Salesman Price Factor */}
              <Button.Group className="d-flex justify-content-center m-2" size="default">
                <div className="btn btn-orange" onClick={() => { goToQuoteResults('0.90') }} trigger={isBrowser && !logins.isMobile ? 'hover' : 'contextMenu'}><b>{'<<'}</b></div>
                <div className="btn btn-orange" onClick={() => { goToQuoteResults('0.95') }} trigger={isBrowser && !logins.isMobile ? 'hover' : 'contextMenu'}><b>{'<'}</b></div>
                <div className="btn btn-orange" data-cy="salesman-factor-1" onClick={() => { goToQuoteResults('1.00') }} trigger={isBrowser && !logins.isMobile ? 'hover' : 'contextMenu'}><SearchOutlined /></div>
                <div className="btn btn-orange" onClick={() => { goToQuoteResults('1.05') }} trigger={isBrowser && !logins.isMobile ? 'hover' : 'contextMenu'}><b>{'>'}</b></div>
                <div className="btn btn-orange" onClick={() => { goToQuoteResults('1.10') }} trigger={isBrowser && !logins.isMobile ? 'hover' : 'contextMenu'}><b>{'>>'}</b></div>
              </Button.Group>
            </div>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withHandleQuery([useQuoteThroughFileQuery])(QuoteThroughFile)