import * as actionTypes from '../actionType';

  export const setFilterOptions = (option) => {
    return {
      type: actionTypes.SET_FILTER_OPTIONS,
      filterOptions: option
    }
  };

  export const setRankBackendValue = (value) => {
    return {
      type: actionTypes.SET_RANK_BACKEND_VALUE,
      rankBackendValue: value
    }
  };

  export const setRankCmsValue = (value) => {
    return {
      type: actionTypes.SET_RANK_CMS_VALUE,
      rankCmsValue: value
    }
  };

  export const isFilterResultsOn = (boolean) => {
    return {
      type: actionTypes.IS_FILTER_RESULTS_ON,
      isFilterResultsOn: boolean
    }
  };
  