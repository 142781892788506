import React, { useEffect, useCallback, Suspense } from 'react'
import './App.scss'
import { Alert } from 'antd'
import useLogoutQuery from './hooks/cms/useLogoutQuery'
import Routes from './routes/Routes'
import { useSelector, useDispatch } from 'react-redux'
import * as languageAction from './redux/actions/languageAction'
import * as loginAction from './redux/actions/loginAction'
import * as notificationAction from './redux/actions/notificationAction'
import useScreensQuery from './hooks/cms/useScreensQuery'
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import withHandleQuery from './hoc/withHandleQuery'
import useKonamiCode from "./konami"
import { isMobile } from "react-device-detect"

const TopNavBar = React.lazy(() => import('./components/bars/top-nav-bar/TopNavBar'))
const AlertBanner = React.lazy(() => import('./components/banners/AlertBanner'))
const CookieBanner = React.lazy(() => import('./components/banners/CookieBanner'))
const FooterNote = React.lazy(() => import('./components/cards/FooterNote'))
// const Notification = React.lazy(() => import('./components/notification/Notification'))


const App = (props) => {

  const {
    dataHoc
  } = props

  let history = useHistory()
  const [konamiCode] = useKonamiCode()

  const languages = useSelector(state => state.languages)
  const logins = useSelector(state => state.logins)
  const provision = useSelector(state => state.provision)
  const dispatch = useDispatch()
  const setAuthentication = (value) => dispatch(loginAction.setAuthentication(value))
  const setLanguage = useCallback((lang, locale) => dispatch(languageAction.setLanguage(lang, locale)), [dispatch])
  const setMobileMode = (value) => dispatch(loginAction.setMobileMode(value))
  const setNotification = (notification) => dispatch(notificationAction.setNotification(notification))
  const deleteNotification = () => dispatch(notificationAction.deleteNotification())

  useEffect(() => {
    // if (!isBrowser) {
    //   alert('Install our mobile App')
    // }

    /**
     * Set the language and locale according with the the browser language if the language is not define, null or empty string and no path name is given.
     */
    if ((languages.language === undefined || languages.language === null || languages.language === '') && (history.location.pathname.split("/")[1] === "")) {
      switch (navigator.language) {
        case 'en-US':
        case 'en-EN':
        case 'en-en':
        case 'en-UK':
        case 'en-uk':
          setLanguage('en', 'nl_NL')
          break
        case 'nl':
        case 'nl_NL':
        case 'nl_nl':
          setLanguage('nl', 'nl_NL')
          break
        case 'fr':
        case 'fr_FR':
        case 'fr_fr':
          setLanguage('fr', 'fr_FR')
          break
        case 'de':
        case 'de_DE':
        case 'de_de':
          setLanguage('de', 'de_DE')
          break
        case 'it_it':
        case 'it_IT':
        case 'it':
          setLanguage('it', 'it_IT')
          break
        default:
          setLanguage('en', 'nl_NL')
      }
    }

    /**
     * Set the language and locale acording with the login path name.
     * Useful to put the user in the expected language when he comes from the corporate website.
     * This should not be hard coded
     */
    if (history.location.pathname.split("/")[1] !== "") {
      switch (history.location.pathname) {
        case '/login-en':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('en', 'nl_NL')
          break
        case '/inloggen-nl':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('nl', 'nl_NL')
          break
        case '/inloggen-be':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('nl_BE', 'nl_BE')
          break
        case '/login-be':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('fr_BE', 'fr_BE')
          break
        case '/login-fr':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('fr', 'fr_FR')
          break
        case '/einloggen-de':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('de', 'de_DE')
          break
        case '/accesso-it':
          if (logins.isAuthenticated) setAuthentication(false)
          setLanguage('it', 'it_IT')
          break
        default:
      }
    }

    setMobileMode(isMobile)

    console.log('%c Welcome to My Mobilae! Lets debug!!!', 'background: #222; color: #bada55');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const screensLabels = dataHoc[0] !== undefined && dataHoc[0].screensLabels
  const logoutQueryLabels = dataHoc[1] !== undefined && dataHoc[1].logoutQueryLabels

  const globalBackground = logins.isAuthenticated ? {} : { backgroundImage: `url(${screensLabels?.generalScreen.logo[0].background_image})`, backgroundSize: 'cover' }
  const topNavBarStyle = logins.isAuthenticated ? { position: 'sticky', top: '0', zIndex: '99' } : { position: 'inherit' }

  /**
   * Handle to set the language, and locale
   * This handle is only available in stage and in the local environments.
   * @param {string} lang Language ex: nl
   * @param {string} locale Locale ex: nl_NL
   */
  const handleClick = (lang, locale) => {
    setLanguage(lang, locale)
  }

  /**
   * Display the buttons responsible to change the language and locale. 
   * This buttons are only available in stage and in the local environments.
   *  
   * @return html element
   */
  const languageButtons = () => {
    const buttonsContent = [
      {
        lable: 'en',
        language: 'en',
        locale: 'nl_NL'
      },
      {
        lable: 'nl',
        language: 'nl',
        locale: 'nl_NL'
      },
      {
        lable: 'nl_BE',
        language: 'nl_BE',
        locale: 'nl_BE'
      },
      {
        lable: 'fr_BE',
        language: 'fr_BE',
        locale: 'fr_BE'
      },
      {
        lable: 'fr',
        language: 'fr',
        locale: 'fr_FR'
      },
      {
        lable: 'de',
        language: 'de',
        locale: 'de_DE'
      },
      {
        lable: 'it',
        language: 'it',
        locale: 'it_IT'
      }
    ]
    return buttonsContent.map((content, index) => {
      return (
        <button key={content.lable + index} className="btn btn-orange" style={{ padding: '0px' }} onClick={() => handleClick(content.language, content.locale)}>
          {content.lable}
        </button>
      )
    })
  }

  return (
    <React.Fragment>
      <Suspense fallback={null}>
        <div className="wrapper" style={globalBackground}>
          <div className="w-100 h-100 logo-background" id="content" >
            {
              (window.location.host.includes('dev') || window.location.host.includes('stage') || logins.username === "mama@mobilae.com") &&
              <div className="text-center pt-2" style={{ background: 'white', position: 'relative', zIndex: '1' }}>
                {
                  languageButtons()
                }
                {/* <button className="btn btn-orange" style={{ padding: '0px' }} onClick={() => {
                  setNotification(
                    {
                      id: '1',
                      notificationType: '2',
                      placement: '3',
                      style: '4',
                      duration: '5',
                      message: '6',
                      description: '7',
                      handlerClick: '8',
                    }
                  )
                }}>
                  xxx
                </button>
                <button className="btn btn-orange" style={{ padding: '0px' }} onClick={() => {
                  deleteNotification()
                }}>
                  x
                </button> */}
              </div>
            }

            <div style={topNavBarStyle}>
              <TopNavBar
                screensLabels={screensLabels}
                logoutLabels={logoutQueryLabels}
              />
              {
                logins.isAuthenticated && logins.accessType !== undefined && logins.accessType !== 'employee' &&
                <AlertBanner />
              }

              {
                logins.isAuthenticated && logins.accessType !== undefined && logins.accessType === 'employee' && provision.provisionVisibility && provision.provisionCode !== '' && <Alert message={`PC: ${provision.provisionCode}`} type="success" closable />
              }

              {/* {
                logins.isAuthenticated && logins.accessType !== undefined && logins.accessType === 'employee' && logins.username === "jadu@mobilae.com" &&
                <Notification />
              } */}

            </div>

            <Routes dataJson={screensLabels} history={history} />
            {
              konamiCode &&
              console.dir(JSON.parse(sessionStorage.getItem('my-environment')))
            }

            <Helmet>
              <meta name="description" content={screensLabels.generalMetaDescription} />
            </Helmet>

            <FooterNote />
          </div>
        </div>
        <CookieBanner />
      </Suspense>
    </React.Fragment>
  )
}

export default withHandleQuery([useScreensQuery, useLogoutQuery])(React.memo(App))