import React, { useState, useEffect, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useCardStyle from '../../hooks/useCardStyle'
import * as provisionAction from '../../redux/actions/provisionAction'
import Spinner from '../../components/spinner/Spinner'

const PersonalInformation = React.lazy(() => import('../../components/cards/PersonalInformation'))
const Brochure = React.lazy(() => import('../../components/cards/Brochure'))
const Documents = React.lazy(() => import('../../components/cards/Documents'))
const SpecialPromotions = React.lazy(() => import('../../components/cards/SpecialPromotions'))
const Info = React.lazy(() => import('../../components/cards/Info'))
const PriceList = React.lazy(() => import('../../components/cards/PriceList'))
const Video = React.lazy(() => import('../../components/cards/Video'))
const QuoteSteps = React.lazy(() => import('../../components/cards/QuoteSteps'))
const Assistance = React.lazy(() => import('../../components/cards/Assistance'))
const PromoteHomeVisit = React.lazy(() => import('../../components/cards/PromoteHomeVisit'))
const MeasurementAppLink = React.lazy(() => import('../../components/cards/MeasurementAppLink'))

const MyPersonal = (props) => {

  const [showPersonalInformation, setShowPersonalInformation] = useState(false)
  const [showBrochure, setShowBrochure] = useState('')
  const [showSpecialPromotions, setShowSpecialPromotions] = useState('')
  const [showPromoteHomeVisit, setShowPromoteHomeVisit] = useState('')
  const [showAssistance, setShowAssistance] = useState('')
  const [showInfo, setShowInfo] = useState('')
  const [showDocuments, setShowDocuments] = useState('')
  const [loading, setLoading] = useState(false)

  const [columnOne, setColumnOne] = useState('')
  const [columnTwo, setColumnTwo] = useState('')
  const [columnThree, setColumnThree] = useState('')
  const [columnFour, setColumnFour] = useState('')

  useEffect(() => {
    setColumnOne(showPersonalInformation || showBrochure ? 'card border-0' : '')
    setColumnTwo(showSpecialPromotions ? 'card border-0' : '')
    setColumnThree(showPromoteHomeVisit ? 'card border-0' : '')
    setColumnFour(showAssistance || showInfo || showDocuments ? 'card border-0' : '')

  }, [showPersonalInformation, showBrochure, showSpecialPromotions, showPromoteHomeVisit, showAssistance, showInfo, showDocuments, setColumnOne, setColumnTwo, setColumnThree, setColumnFour])

  const logins = useSelector(state => state.logins)
  const languages = useSelector(state => state.languages)

  const dispatch = useDispatch()
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { cardStyle } = useCardStyle()

  return (
    <Suspense fallback={null}>
      {
        loading && <Spinner />
      }
      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-personal">
        <div className={columnOne} style={cardStyle}>
          <PersonalInformation setShowPersonalInformation={setShowPersonalInformation} showPersonalInformation={showPersonalInformation} setLoading={setLoading} languages={languages} />
          <Brochure setShowBrochure={setShowBrochure} />
        </div>

        <div className={columnTwo} style={cardStyle}>
          <SpecialPromotions setShowSpecialPromotions={setShowSpecialPromotions} />
        </div>

        <div className={columnThree} style={cardStyle}>
          <PromoteHomeVisit setShowPromoteHomeVisit={setShowPromoteHomeVisit} />
        </div>

        <div className={columnFour} style={cardStyle}>
          <Assistance planVisit={true} setShowAssistance={setShowAssistance} />
          <Info components={
            [
              <PriceList />,
              <Video />,
              logins.accessType === 'employee' && <QuoteSteps />,
              logins.accessType === 'employee' && <MeasurementAppLink />
            ]
          }
            setShowInfo={setShowInfo} />
          <Documents setShowDocuments={setShowDocuments} />
        </div>
      </div>
    </Suspense>
  )
}

export default React.memo(MyPersonal)
