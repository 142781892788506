import React, { useState, Suspense, useEffect } from 'react'
import { Skeleton, Switch } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import useCardStyle from '../../hooks/useCardStyle'
import ScrollToTop from '../../hooks/ScrollToTop'
import * as provisionAction from '../../redux/actions/provisionAction'
import useQuotationStepsQuery from '../../hooks/cms/useQuotationStepsQuery'
import withHandleQuery from '../../hoc/withHandleQuery'
import { fetchWrapper } from '../../helpers/fetchWrapper.js'
import { CTD_REQUESTS_QUERY } from '../../queries/back-end/queries.js'
import Spinner from '../../components/spinner/Spinner'
import { ctdChecksComponentQuery } from '../../lib/queries/cms/queries'
import sanityClient from '../../lib/client.js'
import CtdRequests from '../../components/cards/ctd/CtdRequests'
import CtdChecks from '../../components/cards/ctd/CtdChecks'

const CommercialPersonalInformation = React.lazy(() => import('../../components/cards/CommercialPersonalInformation'))
const NewUser = React.lazy(() => import('../../components/cards/NewUser'))
const CommercialAppointments = React.lazy(() => import('../../components/cards/CommercialAppointments'))
const SalesmanStatistics = React.lazy(() => import('../../components/cards/SalesmanStatistics'))
const SalesmanCountryStatistics = React.lazy(() => import('../../components/cards/SalesmanCountryStatistics'))

const backendGraphqlEndpoint = process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT

const Dashboard = (props) => {

  const {
    dataJson,
    myHomeVisitScreenUrl,
    history,
    dataHoc
  } = props

  const languages = useSelector(state => state.languages)
  const [isLoading, setIsLoading] = useState(true)
  const [ctdCheckLabels, setCtdCheckLabels] = useState(null)
  const [ctdRequestsData, setCtdRequestsData] = useState(null)
  const [syncData, setSyncData] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const getCtdRequestsData = async () => {
      await fetchWrapper.post(backendGraphqlEndpoint, CTD_REQUESTS_QUERY)
        .then(({ data }) => {
          setCtdRequestsData(data?.ctdRequests.reverse())
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
        })
    }
    getCtdRequestsData()
  }, [syncData])

  useEffect(() => {
    const query = ctdChecksComponentQuery
    const params = { language: languages.language === "en" ? 'en_UK' : languages.locale }

    sanityClient.fetch(query, params)
      .then((data) => {
        setCtdCheckLabels(data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
      })

  }, [languages])

  const logins = useSelector(state => state.logins)
  const impersonates = useSelector(state => state.impersonates)
  const dispatch = useDispatch()
  const setProvisionVisibility = (value) => dispatch(provisionAction.setProvisionVisibility(value))

  const quotationStepsLabels = dataHoc[0] !== undefined && dataHoc[0].quotationStepsLabels

  const [loading, setloading] = useState(true)
  const { cardStyle } = useCardStyle()

  useEffect(() => {
    setProvisionVisibility(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = checked => {
    setloading(!checked);
  }

  return (
    <Suspense fallback={isLoading}>
      {
        isLoading &&
        <Spinner />
      }
      {
        // CTD salesman
        logins.employeeName.role.filter(role => role.id === 5).length === 0 && ctdRequestsData &&
        <CtdRequests
          ctdCheckLabels={ctdCheckLabels}
          ctdRequestsData={ctdRequestsData}
          syncData={syncData}
          setSyncData={setSyncData}
        />
      }

      {
        // CTD employee
        logins.employeeName.role.some(role => role.id === 5) &&
        <CtdChecks
          syncData={syncData}
          setSyncData={setSyncData}
          ctdCheckLabels={ctdCheckLabels}
          ctdRequestsData={ctdRequestsData}
          quotationStepsLabels={quotationStepsLabels}
        />
      }

      <div className={`card-${!logins.isMobile ? 'deck' : ''} p-3 p-lg-5`} data-cy="my-dashboard">
        <ScrollToTop>
          <div className="card border-0" style={cardStyle}>
            {
              !impersonates.isImpersonate && logins.accessType === 'employee' &&
              <NewUser dataJson={dataJson} />
            }
            {
              !impersonates.isImpersonate &&
              <CommercialAppointments myHomeVisitScreenUrl={myHomeVisitScreenUrl} />
            }
            {
              !impersonates.isImpersonate &&
              <CommercialPersonalInformation />
            }
          </div>
          {
            logins.employeeName.role.filter(role => role.id === 5).length === 0 &&
            <Switch className="mt-3" checked={!loading} onChange={onChange} style={{ maxWidth: '50px' }} />
          }
          {
            logins.employeeName.role.filter(role => role.id === 5).length === 0 &&
            <div className="card border-0" style={cardStyle}>
              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanStatistics period={'year'} />
              </Skeleton>

              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanCountryStatistics title={'appointments'} />
              </Skeleton>

              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanCountryStatistics title={'sales'} />
              </Skeleton>

            </div>
          }
          {
            logins.employeeName.role.filter(role => role.id === 5).length === 0 &&
            <div className="card border-0" style={cardStyle}>
              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanStatistics period={'month'} />
              </Skeleton>

              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanCountryStatistics title={'conversion'} />
              </Skeleton>

              <Skeleton className="ant-card ant-card-bordered ant-card-body mb-4 mt-3" loading={loading} active>
                <SalesmanCountryStatistics title={'margin'} />
              </Skeleton>

            </div>
          }

        </ScrollToTop>
      </div>
    </Suspense>
  )
}

export default withHandleQuery([useQuotationStepsQuery])(React.memo(Dashboard))
