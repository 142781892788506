import { UPDATE_QUOTATION_PRICE } from '../../queries/back-end/queries'
import { useMutation } from 'react-apollo'

const useUpdateQuotationPriceMutation = () => {

  const [updateQuotationPriceMutation, { loading, error }] = useMutation(UPDATE_QUOTATION_PRICE);

  if (loading) return { loading }
  if (error) return { error }

  return {
    updateQuotationPriceMutation,
    loading,
    error
  }

}

export default useUpdateQuotationPriceMutation
