import * as actionTypes from './actionType';

export const setAlertBannerVisibility = (boolean) => {
  return {
    type: actionTypes.SET_ALERT_BANNER_VISIBILITY,
    isAlertBannerVisible: boolean
  }
};

export const setAlertBannerMessage = (message) => {
  return {
    type: actionTypes.SET_ALERT_BANNER_MESSAGE,
    alertBannerMessage: message
  }
};

export const setAlertBannerType = (type) => {
  return {
    type: actionTypes.SET_ALERT_BANNER_TYPE,
    alertBannerType: type
  }
};