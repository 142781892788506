import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';

function useI18next(translationJson) {
  const resources = {
    all: {
      translation: translationJson
    }
  }

  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: 'all',
      lng: 'all',
      debug: false,
    });

  return {
    i18next: i18next
  }
}

export default useI18next