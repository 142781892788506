import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks';
import { QUOTATION_STEPS_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useQuotationStepsQuery = () => {

  const languages = useSelector(state => state.languages)
  let locale = ''
  const products = useSelector(state => state.products)

  const productId = products.productId === "" ? 10 : parseInt(products.productId)

  switch (languages.locale.toLowerCase()) {
    case 'en':
    case 'en_en':
      locale = 'en'
      break
    case 'nl':
    case 'nl_nl':
      locale = 'nl'
      break
    case 'fr':
    case 'fr_fr':
      locale = 'fr'
      break
    case 'de':
    case 'de_de':
      locale = 'de'
      break
    case 'it':
    case 'it_it':
      locale = 'it'
      break
    case 'nl_be':
      locale = 'nl_BE'
      break
    case 'fr_be':
      locale = 'fr_BE'
      break
    default:
      locale = 'nl'
  }

  const { loading, error, data } = useQuery(QUOTATION_STEPS_QUERY, { client: cmsClient, variables: { productId: productId } });

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJsonOrder = data.products[0].order.internationalization_json_order
  const internationalizationJsonPreOrder = data.products[0].pre_order.internationalization_json_pre_order
  const internationalizationJsonProducts = data.products[0].internationalization_json
  const internationalizationJsonFilter = data.products[0].filter.internationalization_json

  const getCmsData = (json, tag) => {
    if (tag !== 'card_terms_and_conditions_popup_pdf') {
      const I18next = () => useI18next(json)
      const { i18next } = I18next()
      return i18next.t(`${tag}.${languages.language}`)
    } else {
      const I18next = () => useI18next(json)
      const { i18next } = I18next()
      return i18next.t(`${tag}.${locale}`)
    }
  }

  const lables = {
    cardProductSpecifications: getCmsData(internationalizationJsonOrder, 'card_product_specifications'),
    cardProductColorOptionOne: getCmsData(internationalizationJsonOrder, 'card_product_color_option_one'),
    cardProductColorOptionTwo: getCmsData(internationalizationJsonOrder, 'card_product_color_option_two'),
    cardProductExtraServiceOptionOne: getCmsData(internationalizationJsonOrder, 'card_product_extra_service_option_one'),
    cardProductExtraServiceOptionTwo: getCmsData(internationalizationJsonOrder, 'card_product_extra_service_option_two'),
    cardProductAdditionalCodesTitle: getCmsData(internationalizationJsonOrder, 'card_product_additional_codes_title'),
    cardProductAdditionalCodesTableDescription: getCmsData(internationalizationJsonOrder, 'card_product_additional_codes_table_description'),
    cardProductAdditionalCodesTableQuantity: getCmsData(internationalizationJsonOrder, 'card_product_additional_codes_table_quantity'),
    cardProductAdditionalCodesTablePrice: getCmsData(internationalizationJsonOrder, 'card_product_additional_codes_table_price'),
    cardPaymentMethodTitle: getCmsData(internationalizationJsonOrder, 'card_payment_method_title'),
    cardPaymentMethodDropdownText: getCmsData(internationalizationJsonOrder, 'card_payment_method_dropdown_text'),
    cardPaymentMethodSubventionValue: getCmsData(internationalizationJsonOrder, 'card_payment_method_subvention_value'),
    cardPaymentMethodDropdownInfoMessage: getCmsData(internationalizationJsonOrder, 'card_payment_method_dropdown_info_message'),
    cardSpecialPromotionsTitle: getCmsData(internationalizationJsonOrder, 'card_special_promotions_title'),
    cardSpecialPromotionsPromoCode: getCmsData(internationalizationJsonOrder, 'card_special_promotions_promo_code'),
    cardSpecialPromotionsPromoCodeMessage: getCmsData(internationalizationJsonOrder, 'card_special_promotions_promo_code_message'),
    cardSpecialPromotionsAdditionalDiscount: getCmsData(internationalizationJsonOrder, 'card_special_promotions_additional_discount'),
    cardSpecialPromotionsAdditionalDiscountMessage: getCmsData(internationalizationJsonOrder, 'card_special_promotions_additional_discount_message'),
    cardSpecialPromotionsButton: getCmsData(internationalizationJsonOrder, 'card_special_promotions_button'),
    cardSpecialPromotionsInfoMessage: getCmsData(internationalizationJsonOrder, 'card_special_promotions_info_message'),
    cardRemarksTitle: getCmsData(internationalizationJsonOrder, 'card_remarks_title'),
    cardRemarksText: getCmsData(internationalizationJsonOrder, 'card_remarks_text'),
    cardDeliveryAddressTitle: getCmsData(internationalizationJsonOrder, 'card_delivery_address_title'),
    cardDeliveryAddressEditButton: getCmsData(internationalizationJsonOrder, 'card_delivery_address_edit_button'),
    cardDeliveryAddressSuccessMessageContent: getCmsData(internationalizationJsonOrder, 'finalise_button'),
    cardConfirmOrderTitle: getCmsData(internationalizationJsonOrder, 'card_confirm_order_title'),
    cardConfirmOrderContent: getCmsData(internationalizationJsonOrder, 'card_confirm_order_content'),
    cardConfirmOrderCheckBox: getCmsData(internationalizationJsonOrder, 'card_confirm_order_check_box'),
    cardConfirmOrderSendButton: getCmsData(internationalizationJsonOrder, 'card_confirm_order_send_button'),
    cardConfirmOrderPopupTitle: getCmsData(internationalizationJsonOrder, 'card_confirm_order_popup_title'),
    cardConfirmOrderPopupContent: getCmsData(internationalizationJsonOrder, 'card_confirm_order_popup_content'),
    cardConfirmOrderPopupCancelButton: getCmsData(internationalizationJsonOrder, 'card_confirm_order_popup_cancel_button'),
    cardConfirmOrderPopupOkButton: getCmsData(internationalizationJsonOrder, 'card_confirm_order_popup_ok_button'),
    cardConfirmOrderSuccessMessage: getCmsData(internationalizationJsonOrder, 'card_confirm_order_success_message'),
    cardConfirmOrderErrorMessage: getCmsData(internationalizationJsonOrder, 'card_confirm_order_error_message'),
    cardTermsAndConditionsBtn: getCmsData(internationalizationJsonOrder, 'card_terms_and_conditions_btn'),
    cardTermsAndConditionsPopupTitle: getCmsData(internationalizationJsonOrder, 'card_terms_and_conditions_popup_title'),
    cardTermsAndConditionsPopupPdf: getCmsData(internationalizationJsonOrder, 'card_terms_and_conditions_popup_pdf'),
    deliveryTime: getCmsData(internationalizationJsonOrder, 'card_payment_method_delivery_time'),
    termsAndConditionsId: getCmsData(internationalizationJsonOrder, 'terms_and_conditions_id'),
    quotationPdfButton: getCmsData(internationalizationJsonOrder, 'quotation_pdf_button'),
    paymentMethodDropdownOptions: data.products[0].order.payment_method_dropdown_options,
    finaliseButton: getCmsData(internationalizationJsonPreOrder, 'finalise_button'),
    saveButton: getCmsData(internationalizationJsonPreOrder, 'save_button'),
    cardColorsTitle: getCmsData(internationalizationJsonPreOrder, 'card_colors_title'),
    cardColorsOptionOne: getCmsData(internationalizationJsonPreOrder, 'card_colors_option_one'),
    cardColorsOptionTwo: getCmsData(internationalizationJsonPreOrder, 'card_colors_option_two'),
    cardExtraServicesTitle: getCmsData(internationalizationJsonPreOrder, 'card_extra_services_title'),
    cardExtraServicesOptionOne: getCmsData(internationalizationJsonPreOrder, 'card_extra_services_option_one'),
    cardExtraServicesOptionTwo: getCmsData(internationalizationJsonPreOrder, 'card_extra_services_option_two'),
    cardAdditionalCodesTitle: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_title'),
    cardAdditionalCodesTableDescription: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_table_description'),
    cardAdditionalCodesTableQuantity: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_table_quantity'),
    cardAdditionalCodesTablePrice: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_table_price'),
    cardAdditionalCodesSelectButton: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_select_button'),
    cardAdditionalCodesDeleteCodesButton: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_delete_codes_button'),
    cardAdditionalCodesPopupTitle: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_popup_title'),
    cardAdditionalCodesPopupOption: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_popup_option'),
    cardAdditionalCodesPopupDropdownText: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_popup_dropdown_text'),
    cardAdditionalCodesPopupButton: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_popup_button'),
    cardAdditionalCodesPopupErrorMessage: getCmsData(internationalizationJsonPreOrder, 'card_additional_codes_popup_error_message'),
    finaliseProcessErrorMessageTitle: getCmsData(internationalizationJsonPreOrder, 'finalise_process_error_message_title'),
    finaliseProcessErrorMessageContent: getCmsData(internationalizationJsonPreOrder, 'finalise_process_error_message_content'),
    cardUpdatePriceTitle: getCmsData(internationalizationJsonPreOrder, 'card_update_price_title'),
    cardUpdatePriceDescription: getCmsData(internationalizationJsonPreOrder, 'card_update_price_description'),
    filterCardTitle: getCmsData(internationalizationJsonFilter, 'card_name'),
    button: getCmsData(internationalizationJsonFilter, 'button'),
    filterRanksDropdownName: getCmsData(internationalizationJsonFilter, 'filter_ranks_dropdown_name'),
    messageInfoFilterRanksDropdown: getCmsData(internationalizationJsonFilter, 'message_info_filter_ranks_dropdown'),
    messageErrorNoResultsTitle: getCmsData(internationalizationJsonFilter, 'message_error_no_results_title'),
    messageErrorNoResultsContent: getCmsData(internationalizationJsonFilter, 'message_error_no_results_content'),
    messageErrorNoResultsOkButton: getCmsData(internationalizationJsonFilter, 'message_error_no_results_ok_button'),
    filterRanks: data.products[0].filter.filter_ranks,
    filterIcons: data.products[0].filter.filter_icons,
    question: data.products[0].quotation.question,
    answers: data.products[0].quotation.answer,
    showProduct: data.products[0].show_product
  }

  return {
    quotationStepsLabels: { lables, internationalizationJsonProducts },
    loading,
    error,
  }
}

export default useQuotationStepsQuery
