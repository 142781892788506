import { CUSTOMER_QUERY } from '../../queries/back-end/queries';
import { useQuery } from '@apollo/react-hooks';

const useCustomerQuery = () => {

  const { data, loading, error } = useQuery(CUSTOMER_QUERY/*, { fetchPolicy: 'cache-and-network' }*/);

  if (loading) return { loading }
  if (error) return { error }

  const backendData = {
    customerFirstName: data.customer.firstName,
    customerLastName: data.customer.lastName,
    customerPhone: data.customer.phoneNumber,
    customerAddressLine1: data.customer.addressLine1,
    customerCity: data.customer.city,
    customerPostalCode: data.customer.postalCode,
    customerEmail: data.customer.User.email,
    addressLine1: data.customer.addressLine1,
    cmpCode: data.customer.synergyCmpCode,
    customerJourney: data.customer.journey,
    customerPreferredLanguage: data.customer.preferredLanguage,
    customerAgreement: data.customer.agreement
  }

  return {
    customerQueryData: backendData,
    loading,
    error
  }
}

export default useCustomerQuery