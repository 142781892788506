import { SAVE_EXTRA_SERVICES } from '../../queries/back-end/queries'
import { useMutation } from 'react-apollo'

const useSaveExtraServicesMutation = () => {

  const [saveExtraServicesMutation, { loading, error }] = useMutation(SAVE_EXTRA_SERVICES);

  if (loading) return { loading }
  if (error) return { error }

  return {
    saveExtraServicesMutation,
     loading,
     error
  }
}

export default useSaveExtraServicesMutation