import { useQuery } from '@apollo/react-hooks';
import { SCREENS_QUERY } from '../../queries/cms/queries'
import { useSelector } from 'react-redux'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useScreensQuery = () => {

  const languages = useSelector(state => state.languages)
  const { loading, error, data } = useQuery(SCREENS_QUERY, { client: cmsClient });

  if (loading) return { loading }
  if (error) return { error }

  const getURL = (json, tag) => {
    const I18next = () => useI18next(json)
    const { i18next } = I18next()
    return i18next.t(`${tag}.${languages.language}`)
  }

  const lables = {
    myPersonalScreen: data.screens[0],
    myHomeVisitScreen: data.screens[1],
    myQuotationScreen: data.screens[2],
    myOrderScreen: data.screens[3],
    myServiceScreen: data.screens[4],
    generalScreen: data.screens[5],
    dashboardScreen: data.screens[7],
    loginScreen: data.screens[9],
    resetPasswordScreen: data.screens[12],
    myPersonalScreenJson: data.screens[0].internationalization_json,
    myHomeVisitScreenJson: data.screens[1].internationalization_json,
    myQuotationScreenJson: data.screens[2].internationalization_json,
    myOrderScreenJson: data.screens[3].internationalization_json,
    myServiceScreenJson: data.screens[4].internationalization_json,
    generalScreenJson: data.screens[5].internationalization_json,
    dashboardScreenJson: data.screens[7].internationalization_json,
    quotationStepsScreenJson: data.screens[6].internationalization_json,
    quotationResultsScreenJson: data.screens[8].internationalization_json,
    resetPasswordScreenJson: data.screens[12].internationalization_json,
    loginScreenJson: data.screens[9].internationalization_json,
    preOrderScreenJson: data.screens[10].internationalization_json,
    orderScreenJson: data.screens[11].internationalization_json,
    dashboardScreenUrl: getURL(data.screens[7].internationalization_json, 'url_path'),
    generalMetaDescription: getURL(data.screens[5].meta[0].internationalization_json, 'description')
  }

  return {
    screensLabels: lables,
    loading,
    error
  }
}

export default useScreensQuery