import { combineReducers } from 'redux'
import answerReducer from './quotation/answerReducer'
import filterReducer from './quotation/filterReducer'
import productReducer from './productReducer'
import loginReducer from './loginReducer'
import alertBannerReducer from './alertBannerReducer'
import impersonateReducer from './impersonateReducer'
import resultsReducer from './quotation/resultsReducer'
import languageReducer from './languageReducer'
import customerOrdersReducer from './customerOrdersReducer'
import customerProductsReducer from './customerProductsReducer'
import provisionReducer from './provisionReducer'
import notificationReducer from './notificationReducer'
import quotationReducer from './quotationReducer'

const appReducer = combineReducers({
  answers: answerReducer,
  products: productReducer,
  logins: loginReducer,
  alertBanners: alertBannerReducer,
  impersonates: impersonateReducer,
  results: resultsReducer,
  languages: languageReducer,
  filters: filterReducer,
  quotations: quotationReducer,
  customerOrders: customerOrdersReducer,
  customerProducts: customerProductsReducer,
  provision: provisionReducer,
  notification: notificationReducer
})

export default appReducer