import React from 'react'
import { Table, Card, Button } from 'antd'
import useCardStyle from '../../../hooks/useCardStyle'
import { SyncOutlined } from '@ant-design/icons'
import { impersonateClient } from '../../../graphql-endpoints/graphqlEndpoints'
import { useDispatch } from 'react-redux'
import * as answerAction from '../../../redux/actions/quotation/answerAction'
import * as productAction from '../../../redux/actions/productAction'
import * as impersonateAction from '../../../redux/actions/impersonateAction'
import * as languageAction from '../../../redux/actions/languageAction'
import * as quotationAction from '../../../redux/actions/quotationAction'
import { useHistory } from "react-router-dom"

// Salesman point of view
const CtdRequests = (props) => {

  const {
    setSyncData,
    syncData,
    ctdRequestsData,
    ctdCheckLabels
  } = props

  let history = useHistory()

  const { cardStyle } = useCardStyle()
  const dispatch = useDispatch()
  const deleteAllAnswers = () => dispatch(answerAction.deleteAllAnswers())
  const deleteProduct = () => dispatch(productAction.deleteProduct())
  const setLanguage = (lang, locale) => dispatch(languageAction.setLanguage(lang, locale))
  const setImpersonification = (boolean) => dispatch(impersonateAction.setImpersonification(boolean))
  const setImpersonateUsername = (username) => dispatch(impersonateAction.setImpersonateUsername(username))
  const setImpersonateParameter = (parameter) => dispatch(impersonateAction.setImpersonateParameter(parameter))
  const setProduct = (name, id) => dispatch(productAction.setProduct(name, id))
  const setQuotation = (quotation) => dispatch(quotationAction.setQuotation(quotation))

  const impersonate = async (request) => {
    let userLanguage = "nl"

    /**
     * this switch allows the frontend to format the user preferredLanguage value comming from the database.
     * the preferredLanguage value does not have a consistent format.
     */
    switch (request.QuotationOverview.Customer.preferredLanguage.toLowerCase()) {
      case 'en':
      case 'en_en':
        userLanguage = 'en'
        break
      case 'nl':
      case 'nl_nl':
        userLanguage = 'nl'
        break
      case 'fr':
      case 'fr_fr':
        userLanguage = 'fr'
        break
      case 'de':
      case 'de_de':
        userLanguage = 'de'
        break
      case 'it':
      case 'it_it':
        userLanguage = 'it'
        break
      case 'nl_be':
        userLanguage = 'nl'
        break
      case 'fr_be':
        userLanguage = 'fr'
        break
      default:
        userLanguage = 'nl'
    }

    await impersonateClient(request.QuotationOverview.Customer.User.email).then((response) => {

      if (response) {
        deleteAllAnswers()
        deleteProduct()

        setLanguage(userLanguage, request.QuotationOverview.Customer.locale)

        setImpersonification(true)
        setImpersonateUsername(request.QuotationOverview.Customer.User.email)
        setImpersonateParameter(request.QuotationOverview.Customer.User.email)
        setProduct(request.QuotationOverview.WizardCompositeKey.Model.ProductCategory.category, request.QuotationOverview.WizardCompositeKey.Model.ProductCategory.code)

        // To do - remove this workaround. We need to get the slug from the CMS
        let pathname = ''
        switch (request.QuotationOverview.Customer.locale.toLowerCase()) {
          case 'en':
          case 'en_en':
            pathname = 'en-uk-ctd'
            break
          case 'nl':
          case 'nl_nl':
            pathname = 'nl-nl-ctd'
            break
          case 'fr':
          case 'fr_fr':
            pathname = 'fr-fr-ctd'
            break
          case 'de':
          case 'de_de':
            pathname = 'de-de-ctd'
            break
          case 'it':
          case 'it_it':
            pathname = 'it-it-ctd'
            break
          case 'nl_be':
            pathname = 'nl-be-ctd'
            break
          case 'fr_be':
            pathname = 'fr-be-ctd'
            break
          default:
            pathname = 'nl-nl-ctd'
        }

        history.push({
          pathname: `/${pathname}`,
          search: `?ctd=${request.QuotationOverview.CTDRequest.id}`
        })

        // add message after impersonation
        // let alertBannerMessage = alertBanners.alertBannerMessage
        // setAlertBannerVisibility(true)
        // setAlertBannerMessage(`${alertBannerMessage}. ${commercialAppointmentsLabel.bannerMessageLabel} ${username}`)
      }
    })
  }

  const columnsEmployee = [
    {
      title: ctdCheckLabels?.ctdChecksTableColumnName,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: ctdCheckLabels?.ctdChecksTableColumnCMPCode,
      dataIndex: 'cmpCode',
      key: 'cmpCode',
    },
    {
      title: ctdCheckLabels?.ctdChecksTableColumnProduct,
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: ctdCheckLabels?.ctdChecksTableColumnWaiting,
      dataIndex: 'waiting',
      key: 'waiting',
    },
    {
      title: ctdCheckLabels?.ctdChecksTableColumnAction,
      dataIndex: 'action',
      key: 'action',
      render: (_, text) => {
        // id 4 - approved
        // id 2 - in progress
        // id 1 - new
        // id 3 - request changes
        switch (text.action) {
          case 1:
            return ctdCheckLabels?.ctdChecksTableActions?.[2]
          case 2:
            return ctdCheckLabels?.ctdChecksTableActions?.[1]
          case 3:
            return <div className="btn btn-orange" style={{ width: '100px' }}
              onClick={() => {
                const request = ctdRequestsData[text.key]
                setQuotation(request?.QuotationOverview)
                impersonate(request)
              }}
            >{ctdCheckLabels?.ctdChecksTableActions?.[3]}</div>
          case 4:
            return ctdCheckLabels?.ctdChecksTableActions?.[0]
          default:
            return 'N/A'
        }
      },
    },
  ]

  let dataCTDRequests = []

  ctdRequestsData.map((request, index) => {

    let diff = Math.abs(new Date() - new Date(request.createdTime))

    dataCTDRequests.push({
      key: index,
      name: `${request?.QuotationOverview?.Customer?.firstName} ${request?.QuotationOverview?.Customer?.lastName}`,
      cmpCode: request?.QuotationOverview?.Customer?.synergyCmpCode,
      product: request?.QuotationOverview?.WizardCompositeKey?.Model?.ProductCategory?.category,
      // summer time
      waiting: Math.floor((diff / 1000) / 60) - 60,
      action: request?.Status?.id,
    })
  })

  return (
    <div className={`pt-3 pr-3 pl-3 pt-lg-5 pr-lg-5 pl-lg-5 pb-0 pb-lg-0`}>
      <div className="card border-0" style={cardStyle}>
        <Card
          style={{ marginTop: 16 }}
          bordered={false}
          title={<div><b>{ctdCheckLabels?.ctdChecksTableTitle}</b><Button
            type="default"
            className='ml-3'
            onClick={() => setSyncData(!syncData)}
          >
            <SyncOutlined />
          </Button></div>}
        >
          <Table
            columns={columnsEmployee}
            dataSource={dataCTDRequests}
            pagination={false}
            scroll={{ x: '100%' }}
          />
        </Card >
      </div>
    </div>
  )
}

export default CtdRequests