import React, { useEffect, useState } from 'react'
import useI18next from '../../hooks/useI18next'
import QuotationPdf from '../pdf/QuotationPdf'
import { pdf } from '@react-pdf/renderer'
import axios from 'axios';
import html2canvas from 'html2canvas'

const QuotationInvoiceView = (props) => {

  const {
    additionalConditionsTitle,
    additionalConditions,
    additionalConditionsSignature,
    filters,
    products,
    customerQueryData,
    modelPicture,
    productName,
    versionNumber,
    quotationStepsLabels,
    totalPrice,
    quotations,
    answers,
    languages,
    printQuotationPdf,
    setLoading,
    quotationPdfLabels,
    pdfType,
    paymentMethod,
    remarks,
    signatureTitle,
    signatureName,
    fileSignature,
    logins,
    subvention,
    deliveryTime,
    deliveryTimeLabel,
    fileSalesmanSignature,
    impersonates,
    updateQuotationIsReadyMutation,
    history,
    occasionLable,
    buybackLable,
    ctdCheckLabels
  } = props

  const [fileSignatureUrl, setFileSignatureUrl] = useState(null)
  const [fileSalesmanSignatureUrl, setFileSalesmanSignatureUrl] = useState(null)
  const [fileSignature2Url, setFileSignature2Url] = useState(null)

  const firstName = customerQueryData.customerFirstName
  const lastName = customerQueryData.customerLastName
  const address = customerQueryData.customerAddressLine1
  const zipCode = customerQueryData.customerPostalCode
  const city = customerQueryData.customerCity
  const cmpCode = customerQueryData.cmpCode
  const customerPhoneNumber = customerQueryData.customerPhone

  const cardProductSpecifications = quotationStepsLabels.lables.cardProductSpecifications
  const cardProductColorOptionOne = quotationStepsLabels.lables.cardProductColorOptionOne
  const cardProductColorOptionTwo = quotationStepsLabels.lables.cardProductColorOptionTwo
  const cardProductExtraServiceOptionOne = quotationStepsLabels.lables.cardProductExtraServiceOptionOne
  const cardProductExtraServiceOptionTwo = quotationStepsLabels.lables.cardProductExtraServiceOptionTwo
  const cardProductAdditionalCodesTitle = quotationStepsLabels.lables.cardProductAdditionalCodesTitle
  const questions = quotationStepsLabels.lables.answers
  const paymentMethodTitle = quotationStepsLabels.lables.cardPaymentMethodTitle
  const remarksTitle = quotationStepsLabels.lables.cardRemarksTitle

  const createPdf = async (element) => {
    let quotationImg = ''

    await html2canvas(element, { letterRendering: 1, allowTaint: true, useCORS: true }).then(function (canvas) {
      let link = document.createElement("a")
      document.body.appendChild(link)
      link.download = "html-to-img.jpg"
      link.href = canvas.toDataURL()
      quotationImg = link.href
    })

    const blob = await pdf(<QuotationPdf
      image={quotationImg}
      pdfCoverPage={quotationPdfLabels.pdfCoverPage}
      pdfQuotationStepsInformationPage={quotationPdfLabels.pdfQuotationStepsInformationPage}
      pdfAuthor={quotationPdfLabels.pdfAuthor}
      pdfKeywords={quotationPdfLabels.pdfKeywords}
      pdfSubject={quotationPdfLabels.pdfSubject}
      pdfTitle={quotationPdfLabels.pdfTitle}
      pdfType={pdfType}
    />).toBlob()

    if (pdfType !== "order") {
      const file = new File([blob], "pdf.pdf", { type: "application/pdf" })

      let bodyFormData = new FormData()

      bodyFormData.append('operations', `{"query": "mutation uploadQuotationFile($file: File!, $quotationId: Int!) {uploadQuotationFile(file: $file, quotationId: $quotationId)}","variables": {"file": null, "quotationId": "${quotations.quotation.id}"}}`);
      bodyFormData.append('map', '{"0":["variables.file"]}');
      bodyFormData.append(0, file);

      setLoading(true)
      await axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      }).then((data) => {
        if (data.data.errors === undefined) {
          // success
          markQuotationAsReady()
        } else {
          // error
        }
      }).catch(() => {
        // error
      })
      history.push({
        pathname: window.location.pathname,
        search: '?view=true'
      })
      setLoading(false)
    }
  }

  const markQuotationAsReady = async () => {
    await updateQuotationIsReadyMutation({
      variables: {
        quotationId: quotations.quotation.quotationId ? quotations.quotation.quotationId : quotations.quotation.id,
        isReady: true
      }
    }).then(() => {
      // success
    }).catch(() => {
      // error
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (printQuotationPdf) {
      createPdf(document.getElementById("overview-pdf"))
    }
    setTimeout(() => {
      document.querySelector(".ant-modal-body").scrollIntoView({ behavior: 'smooth' })
    }, 800)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (fileSignature !== null && fileSignature !== undefined && fileSignature !== "") {

      const reader = new FileReader();
      reader.onloadend = function (data) {

        setFileSignatureUrl(data.currentTarget.result)
      }
      reader.readAsDataURL(fileSignature);
    }
  }, [fileSignature])

  useEffect(() => {
    if (additionalConditionsSignature !== null && additionalConditionsSignature !== undefined && additionalConditionsSignature !== "") {

      const reader = new FileReader();
      reader.onloadend = function (data) {

        setFileSignature2Url(data.currentTarget.result)
      }
      reader.readAsDataURL(additionalConditionsSignature);
    }
  }, [additionalConditionsSignature])

  const currencyFormat = (num) => {
    return (
      num
        .toFixed(2) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
    ) // use . as a separator
  }

  useEffect(() => {
    if (fileSalesmanSignature !== null && fileSalesmanSignature !== undefined && fileSalesmanSignature !== "" && impersonates.isImpersonate) {

      const reader = new FileReader();
      reader.onloadend = function (data) {

        setFileSalesmanSignatureUrl(data.currentTarget.result)
      }
      reader.readAsDataURL(fileSalesmanSignature);
    }
  }, [fileSalesmanSignature, impersonates.isImpersonate])

  const date = new Date().toLocaleString('nl', { dateStyle: 'short' })

  let additionalCodes = []

  if (quotations.quotation?.ExtraServiceResponse?.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
    === 3).length > 0) {
    quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
      === 3).map((service, index) => {
        const description = service.descriptions.find(e => e.language === languages.language)?.value
        const quantity = service.quantity
        const price = service.price

        additionalCodes.push({
          key: index,
          description: description,
          quantity: quantity,
          price: currencyFormat(price),
        }
        )
        return null
      })
  }

  let actions = []

  if (products.productId !== 24) {
    answers.map((answer, index) => {

      const internationalizationJson = questions[index].internationalization_json_answer
      const I18next = () => useI18next(internationalizationJson)
      const { i18next } = I18next()

      actions.push(
        <div key={index} className="m-auto">
          <div className='m-1 mb-0 text-center d-flex'>
            <img height={30} width={30} src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${answer[0].option_url}`} alt={i18next.t(`step.${languages.language}`)} />
            <div className='mb-1 text-center mr-1 ml-1' style={{ width: "80px", fontSize: '0.7rem', height: '34px' }}>
              {i18next.t(`step.${languages.language}`)}
            </div>
          </div>
        </div>
      )
      return null
    })
  }

  // Rail Color
  if (quotations.quotation.ColorResponse.find(e => e.type === "Rail") !== undefined && products.productId !== 24) {
    actions.push(
      <div className="m-auto" key={`unique-rail-color`}>
        <div className='m-1 mb-0 text-center d-flex'>
          <img
            height={30} width={30}
            src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotations.quotation.ColorResponse.find(e => e.type === "Rail").pictureUrl}`}
            alt={cardProductColorOptionOne}
          />
          <div className='mb-1 text-center mr-1 ml-1' style={{ width: "80px", fontSize: '0.7rem', height: '34px' }}>
            {cardProductColorOptionOne}
          </div>
        </div>
      </div>
    )
  }

  // Chair Color
  if (quotations.quotation.ColorResponse.find(e => e.type === "Chair") !== undefined && products.productId !== 24) {
    actions.push(
      <div className="m-auto" key={`unique-chair-color`}>
        <div className='m-1 mb-0 text-center d-flex'>
          <img
            height={30} width={30}
            src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotations.quotation.ColorResponse.find(e => e.type === "Chair").pictureUrl}`}
            alt={cardProductColorOptionTwo}
          />
          <div className='mb-1 text-center mr-1 ml-1' style={{ width: "80px", fontSize: '0.7rem', height: '34px' }}>
            {cardProductColorOptionTwo}
          </div>
        </div>
      </div>
    )
  }

  // Fixation Options
  if (products.productId !== 24 && quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
    === 1).length > 0) {
    quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 1).map((fixation, index) => {
      actions.push(
        <div key={index} className="m-auto d-flex">
          <div className='m-1 mb-0 text-center d-flex'>
            <div className="d-flex">
              <div>
                <img
                  alt={cardProductExtraServiceOptionOne.quantity}
                  src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${fixation.picture}`}
                  height={30}
                  width={30}
                  style={{ margin: '5px 5px 5px 0px', marginBottom: '0px' }}
                />

                <div className='mb-1 text-center mr-1 ml-1' style={{ width: "80px", fontSize: '0.7rem', height: '34px' }}>
                  {fixation.quantity}x {cardProductExtraServiceOptionOne}
                </div>
              </div>
            </div>
          </div>

        </div >
      )
      return null
    })
  }

  // Extra Services
  if (products.productId !== 24 && quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
    === 2).length > 0) {
    quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 2).map((service, index) => {
      actions.push(
        <div className="m-auto" key={`unique-installation-options-${index}`}>
          <div className='m-1 mb-0 text-center d-flex'>
            <img
              height={30} width={30}
              src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${service.picture}`}
              alt={cardProductExtraServiceOptionTwo}
            />
            <div className='mb-1 text-center mr-1 ml-1' style={{ width: "80px", fontSize: '0.7rem', height: '34px' }}>
              {service.quantity}x {cardProductExtraServiceOptionTwo}
            </div>
          </div>
        </div>
      )
      return null
    })
  }

  let walkInShowerProductDetails = []
  let walkInShowerExtraServices = []
  if (products.productId === 24 && quotations.quotation?.WizardCompositeKey?.WizardCompositeKeyArticleCodes?.length > 0) {

    quotations.quotation.WizardCompositeKey.WizardCompositeKeyArticleCodes.map((article) => {
      const articleDescription = article.ArticleCode.descriptions.filter((language) => language.language === languages.language.split('_')[0]).pop()?.value

      walkInShowerProductDetails.push(
        <li key={article.ArticleCode.code} >
          ({article.quantity}x) {article.ArticleCode.code}: <b>{articleDescription}</b>
        </li>
      )
      return null
    })

    if (quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
      === 2).length > 0) {
      quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 2).map((service, index) => {
        walkInShowerExtraServices.push(
          <div className="text-center" key={`unique-installation-options-${index}`}>
            <div className="m-auto d-flex justify-content-center align-items-center">
              <div className="m-auto" key={`unique-installation-options`}>
                <div className='m-2 mb-0 text-center'>
                  <img
                    height={30} width={30}
                    src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${service.picture}`}
                    alt={cardProductExtraServiceOptionTwo}
                  />
                  <div className='mb-1 text-center mr-1 ml-1 p-1' style={{ fontSize: '0.7rem' }}>
                    {service.quantity}x
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        return null
      })
    }

    if (quotations.quotation.ExtraServiceResponse.length > 0 && quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property
      === 1).length > 0) {

      let walkInShowerExtraServicesB = []

      quotations.quotation.ExtraServiceResponse.filter(e => e.properties[0].property === 1).map((fixation) => {
        walkInShowerExtraServicesB.push(
          <div className="m-auto" key={fixation.id}>
            <div className='m-2 mb-0 text-center'>
              <img
                height={30} width={30}
                src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${fixation.picture}`}
                alt={cardProductExtraServiceOptionOne}
              />
              <div className='mb-1 text-center mr-1 ml-1 p-1' style={{ fontSize: '0.7rem' }}>
                {fixation.quantity}x
              </div>
            </div>
          </div>
        )
      })

      walkInShowerExtraServices.push(
        <div className="text-center">
          <div className="m-auto d-flex justify-content-center align-items-center">
            {walkInShowerExtraServicesB}
          </div>
        </div>
      )

    }
  }

  // Outdated - we need to keep this code, to update to Sanity will help us
  // const pdfFooter = pdfType === "quotation" ? quotationPdfLabels.pdfQuotationFooter : quotationPdfLabels.pdfOrderFooter
  const pdfFooter = quotationPdfLabels.pdfOrderFooter
  const pdfLabel = pdfType === "quotation" ? quotationPdfLabels.quotationLabel : quotationPdfLabels.orderLabel

  const productImageStyle = products.productId !== 24 ? {
    maxWidth: '160px',
    maxHeight: '150px',
  } : {
    maxWidth: '340px',
    maxHeight: '220px',
    height: '200px'
  }

  const totalPriceIncludingBuybackAndOccasion = () => {

    const buybackPrice = quotations.quotation.BuyBackModel ? (quotations.quotation.BuyBackModel?.amount * quotations.quotation.BuyBackModel?.quantity) : 0

    const occasionPrice = quotations.quotation.OccasionModel ? (quotations.quotation.OccasionModel?.amount * quotations.quotation.OccasionModel?.quantity) : 0

    return totalPrice - buybackPrice - occasionPrice
  }

  // Translation hard coded. Waiting for migration to myMobilae 2.0
  const walkInShowerLegalText = (locale) => {
    switch (locale) {
      case "en_UK":
        return "The height of the shower floor depends on the situation under the current bath and will be adjusted accordingly."
      case "nl_NL":
      case "nl_BE":
        return "De hoogte van de douchevloer is afhankelijk van de situatie onder het huidige bad/douche en wordt daarop aangepast."
      case "it_IT":
        return "La data di fornitura indicata sul presente documento, potrà subire variazioni qualora il pagamento avvenga oltre i 5 giorni dalla stipula dell'ordine."
      case "fr_FR":
      case "fr_BE":
        return "La hauteur du sol de la douche dépend de la situation sous la baignoire actuelle et sera ajustée en conséquence."
      case "de_DE":
        return "Die Höhe des Duschbodens hängt von der Situation unter der aktuellen Badewanne ab und wird entsprechend angepasst."
      default:
        return "The height of the shower floor depends on the situation under the current bath and will be adjusted accordingly."
    }
  }

  return (
    <>
      {/* Quotation and Order PDF page */}
      <div id="overview-pdf" style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >

        <div className="h-100" style={{
          position: 'relative',
          padding: '1.54cm 2.54cm 1.54cm 2.54cm',
          width: '21cm',
          height: '29.7cm',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)'
        }}>

          {/* PDF Logo & Title */}
          <div className="d-flex pt-2">
            <span className="w-100">
              <div>
                <img
                  className="float-right"
                  alt='Mobilae logo'
                  height="80"
                  src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                  style={{ width: '100%', height: 'auto' }} />
              </div>
              <h3 style={{
                color: '#4BA59B',
                display: 'inline-block',
                lineHeight: '50px',
                marginBottom: 0
              }}><div><b>{pdfLabel}</b></div></h3>
            </span>
          </div>
          {/* End - PDF Logo & Title */}

          {/* User & Product Details  */}
          <div className="d-flex justify-content-between">

            {/* User Details */}
            <div className="pr-2 pt-2 pb-2" style={{ position: "relative", width: '39%' }}>
              <div>
                <b>{quotationPdfLabels.customerLabel}</b> <br />
                {firstName} {lastName} <br />
                {address} <br />
                {zipCode} {city} <br />
                {customerPhoneNumber} <br />
                <b>{quotationPdfLabels.customerNumber}</b> {cmpCode}
                {
                  products.productId === 24 &&
                  <React.Fragment>
                    <br />
                    <b>{quotationPdfLabels.productNameLabel}</b>
                    <br />
                    {productName} <br />
                    {
                      versionNumber &&
                      <span>
                        {quotationPdfLabels.versionNumber}: {versionNumber}
                        <br />
                        <br />
                      </span>
                    }
                    {
                      logins.employeeName !== null && logins.employeeName !== undefined && logins.employeeName?.firstName !== "" && logins.employeeName?.lastName !== "" &&
                      <React.Fragment>
                        {/* Change the logic to add the name of the salesman that create the quotation */}
                        <b>{quotationPdfLabels.localAdviser}:</b><br /> {logins.employeeName?.firstName} {logins.employeeName?.lastName}
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
            {/* End - UserDetails */}

            {/* Product Details */}
            <div className="pl-2 pt-2 pb-2" style={{ width: '60%' }}>
              {
                products.productId !== 24 &&
                <div style={{
                  display: 'inline-block'
                }}>

                  <b>{quotationPdfLabels.productNameLabel}</b>
                  <br />
                  {productName} <br />
                  {
                    versionNumber &&
                    <span>
                      {quotationPdfLabels.versionNumber}: {versionNumber}
                      <br />
                      <br />
                    </span>
                  }
                  {
                    logins.employeeName !== null && logins.employeeName !== undefined && logins.employeeName?.firstName !== "" && logins.employeeName?.lastName !== "" &&
                    <React.Fragment>
                      {/* Change the logic to add the name of the salesman that create the quotation */}
                      <b>{quotationPdfLabels.localAdviser}:</b><br /> {logins.employeeName?.firstName} {logins.employeeName?.lastName}
                    </React.Fragment>
                  }

                </div>
              }

              {/* Product Image */}
              <div style={{
                display: 'inline-block',
                float: 'right'
              }}>
                <img alt={productName} src={products.productId !== 24 ? `${process.env.REACT_APP_BACKEND_PROXY_SERVER}${modelPicture}` : `${modelPicture}`} style={productImageStyle} />
              </div>
              {/* End - Product Image */}
            </div>
            {/* End - Product Details */}
          </div>
          {/* End - User & Product Details */}

          {/* Extra Services Walk in Shower */}
          {
            products.productId === 24 &&
            <div className='d-flex justify-content-center align-items-center'>
              {walkInShowerExtraServices}
            </div>
          }
          {/* End - Extra Services Walk in Shower */}

          {/* Technical Details for products != from Walk in Shower */}
          {
            products.productId !== 24 &&
            <div className="border border-dark rounded mt-1" style={{ width: '100%' }}>
              <div>
                <div className="d-flex justify-content-center" style={{
                  backgroundColor: '#143e47',
                  color: 'white'
                }}>
                  <b>{quotationPdfLabels.techicalDetailsLabel} & {cardProductSpecifications}</b>
                </div>
                <div className="row" style={{ marginRight: '0px', marginLeft: '0px' }}>
                  {actions}
                </div>
              </div>
            </div>
          }
          {/* End - Technical Details for products != from Walk in Shower */}

          {/* Technical Details for Walk in Shower */}
          {
            products.productId === 24 &&
            <div className="border border-dark rounded mt-1" style={{ width: '100%' }}>
              <div>
                <div className="d-flex justify-content-center" style={{
                  backgroundColor: '#143e47',
                  color: 'white'
                }}>
                  <b>{quotationPdfLabels.techicalDetailsLabel} & {cardProductSpecifications}</b>
                </div>
                <div className="p-2" style={{ fontSize: '0.7rem' }}>
                  <ul>
                    {walkInShowerProductDetails}
                  </ul>
                </div>
              </div>
            </div>
          }
          {/* End - Technical Details for Walk in Shower */}

          {/* Legal Text Walk in Shower or for all products for it_IT locale*/}
          {
            products.productId === 24 && languages.locale !== "it_IT" &&
            <div className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '0.7rem' }}>
              {walkInShowerLegalText(languages.locale)}
            </div>
          }
          {
            languages.locale === "it_IT" &&
            <div className='d-flex justify-content-center align-items-center text-center' style={{ fontSize: '0.7rem' }}>
              {walkInShowerLegalText(languages.locale)}
            </div>
          }
          {/* End - Legal Text Walk in Shower */}

          <div className="d-flex justify-content-between mb-1 mt-1">
            {/* Additional Options */}
            <div className="border border-dark rounded mt-1" style={{ width: '49%' }}>
              <div className="d-flex pl-2" style={{
                backgroundColor: '#143e47',
                color: 'white'
              }}>
                <b>{quotationPdfLabels.optionsAndConditionsLabel}</b>
              </div>

              <div className="p-2" style={{ fontSize: '0.7rem' }}>
                {/* Extra Options */}
                {
                  additionalCodes.length > 0 &&
                  <div>
                    <div className="d-flex">
                      <b>{cardProductAdditionalCodesTitle}</b>
                    </div>
                    <table style={{ width: '100%', borderTop: '1px solid black', fontSize: '0.6rem', lineHeight: 'initial' }}>
                      {
                        additionalCodes.map((code, index) => {
                          return (
                            <tbody key={index}>
                              <tr>
                                <td>{code.description}</td>
                                <td className="align-top">{code.quantity}x</td>
                              </tr>
                            </tbody>
                          )
                        })
                      }
                    </table>
                  </div>
                }
                {/* End - Extra Options */}
                {/* Delivery Time */}
                {
                  deliveryTime &&
                  <div>
                    <div className="d-flex justify-content-left">
                      <b>{deliveryTimeLabel}</b>
                    </div>
                    <div className="d-flex justify-content-left" style={{
                      borderTop: '1px solid black'
                    }}>
                      {deliveryTime}
                    </div>
                  </div>
                }
                {/* End - Delivery Time*/}

                {/* Remarks != walk in shower */}
                {
                  remarks !== '' && products.productId !== 24 &&
                  <div className="">
                    <div className="d-flex justify-content-left">
                      <b>{remarksTitle}</b>
                    </div>
                    <div className="d-flex justify-content-left pt-1" style={{
                      borderTop: '1px solid black',
                      lineHeight: '1.2em',
                    }}>
                      {remarks}
                    </div>
                  </div>
                }
                {/* End - Remarks */}
              </div>
            </div>
            {/* End - Additional Options */}

            {/* Order Values */}
            <div className="border border-dark rounded mt-1" style={{ width: '50%' }}>
              <div className="d-flex pl-2" style={{
                backgroundColor: '#143e47',
                color: 'white'
              }}>
                <b>{quotationPdfLabels.orderValueLabel}</b>
              </div>

              <div className="p-2" style={{ fontSize: '0.7rem' }}>

                <table style={{ width: '100%' }}>
                  <tbody>
                    {
                      quotations.quotation.discount > 0 &&
                      <React.Fragment>
                        <tr>
                          <td>{quotationPdfLabels.orderValueLabel}</td>
                          <td className="float-right text-nowrap">{currencyFormat(Number(quotations.quotation.quotationPrice))}</td>
                        </tr>

                        <tr>
                          <td>{quotationPdfLabels.discountLabel}</td>
                          <td className="float-right text-nowrap">-{currencyFormat(Number(quotations.quotation.discount))}</td>
                        </tr>

                      </React.Fragment>
                    }
                    {
                      quotations.quotation.BuyBackModel &&
                      <tr>
                        <td>{buybackLable}</td>
                        <td className="float-right text-nowrap">-{currencyFormat(Number(quotations.quotation.BuyBackModel?.amount * quotations.quotation.BuyBackModel?.quantity))}</td>
                      </tr>
                    }
                    {
                      quotations.quotation.OccasionModel &&
                      <tr>
                        <td>{occasionLable}</td>
                        <td className="float-right text-nowrap">-{currencyFormat(Number(quotations.quotation.OccasionModel?.amount * quotations.quotation.OccasionModel?.quantity))}</td>
                      </tr>
                    }
                    {
                      <tr style={{
                        borderTop: '1px solid black'
                      }}>

                        {
                          subvention <= 0 &&
                          <React.Fragment>
                            <td><b>{quotationPdfLabels.priceLable}</b></td>
                            <td className="float-right text-nowrap"><b>{currencyFormat(Number(totalPriceIncludingBuybackAndOccasion()))}</b></td>
                          </React.Fragment>
                        }

                        {
                          subvention > 0 &&
                          <React.Fragment>
                            <td>{quotationPdfLabels.priceLable}</td>
                            <td className="float-right text-nowrap">{currencyFormat(Number(totalPriceIncludingBuybackAndOccasion()))}</td>
                          </React.Fragment>
                        }
                      </tr>
                    }

                    {
                      subvention > 0 &&
                      <tr>
                        <td>{quotationPdfLabels.subventionValue}</td>
                        <td className="float-right text-nowrap" >-{currencyFormat(Number(subvention))}</td>
                      </tr>
                    }
                    {
                      subvention > 0 &&
                      <tr style={{
                        borderTop: '2px solid black'
                      }}>
                        <td><b>{quotationPdfLabels.valueToPayLabel}</b></td>
                        <td className="float-right text-nowrap" ><b>{currencyFormat(Number(totalPriceIncludingBuybackAndOccasion() - subvention))}</b></td>
                      </tr>

                    }
                  </tbody>
                </table>
                <br />
                {/* End - Values & Discounts */}

                {/* PDF Date */}
                <div className="w-100 float-right">
                  <span>
                    {city}
                  </span>
                  <span className="float-right">
                    {quotationPdfLabels.dateLabel} {date}
                  </span>
                </div>
                {/* End - PDF Date */}

                {/* Signature */}
                {
                  fileSignature &&
                  <div>
                    <div className="w-100 float-right">
                      <span className="float-right">
                        <b>{signatureTitle}</b>
                      </span>
                    </div>
                    <div className="float-right d-flex">

                      {/* Salesman signature */}
                      {
                        impersonates.isImpersonate && fileSalesmanSignature &&
                        <div>
                          <div>
                            <img alt={signatureTitle} height="68" width="130" src={fileSalesmanSignatureUrl} />
                          </div>
                          <div className="p-1 float-left">
                            {logins.employeeName?.firstName} {logins.employeeName?.lastName}
                          </div>
                        </div>
                      }

                      {/* Customer signature */}
                      <div>
                        <div>
                          <img alt={signatureTitle} height="68" width="160" src={fileSignatureUrl} />
                        </div>
                        <div className="p-1 float-left">
                          {signatureName}
                        </div>
                      </div>

                    </div>
                  </div>
                }
                {/* End - Signature */}

                {/* Remarks for walk in shower */}
                {
                  remarks !== '' && products.productId === 24 &&
                  <div className="">
                    <div className="d-flex justify-content-left w-100">
                      <b>{remarksTitle}</b>
                    </div>
                    <div className="d-flex justify-content-left pt-1" style={{
                      borderTop: '1px solid black',
                      lineHeight: '1.2em',
                    }}>
                      {remarks}
                    </div>
                  </div>
                }
                <br />
                {/* Payment Option */}
                {
                  paymentMethod &&
                  <div>
                    <div className="">
                      <b>{paymentMethodTitle}</b>
                    </div>
                    <div className="" style={{
                      borderTop: '1px solid black'
                    }}>
                      {paymentMethod}
                    </div>
                  </div>
                }
                {/* End - Payment Option */}

              </div>
            </div>
            {/* End - Order Values */}
          </div>

          {/* PDF Footer */}
          <div className="fixed-bottom text-center" style={{ position: 'absolute', padding: '1.54cm 2.54cm 1.54cm 2.54cm', fontSize: '9px', lineHeight: '10px' }}>
            <p style={{ whiteSpace: 'pre-line' }}>{pdfFooter}</p>
          </div>
          {/* End - PDF Footer */}

        </div>
      </div>

      {/* Additional conditions PDF page */}
      {
        additionalConditions &&
        <div>
          <div id="additional-conditions"
            style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >
            <div className="h-100" style={{
              position: 'relative',
              padding: '1.54cm 3.54cm 1.54cm 3.54cm',
              width: '21cm',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }}>
              {/* PDF Logo & Title */}
              <div className="d-flex pt-2">
                <span className="w-100">
                  <div>
                    <img
                      className="float-right"
                      alt='Mobilae logo'
                      height="80"
                      src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                      style={{ width: '100%', height: 'auto' }} />
                  </div>
                  <h3 style={{
                    color: '#4BA59B',
                    display: 'inline-block',
                    lineHeight: '50px',
                    marginBottom: 0,
                    marginLeft: '25px'
                  }}><div><b>{additionalConditionsTitle}</b></div></h3>
                </span>
              </div>
              {/* End - PDF Logo & Title */}

              <div className="bg-white">
                <div className="justify-content-center" style={{
                  marginLeft: '25px'
                }}>
                  <div className="border border-dark rounded mt-1" style={{ width: '50%' }}>
                    <div className="d-flex pl-2" style={{
                      backgroundColor: '#143e47',
                      color: 'white'
                    }}>
                      <b>{additionalConditionsTitle}</b>
                    </div>

                    <div className="p-2" style={{ fontSize: '0.7rem' }}>
                      {/* PDF Date */}
                      <div className="w-100 float-right">
                        <span>
                          {city}
                        </span>
                        <span className="float-right">
                          {quotationPdfLabels.dateLabel} {date}
                        </span>
                      </div>
                      {
                        additionalConditions !== '' &&
                        <div className="mt-4">
                          <div className="pt-1" style={{
                            lineHeight: '1.2em',
                          }}>
                            {additionalConditions}
                          </div>
                        </div>
                      }
                      {
                        additionalConditionsSignature &&
                        <div>
                          <div>
                            <img alt="add-con" height="68" width="160" src={fileSignature2Url} />
                          </div>
                        </div>
                      }

                    </div>


                  </div>
                </div>
              </div>
              {/* PDF Footer */}
              <div className="fixed-bottom text-center" style={{ position: 'absolute', padding: '1.54cm 2.54cm 1.54cm 2.54cm', fontSize: '9px', lineHeight: '10px' }}>
                <p style={{ whiteSpace: 'pre-line' }}>{pdfFooter}</p>
              </div>
              {/* End - PDF Footer */}
            </div>
          </div>
        </div>
      }


      {/* CTD PDF page */}
      {
        pdfType === "order" && quotations.quotation?.CTDRequest?.Status?.id === 4 &&
        <div>
          {/* Category 1 */}
          <div id="ctd-pdf-cat-1"
            style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >
            <div className="h-100" style={{
              position: 'relative',
              padding: '1.54cm 2.54cm 1.54cm 2.54cm',
              width: '21cm',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <div className="bg-white">
                <div className="justify-content-center">

                  {/* CTD Page 1 Header */}
                  <div>
                    <span className="w-100">
                      <div>
                        <img
                          className="float-right"
                          alt='Mobilae logo'
                          height="80"
                          src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                          style={{ width: '100%', height: 'auto' }} />
                      </div>
                      <h3
                        style={{
                          color: '#4BA59B',
                          display: 'inline-block',
                          lineHeight: '50px',
                          marginBottom: 0,
                          width: '100%',
                          textAlign: 'center'
                        }}><div><b>{ctdCheckLabels?.ctdPdfHeader}</b></div></h3>
                    </span>
                  </div>

                  <h5 className="text-center">{ctdCheckLabels?.ctdCheckCategoryEntry?.filter((category) => category.id === 1)[0]?.title}</h5>
                  {
                    quotations.quotation.CTDRequest?.answers &&
                    quotations.quotation.CTDRequest.answers.filter((answer) => { return answer.categoryId === 1 }).map((answer, key) => {

                      const question = ctdCheckLabels?.ctdCheckCategoryEntry?.filter((e) => { return e.id === 1 })[0]?.ctdQuestionEntry?.filter((e) => { return e.id === answer.questionId })[0]

                      const ctdAnswer = answer.answer

                      if (question?.questionType !== "file") {
                        return (
                          <div className="border border-white rounded bg-light p-2 m-1" key={key}>
                            {question?.questionText} <b>{ctdAnswer}</b>
                          </div>
                        )
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </div >

          {/* Category 2 */}
          <div id="ctd-pdf-cat-2"
            style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >
            <div className="h-100" style={{
              position: 'relative',
              padding: '1.54cm 2.54cm 1.54cm 2.54cm',
              width: '21cm',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <div className="bg-white">
                <div className="justify-content-center">

                  {/* CTD Page 2 Header */}
                  <div>
                    <span className="w-100">
                      <div>
                        <img
                          className="float-right"
                          alt='Mobilae logo'
                          height="80"
                          src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                          style={{ width: '100%', height: 'auto' }} />
                      </div>
                      <h3
                        style={{
                          color: '#4BA59B',
                          display: 'inline-block',
                          lineHeight: '50px',
                          marginBottom: 0,
                          width: '100%',
                          textAlign: 'center'
                        }}><div><b>{ctdCheckLabels?.ctdPdfHeader}</b></div></h3>
                    </span>
                  </div>

                  <h5 className="text-center">{ctdCheckLabels?.ctdCheckCategoryEntry?.filter((category) => category.id === 2)[0]?.title}</h5>
                  {
                    quotations.quotation.CTDRequest?.answers &&
                    quotations.quotation.CTDRequest.answers.filter((answer) => { return answer.categoryId === 2 }).map((answer, key) => {

                      const question = ctdCheckLabels?.ctdCheckCategoryEntry?.filter((e) => { return e.id === 2 })[0]?.ctdQuestionEntry?.filter((e) => { return e.id === answer.questionId })[0]

                      const ctdAnswer = answer.answer

                      if (question?.questionType !== "file") {
                        return (
                          <div className="border border-white rounded bg-light p-2 m-1" key={key}>
                            {question?.questionText} <b>{ctdAnswer}</b>
                          </div>
                        )
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </div >

          {/* Category 3 */}
          <div id="ctd-pdf-cat-3"
            style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >
            <div className="h-100" style={{
              position: 'relative',
              padding: '1.54cm 2.54cm 1.54cm 2.54cm',
              width: '21cm',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <div className="bg-white">
                <div className="justify-content-center">

                  {/* CTD Page 3 Header */}
                  <div>
                    <span className="w-100">
                      <div>
                        <img
                          className="float-right"
                          alt='Mobilae logo'
                          height="80"
                          src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                          style={{ width: '100%', height: 'auto' }} />
                      </div>
                      <h3
                        style={{
                          color: '#4BA59B',
                          display: 'inline-block',
                          lineHeight: '50px',
                          marginBottom: 0,
                          width: '100%',
                          textAlign: 'center'
                        }}><div><b>{ctdCheckLabels?.ctdPdfHeader}</b></div></h3>
                    </span>
                  </div>

                  <h5 className="text-center">{ctdCheckLabels?.ctdCheckCategoryEntry?.filter((category) => category.id === 3)[0]?.title}</h5>
                  {
                    quotations.quotation.CTDRequest?.answers &&
                    quotations.quotation.CTDRequest.answers.filter((answer) => { return answer.categoryId === 3 }).map((answer, key) => {

                      const question = ctdCheckLabels?.ctdCheckCategoryEntry?.filter((e) => { return e.id === 3 })[0]?.ctdQuestionEntry?.filter((e) => { return e.id === answer.questionId })[0]

                      const ctdAnswer = answer.answer

                      if (question?.questionType !== "file") {
                        return (
                          <div className="border border-white rounded bg-light p-2 m-1" key={key}>
                            {question?.questionText} <b>{ctdAnswer}</b>
                          </div>
                        )
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </div >

          {/* Category 4 */}
          <div id="ctd-pdf-cat-4"
            style={{ width: '21cm', height: '29.7cm', backgroundColor: 'white' }} >
            <div className="h-100" style={{
              position: 'relative',
              padding: '1.54cm 2.54cm 1.54cm 2.54cm',
              width: '21cm',
              top: '50%',
              left: '50%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)'
            }}>
              <div className="bg-white">
                <div className="justify-content-center">

                  {/* CTD Page 4 Header */}
                  <div>
                    <span className="w-100">
                      <div>
                        <img
                          className="float-right"
                          alt='Mobilae logo'
                          height="80"
                          src={`${process.env.REACT_APP_BACKEND_PROXY_SERVER}${quotationPdfLabels.pdfLogo}`}
                          style={{ width: '100%', height: 'auto' }} />
                      </div>
                      <h3
                        style={{
                          color: '#4BA59B',
                          display: 'inline-block',
                          lineHeight: '50px',
                          marginBottom: 0,
                          width: '100%',
                          textAlign: 'center'
                        }}><div><b>{ctdCheckLabels?.ctdPdfHeader}</b></div></h3>
                    </span>
                  </div>

                  <h5 className="text-center">
                    {
                      ctdCheckLabels?.ctdCheckCategoryEntry?.filter((category) => category.id === 4)[0]?.title
                    }
                  </h5>
                  {
                    quotations.quotation.CTDRequest?.answers &&
                    quotations.quotation.CTDRequest.answers.filter((answer) => { return answer.categoryId === 4 }).map((answer, key) => {

                      const question = ctdCheckLabels?.ctdCheckCategoryEntry?.filter((e) => { return e.id === 4 })[0]?.ctdQuestionEntry?.filter((e) => { return e.id === answer.questionId })[0]

                      const ctdAnswer = answer.answer

                      if (question?.questionType !== "file") {
                        return (
                          <div className="border border-white rounded bg-light p-2 m-1" key={key}>
                            {question?.questionText} <b>{ctdAnswer}</b>
                          </div>
                        )
                      }

                    })
                  }
                  {/* CTD approved comment */}
                  {
                    quotations.quotation.CTDRequest?.approvalCommentId &&
                    <div className="text-center mt-5">
                      <h5>{ctdCheckLabels?.ctdPdfApprovalComment}</h5>
                      <div className="border border-white rounded bg-light p-2 m-1">
                        {ctdCheckLabels?.ctdApproveComment?.dropdownOptions[quotations.quotation.CTDRequest?.approvalCommentId]}
                      </div>
                    </div>
                  }

                  {/* CTD installation */}
                  {
                    (quotations.quotation.CTDRequest?.installationTimeId !== null || quotations.quotation.CTDRequest?.complexityId !== null) &&
                    <div className="text-center mt-5">
                      <h5>{ctdCheckLabels?.installationInformation?.title}</h5>

                      {/* CTD installation time */}
                      {
                        quotations.quotation.CTDRequest?.installationTimeId !== null &&

                        < div >
                          < div className="border border-white rounded bg-light p-2 m-1">
                            <b>{ctdCheckLabels?.installationInformation?.installationTime?.dropdownPlaceholder}</b>: {ctdCheckLabels?.installationInformation?.installationTime?.dropdownOptions[quotations.quotation.CTDRequest?.installationTimeId - 1]}
                          </div>
                        </div>
                      }

                      {/* CTD installation complexity */}
                      {
                        quotations.quotation.CTDRequest?.complexityId !== null &&
                        < div >
                          < div className="border border-white rounded bg-light p-2 m-1">
                            <b>{ctdCheckLabels?.installationInformation?.complexity?.dropdownPlaceholder}</b>: {ctdCheckLabels?.installationInformation?.complexity?.dropdownOptions[quotations.quotation.CTDRequest?.complexityId - 1]}
                          </div>
                        </div>
                      }
                    </div>
                  }

                </div>
              </div>
            </div>
          </div >
        </div >
      }
      {/* End - CTD PDF page */}
    </>
  )
}

export default QuotationInvoiceView

