// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'antd/dist/antd.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import configureStore from './redux/store/configureStore';
import { saveToSessionStorage } from './session-storage/sessionStorage'
import { backendClient } from './graphql-endpoints/graphqlEndpoints'
import ScrollToTop from './hooks/ScrollToTop'

export const store = configureStore();

store.subscribe(() => {
  saveToSessionStorage(store.getState())
})

ReactDOM.render(
  <ApolloProvider client={backendClient}>
    <Provider store={store}>
      <BrowserRouter>
        {/* <React.StrictMode></React.StrictMode> */}
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();