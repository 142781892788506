import * as actionTypes from '../actions/actionType';

export default (state = {
  id: '1',
  notificationType: '2',
  placement: '',
  style: '',
  duration: '',
  message: '',
  description: '',
  handlerClick: '',

}, action) => {

  switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
      return {
        ...state,
        id: action.id,
        notificationType: action.notificationType,
        placement: action.placement,
        style: action.style,
        duration: action.duration,
        message: action.message,
        description: action.description,
        handlerClick: action.handlerClick,
      }
    case actionTypes.DELETE_NOTIFICATION:
      return {}
    default:
      return state;
  }
}